import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import efficiency from "../Images/Efficiency.svg";
import performance from "../Images/Performance.png";
import reliability from "../Images/Reliability.png";
import NavBar from "../Components/NavBar";
import "../StyleSheets/Forensics.css";
function Upcoming() {
  return (
    <>
      <section>
        <NavBar />
        <Container className="py-3 my-5">
       <h2 className="text-center m-5" style={{fontWeight: "300"}}>Upcoming Services</h2> 
          <Row className="m-5">
            <Col className="col-4 aligningItems">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{ width: "18 rem" }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5>Reliability </h5>
                    <a href="#">
                      <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={reliability}
                        alt="Card image cap"
                      />
                    </a>
                  </Card.Text>
                </Card.Body>
               
              </Card>
              <p className="m-0 text-center" style={{fontsize:"18px"}}>Full Stack Forensics</p>
          <p className="m-0 text-center" style={{fontsize:"18px"}}>Change Intelligence</p>
            </Col>
            <Col className="col-4 aligningItems">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{ width: "18 rem" }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5>Performance </h5>
                    <a href="#">
                      <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={performance}
                        alt="Card image cap"
                      />
                    </a>
                  </Card.Text>
                </Card.Body>
           
              </Card>
              <p className="m-0 text-center" style={{fontsize:"18px"}}>Load Tester</p>
         <p className="m-0 text-center" style={{fontsize:"18px"}}>Chaos Library</p>
         <p className="m-0 text-center" style={{fontsize:"18px"}}>Performance Intelligence</p>
            </Col>
            <Col className="col-4 aligningItems">
              <Card
                className="shadow py-2 my-3 imgContainer"
                style={{ width: "18 rem" }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5>Efficiency</h5>
                    <a href="#">
                      <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={efficiency}
                        alt="Card image cap"
                      />
                    </a>
                  </Card.Text>
                </Card.Body>
               
              </Card>
              <p className="m-0 text-center" style={{fontsize:"18px"}}>Capacity Capture</p>
            <p className="m-0 text-center" style={{fontsize:"18px"}}>Efficiency Intelligence</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Upcoming;
