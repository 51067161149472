import React from "react";

function LoadingValidate() {
  return (
    <>
      <h1 style={{textAlign: 'center'}}>Running validation, please wait, this may take few minutes...</h1>
    </>
  );
}

export default LoadingValidate;
