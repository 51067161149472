import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Error(props) {
  const navigate = useNavigate();
  const goBack = () => {
    if(props.element.comingFrom === "sqlForensics"){
      navigate("/SqlForensicForm")
    }
    if(props.element.comingFrom === "postgresForensics"){
    navigate("/PostgresForensicForm")
    }
    if(props.element.comingFrom === "neo4jForensics"){
      navigate("/Neo4jForensicForm")
      }
    if(props.element.comingFrom === "batchReport"){
      navigate("/URLBatch")
    }
  }
  return (  
    props.element.comingFrom === "batchReport" 
    ? <p className="px-5 py-2 text-center">Error in validating urls please contact SRE</p> : 
    <div>
    <p className="text-center py-5 px-5">
      {" "}
      {
      props.element.Error=== "" ? 
      <div>
      <p>Invalid Details</p>
      <p className="px-5 py-2">Try again with correct credentials and other details. If you still face issues, contact SRE team</p>
      </div> 
     :
      <div>
      {props.element.Error}
      <p className="px-5 py-2">Try again with correct credentials and other details. If you still face issues, contact SRE team</p>
      </div> 
      }
    </p>
    {/* <div className="buttonContainer">
    <Button style={{width:"max-content"}} type="button" className="submitButton" onClick={goBack}>
      Go Back
    </Button>
    </div> */}
  </div>
  )
}
export default Error;