import { createContext, useContext, useState } from "react";

const SlothContext = createContext({});

export const SlothLayoutProvider = ({ children }) => {
  const [sloData, setSloData] = useState();
  const [slosContextArray, setSlosContextArray] = useState([]);
  const [yamlString, setYamlString] = useState("")
  const [updating, setUpdating] = useState(false)
  const [sha, setSha] = useState("")
  const [noOfOldSlos, setNoOfOldSlos] = useState(0)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [macValues, setMacValues] = useState({
    Portfolio:{
        id:"",
        label:"",
        value:""
    },
    Product:{
        id:"",
        label:"",
        value:""
    },
    Platform:{
        id:"",
        label:"",
        value:""
    },
    App:{
        id:"",
        label:"",
        value:""
    },
  })
  // const []
  console.log("sloData", sloData);
  console.log("slosContextArray ", slosContextArray)
  // console.log("slosCOntextArray ", slosContextArray);

  return (
    <SlothContext.Provider
      value={{
        sha,
        setSha,
        updating,
        setUpdating,
        sloData,
        setSloData,
        slosContextArray,
        setSlosContextArray,
       yamlString,
       setYamlString,
       noOfOldSlos, setNoOfOldSlos,
       macValues, setMacValues,
       currentTabIndex, setCurrentTabIndex
      }}
    >
      {children}
    </SlothContext.Provider>
  );
};

export default function UseSloInfo() {
  return useContext(SlothContext);
}
