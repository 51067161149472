import React, { createContext, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { callMsGraph } from "../auth/graph";
import { useMsal} from "@azure/msal-react";
import jwt from "jwt-decode";
import { loginRequest } from "../auth/authProviderMsalReact";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
const REFRESH_THRESHOLD = 300; // 5 minutes in seconds
const TOKEN_CHECK_INTERVAL = 60000;

// Create a context for the page layout
const PageLayoutContext = createContext({});

// Provider component for the page layout context
export const PageLayoutProvider = ({ children }) => {
  console.log("I am in PageLayoutProvider")
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [token, setToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const interval = useRef(null);

  // Function to request profile data
  function RequestProfileData() {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    })
    .then((response) => {
      setToken(response.idToken);
      setAccessToken(response.accessToken);
      acquireTokenWithRefreshToken();
      callMsGraph(response.accessToken).then(response => setGraphData(response));
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({...loginRequest, account:accounts[0]})
        .then(function (response) {
          setToken(response.idToken);
          setAccessToken(response.accessToken);
          acquireTokenWithRefreshToken();
          callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
      }
    });
  }

  // Function to acquire token with refresh token
  const acquireTokenWithRefreshToken = async () => { 
    try {  
      if (accounts.length && instance) {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,  
          account: accounts[0],
        });
        sessionStorage.setItem('accessToken', response.accessToken);
        sessionStorage.setItem('idToken', response.idToken);
      }
    } catch (error) {  
      console.log('Error refreshing token', error);
    }
  };

  // Effect to request profile data on mount
  useEffect(()=>{
    RequestProfileData();
  },[]);

  // Effect to check token expiry
  useEffect(() => {
    const checkTokenExpiry = () => {
      if (accessToken) {
        const decodeToken = jwt(accessToken);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeUntilExpiry = decodeToken.exp - currentTime;
        if (timeUntilExpiry <= REFRESH_THRESHOLD) {
          acquireTokenWithRefreshToken();
        }
      }
    };

    interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
    checkTokenExpiry(); // Check token expiry immediately after mounting
    return () => clearInterval(interval.current);
  }, []);

  return (
    <PageLayoutContext.Provider
      value={{
        graphData,
        token, 
        setToken
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};

// Function to use the page layout context
export default function UserInfo() {
  return useContext(PageLayoutContext);
}

    // const RequestUserRoles = async () => {
    //   const resp = await fetch("http://localhost:3001/api/validateSessionAccess", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ authToken: token }),
    //   });
    //   console.log(resp)
    //   const rolesData = await resp.json();
    //   console.log(rolesData);
    //   setRoleData(rolesData);
    // };