import React, { useState } from 'react';

const DynamicForm = ({queryAddOnFields,setqueryAddOnFields}) => {
  const [testFields, setTestFields] = useState([{ testName: 'app', testValue: 'afls' }]);
  
  // Define the maximum number of field sets allowed
  const MAX_FIELDS = 3;

  // Handle adding a new set of fields, but only if the current number is below the max
  const addTestField = ({queryAddOnFields,setqueryAddOnFields}) => {
    if (testFields.length < MAX_FIELDS) {
      setTestFields([...testFields, { testName: '', testValue: '' }]);
    } else {
      alert(`You can only add up to ${MAX_FIELDS} fields.`);
    }
  };
  console.log("ADDON FIELD",setTestFields)

  // const QueryField = ({queryAddOnFields,setqueryAddOnFields}) => {
  //   const [selectedValue, setSelectedValue] = useState([{ testName: 'app', testValue: 'afls' }]);
  
  //   const handleChange = (event) => {
  //     setqueryAddOnFields(event.target.value);
  //   };
  //   console.log("this SLI metric",queryAddOnFields)

  // Handle removing a set of fields
  const removeTestField = (index) => {
    const updatedFields = testFields.filter((_, i) => i !== index);
    setTestFields(updatedFields);
  };

  // Handle change in one of the fields in a set
  const handleFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = testFields.map((field, i) => 
      i === index ? { ...field, [name]: value } : field
    );
    setTestFields(updatedFields);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Data:', testFields);
  };

  return (
    <form onSubmit={handleSubmit}>
       <button type="button" onClick={addTestField}>Add On Parameters</button>
      {testFields.map((fieldSet, index) => (
        <div key={index}>
          <input
            type="text"
            name="testName"
            placeholder="Query parameter Key"
            value={fieldSet.testName}
            onChange={(event) => handleFieldChange(index, event)}
          />
          <input
            type="text"
            name="testValue"
            placeholder="Query parameter Value"
            value={fieldSet.testValue}
            onChange={(event) => handleFieldChange(index, event)}
          />
          <button type="button" onClick={() => removeTestField(index)}>Remove</button>
          <br></br><br></br>
        </div>
        
      ))}
     
      {/* <button type="submit">Submit</button> */}
    </form>
  );
};

export default DynamicForm;
