import { useCallback, useEffect, useRef, useState } from "react";
import UseSloInfo from "../../SlothContext";
import { submit } from "./functions/submit";
import { setContext } from "./functions/setContext";
import { populate } from "./functions/populateProductInfo";
import { Validate } from "./functions/validate";
import { SloProductFormService } from "./Services/SloProductFormService";
import { FormStates } from "./states";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../../../auth/authProviderMsalReact";
import { log } from "async";

export function useProductInfoForm() {
  //Defining states for product form input fields
  const [serviceName, setServiceName] = useState("");
  const [productId, setProductId] = useState("");
  const [OrgId, setOrgId] = useState();
  const [env, setEnv] = useState();
  const [headwigScope, setHeadwigScope] = useState("");
  const { instance, accounts } = useMsal();
  const apiEndpoint = process.env.REACT_APP_APIGEE_WRAPPER_API;
  const apiEndpointHedwigScope = process.env.REACT_APP_HEDWIG_SCOPE;

  const {
    selectedFormDetails,
    setSelectedFormDetails,
    cachedFormDetails,
    setCachedFormDetails,
    isMac,
    setIsMac,
    isLoading,
    setIsLoading,
    dropDownSequence,
    inValidValue,
    setInvalidValue,
  } = FormStates();

  //context state data

  const { sloData, setSloData, setMacValues, currentTabIndex } = {
    ...UseSloInfo(),
  };

  //setting the environment choice box state
  const selectThisEnv = (envValue) => {
    setEnv(envValue);
  };

  //setting the "org_id" choice box state
  const selectThisOrgId = (orgId) => {
    setOrgId(orgId);
  };

  //Function that will handle the submit form action
  const submitProductInfo = (event, navigate) => {
    submit(
      event,
      navigate,
      setSloData,
      serviceName,
      productId,
      headwigScope,
      OrgId,
      env,
      setMacValues,
      selectedFormDetails,
      cachedFormDetails
    );
  };

  // This function will be called each time the "product form" component is mounted on screen
  // This function will current input fields with the data stored in state
  // The purpose of this function is fetch the persisted data between tab switching

  const slothContextData = () => {
    setContext(
      sloData,
      setServiceName,
      setProductId,
      setOrgId,
      setEnv,
      setHeadwigScope,
      setSelectedFormDetails,
      setCachedFormDetails
    );
  };

  const clearDropDowns = (currentDropDown) => {
    let dropDownsToClear = dropDownSequence.slice(
      dropDownSequence.indexOf(currentDropDown) + 1
    );
    console.log("index", dropDownsToClear);
    dropDownsToClear.forEach((dropDown) => {
      setSelectedFormDetails((prevState) => (
        // console.log("clearInputs@95", prevState),
        {
        ...prevState,
        [dropDown]: "",
      }));
      setCachedFormDetails((prevState) => ({
        ...prevState,
        [dropDown]: [],
      }));
    });
  };


  const getHedwigScopes = (query) => {
    console.log("getHedwigScopes", query);
    if (query === "") {
      setIsLoading((prevState) => ({ ...prevState, hedwigScope: true }));
      setInvalidValue((prevState) => ({ ...prevState, hedwigScope: false }));
      SloProductFormService.getMyHedwigScopeData(
        instance,
        loginRequest,
        accounts,
        apiEndpointHedwigScope
      )
        .then((response) => {
          setCachedFormDetails((prevState) => ({
            ...prevState,
            hedwigScope: response,
          }));
          setIsLoading((prevState) => ({ ...prevState, hedwigScope: false }));
        })
        .catch((error) => {
          console.log(
            "Error while fetching your hedwig scopes , please try again later ",
            error
          );
        });
    } else {
      setIsLoading((prevState) => ({ ...prevState, hedwigScope: true }));
      SloProductFormService.getHedwigScopeData(
        instance,
        loginRequest,
        accounts,
        apiEndpointHedwigScope,
        query
      )
        .then((response) => {
          if (response.length === 0) {
            setInvalidValue((prevState) => {
              return { ...prevState, hedwigScope: true };
            });
            setIsLoading((prevState) => ({ ...prevState, hedwigScope: false }));
            return;
          }
          setCachedFormDetails((prevState) => ({
            ...prevState,
            hedwigScope: response,
          }));
          setIsLoading((prevState) => ({ ...prevState, hedwigScope: false }));
        })
        .catch((error) => {
          console.log(
            `Error while fetching hedwig scopes with name ${selectedFormDetails.hedwigScope}`,
            error
          );
        });
    }
  };

  useEffect(() => {
    getHedwigScopes("");
  }, []);

  useEffect(() => {
    setIsLoading((prevState) => ({ ...prevState, Portfolio: true }));
    SloProductFormService.getPortfolioData(
      instance,
      loginRequest,
      accounts,
      apiEndpoint
    )
      .then((response) => {
        console.log("portfolioData", response);
        setCachedFormDetails((prevState) => ({
          ...prevState,
          Portfolio: response,
        }));
        
      })
      .catch((error) => {
        console.log("Error While fetching Portfolio Data : ", error);
      }).finally(() => {
        setIsLoading((prevState) => ({ ...prevState, Portfolio: false }));
      });
  }, []);

  const handleDropDownClick = (IdParamter, calledFor) => {
    if (calledFor === "" || IdParamter === null || IdParamter === "") {
      return;
    }
    setIsMac(false);
    let functionName = "";
    if (calledFor === "Portfolio") {
      functionName = "getPortfolioData";
    } else if (calledFor === "Platform") {
      functionName = "getPlatformData";
    } else if (calledFor === "Product") {
      functionName = "getProductData";
    } else if (calledFor === "Application") {
      functionName = "getApplicationData";
    }
    setIsLoading((prevState) => ({ ...prevState, [calledFor]: true }));
    SloProductFormService[functionName](
      instance,
      loginRequest,
      accounts,
      apiEndpoint,
      IdParamter
    )
      .then((response) => {
        setCachedFormDetails((prevState) => ({
          ...prevState,
          [calledFor]: response,
        }));
        setIsLoading((prevState) => ({ ...prevState, [calledFor]: false }));
      })
      .catch((error) => {
        console.log(`Error While fetching ${calledFor} Data : `, error);
      });
  };

  const clearInputs = () => {
    console.log("clearInputs");
    setSelectedFormDetails((prevState) => (
      console.log("clearInputs", prevState),
      
      {
      ...prevState,
      AppID: selectedFormDetails.AppID,
      Portfolio: "",
      Platform: "",
      Product: "",
      Application: "",

    }));
  };

  const [debouncedAppID, setDebouncedAppID] = useState(
    selectedFormDetails.AppID
  );

  useEffect(() => {
    // Set a delay
    const timerId = setTimeout(() => {
      setDebouncedAppID(selectedFormDetails.AppID);
    }, 700); // 1000ms delay

    // Cleanup
    return () => {
      clearTimeout(timerId);
    };
  }, [selectedFormDetails.AppID]);

  useEffect(() => {
    if (debouncedAppID && sloData?.AppID !== debouncedAppID) {
      // ... rest of your code

    
      clearInputs();
      setIsLoading((prevState) => ({ ...prevState, AppID: true }));
      SloProductFormService.getMACApplicationData(
        instance,
        loginRequest,
        accounts,
        apiEndpoint,
        selectedFormDetails.AppID
      )
        .then((response) => {
          setInvalidValue((prevState) => {
            return { ...prevState, AppID: false };
          });
          setIsMac(true);
          setSelectedFormDetails((prevState) => (   
            {
            Environment: prevState.Environment,
            hedwigScope: prevState.hedwigScope,
            AppID: prevState.AppID.toUpperCase(),
            Portfolio: {
              id: response.portFolioName,
              label: response.portFolioName,
              value: response.portFolioName,
            },
            Platform: {
              id: response.platformName,
              label: response.platformName,
              value: response.platformName,
            },
            Product: {
              id: response.platformName,
              label: response.platformName,
              value: response.platformName,
            },
            Application: {
              id: response.applicationName,
              label: response.applicationName,
              value: response.applicationName,
            },
          }));
   
        })
        .catch((error) => {
          setInvalidValue((prevState) => {
            return { ...prevState, AppID: true };
          });
          console.log("Error While fetching AppID : ", error);
        })
        .finally(() => {
          setIsLoading((prevState) => ({ ...prevState, AppID: false }));
        });
    }
  }, [debouncedAppID]);

  // console.log("selectedFormDetails", selectedFormDetails);
  // console.log("cachedFormDetails", cachedFormDetails);
  // console.log("inValidValue", inValidValue);

  return {
    serviceName,
    productId,
    headwigScope,
    OrgId,
    env,
    submitProductInfo,
    setServiceName,
    setHeadwigScope,
    setProductId,
    selectThisEnv,
    selectThisOrgId,
    slothContextData,
    selectedFormDetails,
    setSelectedFormDetails,
    cachedFormDetails,
    setCachedFormDetails,
    handleDropDownClick,
    isMac,
    isLoading,
    clearDropDowns,
    inValidValue,
    getHedwigScopes,
  };
}

export function usePopulateFields() {
  const { sloData, setSloData } = { ...UseSloInfo() };

  const populateProductInfoFormValues = (data) => {
    return populate(data, setSloData);
  };
  return {
    populateProductInfoFormValues,
  };
}

//Hook that is used to validate the input fields
export function useValidation(values, validationRules) {
  //State variables that will be used to mention valid/invalid the current state input values
  const [isValidProductId, setIsValidProductId] = useState(true);
  const [isValidServiceName, setIsValidServiceName] = useState(true);
  const [isValidHeadwigScope, setIsValidHeadwigScope] = useState(true);

  Validate(
    values,
    validationRules,
    isValidProductId,
    setIsValidProductId,
    isValidServiceName,
    setIsValidServiceName,
    isValidHeadwigScope,
    setIsValidHeadwigScope
  );

  return {
    isValidProductId,
    isValidServiceName,
    isValidHeadwigScope,
  };
}

export function useSlothContext() {}
