
import React, { Suspense } from "react";
import "./App.css";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Manifesto from "./Pages/Manifesto";
import { Route, Routes } from "react-router-dom";
import { PageLayoutProvider } from "./hooks/PageLayout";

import Certificates from "./Capabilities/Reliability/CertValidation/Certificates";
import URLBatch from "./Capabilities/Reliability/CertValidation/URLBatch";
import { URLBatchReport } from "./Capabilities/Reliability/CertValidation/URLBatchReport";
import UrlSingleReport from "./Capabilities/Reliability/CertValidation/UrlSingleReport";
import URLSingle from "./Capabilities/Reliability/CertValidation/URLSingle";
import Forensics from "./Capabilities/Reliability/Forensics/Forensics";
import PostgresForensicForm from "./Capabilities/Reliability/Forensics/PostgresForensics/PostgresForensicForm";
import SQLForensicForm from "./Capabilities/Reliability/Forensics/SqlForensics/SQLForensicForm";
import Neo4jForensicForm from "./Capabilities/Reliability/Forensics/Neo4jForensics/Neo4jForensicForm";
import CiForm from "./OnBoarder/Forms/CiForm";
import PlatformForm from "./OnBoarder/Forms/PlatformForm";
import UrlOnBoardInfoForm from "./OnBoarder/Forms/UrlOnBoardInfoForm";
import { PostgresReport } from "./Capabilities/Reliability/Forensics/PostgresForensics/PostgresReport";
import { SQLReport } from "./Capabilities/Reliability/Forensics/SqlForensics/SQLReport";
import { Neo4jReport } from "./Capabilities/Reliability/Forensics/Neo4jForensics/Neo4jReport";
import Upcoming from "./Pages/Upcoming";





import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import OnBoarding from "./Pages/OnBoarding";
import JobScheduleForm from "./OnBoarder/Forms/JobScheduleForm";
import TeamForm from "./OnBoarder/Forms/TeamForm";
import SRELibrary from "./Pages/SRELibrary";
import SloDashboards from "./Capabilities/Reliability/SLODashboards/SloDashboards";
import CapabilityPageUI from "./Pages/Capabilities/CapabilityPageUI";
import SlothUI from "./Capabilities/Reliability/SLODashboards/Sloth/SlothUI";
import SideBar from "./Pages/Capabilities_revamp/sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import NavBar from "./Components/NavBar";
import HomeHeading from "./Pages/Capabilities_revamp/Pages/Home/HomeHeading";
import Forensic from "./Pages/Capabilities_revamp/Components/Forensic/Forensic";
import CertficateValidation from "./Pages/Capabilities_revamp/Pages/CertificateValidation.jsx/CertficateValidation";
import ValidationForUrls from "./Pages/Capabilities_revamp/Components/ValidationForUrls.jsx/ValidationForUrls";
import OnboardUrl from "./Pages/Capabilities_revamp/Components/OnboardUrl/OnboardUrl";
import Autotron from "./Pages/Capabilities_revamp/Pages/Autotron/Autotron";

const MainContent = () => {

  const location = useLocation();

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <PageLayoutProvider>
          {/* <RequestInterceptor> */}
          {/* <Suspense fallback={<div>Loading...</div>}> */}

          {(location.pathname === "/capability" || location.pathname === "/slo_service" || location.pathname === "/forensic" ||
              location.pathname === "/CertficateValidation" || location.pathname === "/validation_urls" || 
              location.pathname === "/slodashboarding" || location.pathname === "/slo-service" || 
              location.pathname === "/ValidationForUrls" || location.pathname === "/OnboardUrl" ||
              location.pathname === "/manifesto" || location.pathname === "/about" || location.pathname === "/autotron") && <NavBar />}
          <div style={{ display: "flex" }}>
            {/* {console.log("Location" ,location.pathname)} */}
            {(location.pathname === "/capability" || location.pathname === "/slo_service" || location.pathname === "/forensic" ||
              location.pathname === "/CertficateValidation" || location.pathname === "/validation_urls" || 
              location.pathname === "/slodashboarding" || location.pathname === "/slo-service" || 
              location.pathname === "/ValidationForUrls" || location.pathname === "/OnboardUrl" || location.pathname === "/autotron") && <SideBar />}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/manifesto" element={<Manifesto />} />
              <Route path="/capability" element={<HomeHeading />} />
              <Route path="/forensic" element={<Forensic />} />
              <Route path="/CertficateValidation" element={<CertficateValidation />} />
              <Route path="/ValidationForUrls" element={< ValidationForUrls /> } />
              <Route path="/OnboardUrl" element={< OnboardUrl />} />
              <Route path="/autotron" element={< Autotron />} />
              
              {/* OLD ROUTES */}
              {/* <Route path="/capability" element={<CapabilityPageUI />} /> */}
              <Route path="/certificates" element={<Certificates />} />
              <Route path="/URLBatch" element={<URLBatch />} />
              <Route path="/urlsinglereport" element={<UrlSingleReport />} />
              <Route path="/urlsingle" element={<URLSingle />} />
              <Route path="/forensics" element={<Forensics />} />
              <Route
                path="/PostgresForensicForm"
                element={<PostgresForensicForm />}
              />
              <Route path="/SqlForensicForm" element={<SQLForensicForm />} />
              <Route path="/postgresreport" element={<PostgresReport />} />
              <Route path="/sqlreport" element={<SQLReport />} />
              <Route path="/Neo4jForensicForm" element={<Neo4jForensicForm />} />
              <Route path="/neo4jreport" element={<Neo4jReport />} />
              <Route path="/urlbatchreport" element={<URLBatchReport />} />
              <Route path="/upcoming" element={<Upcoming />} />
              <Route path="/capability" element={<CapabilityPageUI />} />
              <Route path="/onBoarding" element={<OnBoarding />} />
              <Route path="/team" element={<TeamForm />} />
              <Route path="/ci" element={<CiForm />} />
              <Route path="/platform" element={<PlatformForm />} />
              <Route path="/cert_url" element={<UrlOnBoardInfoForm />} />
              <Route path="/jobschedule" element={<JobScheduleForm />} />
              <Route path="/contribute" element={<SRELibrary />} />
              <Route path="/runbooks" element={<SRELibrary />} />
              <Route path="/slodashboarding" element={<SloDashboards />} />

              <Route
                path="/slo-service"
                element={

                  <SlothUI />

                }
              />
            </Routes>
          </div>

          {/* </Suspense> */}
        </PageLayoutProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 style={{ textAlign: "center" }} className="card-title">
          Please Wait
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
};
function App() {
  useMsalAuthentication(InteractionType.Redirect);
  return <MainContent />;
}

export default App;
