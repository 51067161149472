import { useState } from "react";

export const FormStates = () => {
  const [selectedFormDetails, setSelectedFormDetails] = useState({
    AppID: "",
    Portfolio: "",
    Platform: "",
    Product: "",
    Application: "",
    Environment: "",
    hedwigScope: "",
  });

  const [cachedFormDetails, setCachedFormDetails] = useState({
    Portfolio: [],
    Platform: [],
    Product: [],
    Application: [],
    hedwigScope: [],
  });

  const [isMac, setIsMac] = useState(false);

  const [isLoading, setIsLoading] = useState({
    AppID: false,
    Portfolio: false,
    Platform: false,
    Product: false,
    Application: false,
    hedwigScope: false,
  });

  const dropDownSequence = [
    "Portfolio",
    "Platform",
    "Product",
    "Application",
    "Environment",
  ];

  const [inValidValue, setInvalidValue] = useState({
    Portfolio: false,
    Platform: false,
    Product: false,
    Application: false,
    AppID: false,
    hedwigScope: false,
  });

  return {
    selectedFormDetails,
    setSelectedFormDetails,
    cachedFormDetails,
    setCachedFormDetails,
    isMac,
    setIsMac,
    isLoading,
    setIsLoading,
    dropDownSequence,
    inValidValue,
    setInvalidValue,
  };
};
