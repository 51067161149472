import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import NavBar from "../../Components/NavBar";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

function JobScheduleForm() {
  const [jobName, setJobName] = useState("cert_batch_url");
  const [oldJobName, setOldJobName] = useState("");
  const [startHour, setStartHour] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [loading, setLoading] = useState("");
  const [opp, setOpp] = useState("Insert");
  const [jName, setJName] = useState("cert_batch_url");
  const [jobNames, setJobNames] = useState([]);
  const [jobId, setJobId] = useState("");
  console.log(jobName);
  useEffect(() => {
    setJobName("");
    setStartHour("");
    setStartMinute("");
    setDayOfWeek("");
    setJName("");
    if (opp === "Update" || opp === "Delete") {
      if (opp !== "") {
        //fetchJobNames();
        fetchJobData();
      }
    }
  }, [opp]);



  const fetchJobData = async () => {
    try {

      let jobData = await axios.get(
        `${process.env.REACT_APP_API}/getJobData?name=cert_batch_url`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      // console.log(await jobData.json());
      jobData = jobData.data.data;

      if (jobData.error) {
        if (jobData.status === 401) {
          throw new Error(jobData.message);
        }
        throw new Error(
          "Unable to get Job data from server, please refresh the page"
        );
      } else {
        // console.log(jobData.data.data[0]);
        setJobName(jobData.data[0].job_name);
        setStartHour(jobData.data[0].start_hr);
        setStartMinute(jobData.data[0].start_min);
        setOldJobName(jobData.data[0].job_name);
        setJobId(jobData.data[0].id);
        if (jobData.data[0].day_of_week === "0") setDayOfWeek("0 - DAILY");
        if (jobData.data[0].day_of_week === "1") setDayOfWeek("1 - SUNDAY");
        if (jobData.data[0].day_of_week === "2") setDayOfWeek("2 - MONDAY");
        if (jobData.data[0].day_of_week === "3") setDayOfWeek("3 - TUESDAY");
        if (jobData.data[0].day_of_week === "4") setDayOfWeek("4 - WEDNESDAY");
        if (jobData.data[0].day_of_week === "5") setDayOfWeek("5 - THURSDAY");
        if (jobData.data[0].day_of_week === "6") setDayOfWeek("6 - FRIDAY");
        if (jobData.data[0].day_of_week === "7") setDayOfWeek("7 - SATURDAY");
      }
    } catch (err) {
      alert(err);
    }
  };
  const formInputFieldData = [
    {
      name: "Job Name",
      type: "text",
      onChange: "setJobName",
      value: jobName,
      required: true,
      placeholder: "Enter Job Name",
    },
    {
      name: "Start Hour",
      // type: "text",
      type: "number",
      min: "0",
      step: "1",
      max: "23",
      onChange: "setStartHour",
      value: startHour,
      required: true,
      placeholder: "UTC TimeZone",
      pattern: "[:digit]",
    },
    {
      name: "Start Minute",
      type: "number",
      min: "0",
      max: "59",
      step: "1",
      onChange: "setStartMinute",
      value: startMinute,
      required: true,
      placeholder: "UTC TimeZone",
      pattern: "[:digit]",
    },
    {
      name: "Day of Week",
      type: "select",
      onChange: "setDayOfWeek",
      value: dayOfWeek,
      names: [
        "0 - DAILY",
        "1 - SUNDAY",
        "2 - MONDAY",
        "3 - TUESDAY",
        "4 - WEDNESDAY",
        "5 - THURSDAY",
        "6 - FRIDAY",
        "7 - SATURDAY",
      ],
      text: "Select the Day",
      required: true,
    },
  ];

  const changeValue = (value, e) => {
    switch (value) {
      case "setJobName":
        if (opp === "") {
          setJobName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setJobName(e.target.value);
        break;
      case "setStartHour":
        if (opp === "") {
          setStartHour("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setStartHour(e.target.value);
        break;

      case "setDayOfWeek":
        if (opp === "") {
          setDayOfWeek("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setDayOfWeek(e.target.value);
        break;

      case "setStartMinute":
        if (opp === "") {
          setStartMinute("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setStartMinute(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleOppChange = (e) => {
    setOpp(e.target.value);
  };

  const handleJobNameUptDel = (e) => {
    setJName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parseInt(startHour) >= 24) {
      alert(
        "Please enter a valid start hour value. It should be between 0 to 23\nPress ok to continue"
      );
      return;
    }
    if (parseInt(startMinute) >= 60) {
      alert(
        "Please enter a valid start minute value. It should be between 0 to 59\nPress ok to continue"
      );
      return;
    }
   
    if (opp === "Insert") {
      setLoading(true);
      try {
        // let job_Name = jobName.trim();
        let job_Name = "cert_batch_url";
        let start_Hour = startHour.trim();
        let start_Minute = startMinute.trim();
        let body = { job_Name, start_Hour, start_Minute, dayOfWeek };
        const response = await axios.post(
          `${process.env.REACT_APP_API}/addinjobschedule`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            ...body,
            mode: "cors",
          }
        );
        let responseData = response.data.data;
        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable to schedule the job\nPossible Reasons:\n1: A job is already scheduled at that time.\n2: Server is down.\n3: Network Error.\n4: Database is down.\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          alert("Job scheduled successfully!\nPress ok to continue");
          setDayOfWeek("");
          setStartHour("");
          setJobName("");
          setStartMinute("");
          setOpp("");
          // window.location.reload();
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }

    ///Update
    else if (opp === "Update") {
      setLoading(true);
      try {
        let job_Name = jobName.toString().trim();
        let start_Hour = startHour.toString().trim();
        let start_Minute = startMinute.toString().trim();
        let body = { job_Name, start_Hour, start_Minute, dayOfWeek, jobId };
        const response = await axios.post(
          `${process.env.REACT_APP_API}/updateJob`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            ...body,
            mode: "cors",
          }
        );
        let responseData = response.data.data;
        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable to update the job\nPossible Reasons:\n1: A job is already scheduled at that time.\n2: Server is down.\n3: Network Error.\n4: Database is down.\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          alert("Job schedule updated successfully!\nPress ok to continue");
          setDayOfWeek("");
          setStartHour("");
          setJobName("");
          setStartMinute("");
          setOpp("");
          setJName("");
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
    //==========================================================================================================
    //DELETION
    else {
      if (!window.confirm("Do you really want to delete your job?")) {
        return;
      }
      setLoading(true);
      try {
        const body = { jobId };
        const response = await axios.delete(
          `${process.env.REACT_APP_API}/deleteJob`,
          {
            mode: "cors",
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              jobId: jobId,
            },
          }
        );

        let responseData = response.data.data;

        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          // setLoading(false);
          throw new Error(
            "Unable to Delete your Job\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          setDayOfWeek("");
          setStartHour("");
          setJobName("");
          setStartMinute("");
          setOpp("");
          setJName("");
          alert("Your Job has been successfully deleted\nPress ok to continue");
          // window.location.reload();
        }
      } catch (err) {
        // console.log(err.message)
        setLoading(false);
        alert(err.message);
      }
    }
  };
  return (
    <>
      <NavBar />
      <div className="topContainer">
        {loading ? (
          <h2 style={{ fontWeight: "100" }}>
            {opp === "Insert"
              ? "Scheduling "
              : opp === "Update"
              ? "Updating "
              : "Deleting "}
            your Job, Please wait!!
          </h2>
        ) : (
          <>
            <h1 style={{ fontWeight: "100" }}>Job Schedule Info</h1>
            <div className="formContainer">
              <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3 formGroup"
                  controlId="formBasicEmail"
                >
                  <Form.Label style={{}} className="formLabel">
                    Operation
                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                  </Form.Label>
                  <Box
                    sx={{
                      minWidth: 120,
                      marginBottom: "20px",
                      marginTop: "20px",
                      border: "none",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Operation
                      </InputLabel>
                      <Select
                        style={{ borderTop: "0px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={opp}
                        label="Select Opeartion"
                        onChange={(e) => handleOppChange(e)}
                      >
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Insert"
                        >
                          Schedule Job
                        </MenuItem>
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Update"
                        >
                          Update Job
                        </MenuItem>
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Delete"
                        >
                          Delete Job
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {opp === "Update" || opp === "Delete" ? (
                    <Box
                      sx={{
                        minWidth: 120,
                        marginBottom: "20px",
                        marginTop: "20px",
                        border: "none",
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Job
                        </InputLabel>
                        <Select
                          style={{ borderTop: "0px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value="cert_batch_url"
                          label="Select Platform"
                          onChange={(e) => handleJobNameUptDel(e)}
                          disabled="true"
                        >
                          {/* {jobNames.length > 0 &&
                            jobNames.map((jobName) => {
                              return ( */}
                          <MenuItem
                            // key={jobName.id}
                            // onClick={() => setJobId(jobName.id)}
                            style={{ fontFamily: "Maersk Text" }}
                            value={"cert_batch_url"}
                          >
                            cert_batch_url
                          </MenuItem>
                          {/* );
                            })}  */}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}
                  {formInputFieldData.map((data) => {
                    return (
                      <>
                        <Form.Label className="formLabel">
                          {data.name}{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            *
                          </span>
                        </Form.Label>

                        {data.type === "select" ? (
                          <Box
                            sx={{
                              minWidth: 120,
                              marginBottom: "20px",
                              marginTop: "10px",
                              border: "none",
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {data.text}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.value}
                                label={data.text}
                                onChange={(e) => changeValue(data.onChange, e)}
                                placeholder="Select The Day"
                                disabled={opp === "Delete"}
                              >
                                {data.names.length != 0 &&
                                  data.names.map((name) => {
                                    return (
                                      <MenuItem
                                        style={{ fontFamily: "Maersk Text" }}
                                        value={name}
                                      >
                                        {name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                        ) : (
                          <Form.Control
                            className="inputBox"
                            type={data.type}
                            placeholder={data.placeholder}
                            onChange={(e) => changeValue(data.onChange, e)}
                            value={
                              data.name === "Job Name"
                                ? "cert_batch_url"
                                : data.value
                            }
                            required={data.required ? true : false}
                            pattern={data.pattern}
                            min={data.min}
                            step={data.step}
                            max={data.max}
                            disabled={
                              opp === "Delete" || data.name === "Job Name"
                            }
                          />
                        )}
                      </>
                    );
                  })}

                  <div className="buttonContainer">
                    <Button type="submit" className="submitButton">
                      {opp === "Insert"
                        ? "Submit"
                        : opp === "Update"
                        ? "Update"
                        : "Delete"}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default JobScheduleForm;
