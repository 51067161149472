import React from 'react'
import "../../StyleSheets/CapabilityHeading.css"

function Heading() {
  return (
    <div style={{ marginTop: "2rem", paddingLeft: "40px" }}>
      <h1
        style={{ textAlign: "left", marginBottom: 0 }}
        className="text-left heading__capabilityPage"
      >
        <strong>SRE and Performance Capabilities</strong>
      </h1>
      <p style={{ color: "#00243D", fontSize: "20px" }} className="capabilityPage__headingPara">
        SkyDeck products and capabilities for improving your systems'
        reliability!
        <br />Access the products in the menus below.
        <br />
        <br />
        Refer to <nbsp></nbsp>
        <a style={{ textDecoration: "none" }} href={`${process.env.REACT_APP_SRE_SKYDECK_LIBRARY_SITE}`} target="_blank">
          Library
        </a>{" "}
        for more tools, patterns, documents for your reference and use.
        <br />
        You can also contribute to SkyDeck capabilities, tools and documents.
        <br />
      </p>
    </div>
  )
}

export default Heading