import React from 'react'
import NavBar from '../../Components/NavBar'
import Capabilities from './Capabilities'
import Heading from './Heading'
import UI_CONF from "./capability_ui_config"

function CapabilityPageUI() {
  return (
    <>
      <NavBar />
      <div className='capabilities__topContainer'>
        <div className='capabilities__contentWrapper'>
          <Heading />
          <Capabilities capabilities={UI_CONF} />
        </div>
      </div>
    </>

  )
}

export default CapabilityPageUI