// src/Popup.js
// import React from 'react';
import React, { useState } from 'react';
import "../../../../../../StyleSheets/Popup.css";

const Popup = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* <button className="popup-close" onClick={onClose}>
          &times;
        </button> */}
        {children}
        {/* Grafana code */}
      </div>
    </div>
  );
};
  
export default Popup;
