import React from "react";
import { Container, Row, Col, Card} from "react-bootstrap";
import brand from "../../../Images/Forensics.png";
import NavBar from "../../../Components/NavBar";
import "../../../StyleSheets/Forensics.css";
function Forensics() {
  return (
    <>
      <section>
        <NavBar />
        <Container className="py-3 my-5">
          <Row className="m-5">
          <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer cardWidth"
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5 style={{fontSize: "20px"}}>Database Forensics</h5>
                      <p >Database Forensics provide deep-dive metrics that give insights into configuration and performance of your database.</p>
                    {/* </a> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3 ">
              <Card
                style={{backgroundColor:"white" ,border:"none"}}
                className="shadow py-2 my-3  imgContainer cardWidth"
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>Postgres Forensics</h5> */}
                    <a style={{textDecoration:"none"}}href="/PostgresForensicForm" >
                       Postgres Forensics 
                      {/* <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={brand}
                        alt="Card image cap" */}
                      {/* /> */}

                    </a>
                  
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col className="col-3">
              <Card
              style={{backgroundColor:"white" ,border:"none"}}
                className="shadow py-2 my-3  imgContainer cardWidth"
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>SQL Forensics</h5> */}
                    <a style={{textDecoration:"none"}} href="/SqlForensicForm">
                      {/* <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={brand}
                        alt="Card image cap"
                      /> */}
                      SQL Server Forensics
                    </a>

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3">
              <Card
              style={{backgroundColor:"white" ,border:"none"}}
                className="shadow py-2 my-3  imgContainer cardWidth"
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>SQL Forensics</h5> */}
                    <a style={{textDecoration:"none"}} href="/Neo4jForensicForm">
                      {/* <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={brand}
                        alt="Card image cap"
                      /> */}
                      Neo4j Server Forensics
                    </a>
                   
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="m-5">
          <Col className="col-3">
              <Card
              
                className="shadow py-2 my-3  imgContainer cardWidth"
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5 style={{fontSize: "20px"}}>Application Forensics</h5>
                    {/* <a href="#"> */}
                      {/* <img
                        className="p-4"
                        style={{ borderRadius: "20%", height: "110px" }}
                        src={brand}
                        alt="Card image cap"
                      /> */}
                      <p>Application Forensics provide deep-dive metrics that give insights into your application's performance</p>
                    {/* </a> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3 ">
              <Card
              style={{backgroundColor:"white" ,border:"none"}}
                className="shadow py-2 my-3  imgContainer cardWidth"
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>Postgres Forensics</h5> */}
                 <p>Watch this space!!</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
           
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Forensics;
