import React from "react";
import parse from 'html-react-parser'
// import { Container, Navbar } from "react-bootstrap";
// import brand from "../Images/maersk.svg";

const Health = () => {
    const yourHtmlString = '{"status":"ok"}'
  return (
     <>
      {/* <Navbar
        className="navbarStyle  navbar-expand-lg navbar-light"
        expand="lg"
      >
        <Container className="container-fluid px-2 mx-2">
          <Navbar.Brand href="/">
            <img src={brand} />
          </Navbar.Brand>
        </Container>
        
      </Navbar> */}
      <div className="px-3 py-1">

{parse(yourHtmlString)}
      </div>
      </>
    
   
);
  }
export default Health;
