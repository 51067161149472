import { useEffect } from "react";

export const Validate = (values,validationRules, isValidProductId, setIsValidProductId,isValidServiceName, setIsValidServiceName, isValidHeadwigScope, setIsValidHeadwigScope) => {
      //Continuously checks for the valid/invalid inputs
    useEffect(() => {
        setIsValidServiceName(validateServiceName(values, validationRules));
        setIsValidProductId(validateProductId(values, validationRules));
        setIsValidHeadwigScope(validateHeadwigScope(values, validationRules));
      }, [
        values.serviceName,
        values.productId,
        values.headwigScope,
      ]);
}

function validateServiceName(values, validationRules) {

    if (validationRules.pattern.test(values.serviceName)){
      console.log("Test1", validationRules.pattern.test(values.serviceName))
      return false
    }
    if(values.serviceName.indexOf(" ") >= 0){
      console.log("Test2", values.serviceName.indexOf(" ") >= 0)
      return false
    }
    return true
 
  }


  function validateProductId(values, validationRules) {
    return (
      values.productId &&
      values.productId.trim().length > 0 &&
      !validationRules.pattern.test(values.productId)
    );
  }

  function validateHeadwigScope(values, validationRules) {
    return (
      values.headwigScope &&
      values.headwigScope.trim().length > 0 &&
      !validationRules.pattern.test(values.headwigScope)
    );
  }