import { IconButton, Tooltip  } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from "@material-ui/core";
import React, { useState , useEffect } from "react";
import {
  Button,
  Form,

} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../Components/NavBar";
import "../../../../StyleSheets/DBForm.css";
import { Neo4jReport } from "./Neo4jReport";


const useStyles = makeStyles(theme => ({
  customFontSize : {
    fontSize : '15px'
  }
}))

function Neo4jForensicForm() {
  const navigate = useNavigate();
  const classes = useStyles()


  const [showReport, setShowReport] = useState(false);
  //const [bolt, setbolt] = useState("");
    const [Uri, setUri] = useState();
    const [port, setPort] = useState(7687);
    const [userName, setuserName] = useState("");
    const [password, setPassword] = useState("");
    
    // const [sslmode, setsslmode] = useState(process.env.REACT_APP_PG_SSLMODE_DEFAULT);

    // const handleboltChange = (e) => {
    //   setbolt(e.target.value);
    // };
    const handleUriChange = (e) => {
      setUri(e.target.value);
    };
    const handlePortChange = (e) => {
      setPort(e.target.value);
    };
    const handleUserNameChange = (e) => {
      setuserName(e.target.value);
    };
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };

    // const handleSSLmode = (e) => {
    //   setsslmode(e);
    // };


  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate("/neo4jreport",{state: {Uri,port,userName,password}});
    setShowReport(true);
  };
  // const handleClick = (e) => {
  //   handleSSLmode(e);
  // };

  const [isReportLoaded, setIsReportLoaded] = useState(false);

  useEffect(() => {
    if (isReportLoaded) {
      const element = document.getElementById("report");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    console.log("isReportLoaded", isReportLoaded);
    //unmounting 
    return () => {
      setIsReportLoaded(false);
    }
  }, [isReportLoaded])

  return (
    <>
      <NavBar />
      <div className="forenform__topContainer">
        {/* <h2>Neo4j Server Forensic Form</h2> */}
        <div className="foren__formContainer foren_formContainer_md">
          <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {/* <Form.Label className="formLabel">bolt <span style={{color: "red", fontWeight: "bold"}}>*</span></Form.Label>
              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="text"
                placeholder=""
                onChange={handleboltChange}
                value={bolt}
                required
              /> */}
              <Form.Label className="formLabel">URI<span style={{color: "red", fontWeight: "bold"}}>*</span></Form.Label>
              {/* <Tooltip placement = "top" title="Provide 1433 or other port" classes={{tooltip: classes.customFontSize}}>
                <IconButton>
                  <InfoIcon  style={{fontSize:'15px', color:"orange"}}/>
                </IconButton>
              </Tooltip> */}
              {/* </Form.Label> */}

              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="text"
                //min="0"
                //max="100000"
                placeholder=""
                onChange={handleUriChange}
                value={Uri}
                required
                
               
              />
              <Form.Label className="formLabel">Port <span style={{color: "red", fontWeight: "bold"}}>*</span></Form.Label>
              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="text"
                placeholder=""
                onChange={handlePortChange}
                value={port}
                required
              />
              {/* <Form.Label className="formLabel">SSLMode <span style={{color: "red", fontWeight: "bold"}}>*</span></Form.Label> */}
              {/* <InputGroup className="mb-3"> */}
                {/* <FormControl
                  aria-label="Text input with dropdown button"
                  className="inputBox"
                  value={sslmode}
              
                />
                <div
              
                >
                  <DropdownButton
                    variant="default"
                    title="select"
                    id="input-group-dropdown-2"
                    align="end"
                    onSelect={handleClick}
                    className="selectButton"
                  >
                    <Dropdown.Item eventKey={"false"}>false</Dropdown.Item>
                    <Dropdown.Item eventKey={"true"}>true</Dropdown.Item>
                  </DropdownButton>
                </div>
              </InputGroup> */}
              <Form.Label className="formLabel">UserName 
              {/* <Tooltip placement = "top" title={`Provide your database user with admin or monitor privileges. If onboarded with SRE, you can use ${process.env.REACT_APP_PG_USERNAME_DEFAULT} user as well. If not, use your admin/monitor user`} classes={{tooltip: classes.customFontSize}}>
                <IconButton>
                  <InfoIcon  style={{fontSize:'15px', color:"orange"}}/>
                </IconButton>
              </Tooltip> */}
             
              </Form.Label>
              <p style={{fontSize:"12px", marginBottom:"5px"}}>Provide your database user with admin or monitor privileges. If onboarded with SRE (with standard username), username is optional. If not, use your admin/monitor user</p>
              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="text"
                placeholder=""
                value={userName}
                onChange={handleUserNameChange}
                // required
              />
             
              <Form.Label className="formLabel">Password
              {/* <span style={{color: "red", fontWeight: "bold"}}>*</span> */}
              {/* <Tooltip placement = "top" title={`Required if user name is other than  ${process.env.REACT_APP_SQL_USERNAME_DEFAULT}`} classes={{tooltip: classes.customFontSize}}>
                <IconButton>
                  <InfoIcon  style={{fontSize:'15px', color:"orange"}}/>
                </IconButton>
              </Tooltip> */}
              </Form.Label>
              <p style={{fontSize:"12px", marginBottom:"5px"}}>Optional if you are not providing username else provide the password corresponding to the username</p>
              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                //required={userName !== "" ? true : false}
                // required={userName !==  process.env.REACT_APP_SQL_USERNAME_DEFAULT ? true : false}
              />
              
              
             
              <div className="buttonContainer">
                <Button type="submit" className="forenform__submitButton">
                  Submit
                </Button>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div id="report">
          {showReport && <Neo4jReport
            Uri={Uri}
            port={port}
            userName={userName}
            password={password}
            setIsReportLoaded={setIsReportLoaded}
          />}
        </div>
      </div>
    </>
  );
}

export default Neo4jForensicForm;