import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import NavBar from "../../../Components/NavBar";
import "../../../StyleSheets/UrlSingle.css";
import UrlSingleReport from "./UrlSingleReport";


function URLSingle() {
  const [apiData, setApiapiData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState();


  //show is  state variable that we will use to conditionally render the UrlSingleReport component.
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);

  
  //function called once the submit button has been pressed
  const handleSubmit = (e) => {
   e.preventDefault();
    setShow(!show);
    setCount(count+1);
    fetchData()
    

  };


 
  let config = {
    headers: {
      "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
    }
  }
  const fetchData = async() => {
 
    const wrapperApi = process.env.REACT_APP_APIGEE_WRAPPER_API
    const url = wrapperApi+`/cert/url?url=${inputData}`
    const res = await axios.get(url, config);
    setApiapiData(res.data);
    setLoading(false);
  }



  const renderComponent = () => {
   
    if(show){
    
      return <UrlSingleReport apiData = {apiData} isLoading= {loading}/>
    }
    else if(count === 0){

      return "";
    }
    else {
    
    
      return <UrlSingleReport  apiData = {apiData} isLoading= {loading}/>
    }
  }
 

  return (
    <>
      {/* <NavBar /> */}

      <div className="container-fluid px-5 mx-3 text-center topContainerCert">
        {/* <h2 className="headingURL">URL Validator</h2> */}
        <div className="container SingleformContainer p-4 containDiv">
          <Form
            className="py-2 px-2 m-0 certValidationForm"
            // onSubmit={handleSubmit}
          >
           
            <Form.Label className="formLabel">Check Your URL: </Form.Label>
            <Form.Control
              className="URL"
              type="text"
              placeholder=""
              onChange={(e) => {
                setInputData(e.target.value);
              }}
              value={inputData}
           
              required
            />
            <br />

            <Button
            type="button"
            onClick={handleSubmit}
             
              className="btn btn-dark m-3 text-center submitBtn"
             
            >
              Submit
            </Button>
           
          </Form>
        </div>

        {renderComponent()} 
      </div>
    </>
  );
}

export default URLSingle;
