import React from 'react';

function TruncatedParagraph(props) {
  const { text, limit } = props;

  if (text.length > limit) {
    const truncatedText = text.slice(0, limit) + '...';
    return <p>{truncatedText}</p>;
  }

  return <p>{text}</p>;
}

export default TruncatedParagraph;