import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBar from "../../../Components/NavBar";
import UserInfo from "../../../hooks/PageLayout";
import Table from "react-bootstrap/Table";
import "../../../StyleSheets/SloDashboards.css";
import Loading from "../../../Components/Loading";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GitHubIcon from "@mui/icons-material/GitHub";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import BadgeIcon from "@mui/icons-material/Badge";
import DescriptionIcon from "@mui/icons-material/Description";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import CategoryIcon from "@mui/icons-material/Category";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import yaml from "js-yaml";
import { Description, Visibility } from "@material-ui/icons";
import TruncatedParagraph from "../../../Components/TruncatedParagraph";

function SloDashboards() {
  const limit = 10;

  const [filesData, setFilesData] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfRowsRendered, setNoOfRowsRendered] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [searchFailed, setSearchFailed] = useState(false);
  const [tobeViewedSloId, setTobeViewedSloId] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loadingSloInfos, setLoadingSloInfos] = useState(false);
  const [loadingSloInfosError, setLoadingSloInfosError] = useState(false);
  const [loadedSlos, setLoadedSlos] = useState([]);
  const [show, setShow] = useState(true);
  const boxRefs = useRef([]);

  const navigate = useNavigate();

  const { token } = UserInfo();

  let startIndex = (currentPage - 1) * limit;
  let endIndex = currentPage * limit;

  useEffect(() => {
    console.log("fetching files");
    fetchFiles();
  }, [token]);

  useEffect(() => {
    // if (currentPage == totalPages) {
    //   setEndReached(true);
    //   return;
    // }
    let startIndex = (currentPage - 1) * limit;
    let endIndex = currentPage * limit;

    console.log(endIndex);
    if (filesData) {
      setTableData(() => filesData.slice(startIndex, endIndex));
      setNoOfRowsRendered(endIndex);
    }
  }, [currentPage]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (fileName) {
      fetchSlos();
    }
  }, [fileName]);

  useEffect(() => {
    // Add event listener to close the arrow when user clicks outside of it
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".sloDashboard__ArrowRightIcon") &&
        !event.target.closest(".sloDashboard__icon") &&
        !event.target.closest(".sloDashboard__tableRow")
      ) {
        setTobeViewedSloId(null);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearch = (event) => {
    if (event.target.value.trim().length == 0) {
      setSearchFailed(false);
      setTableData(() => filesData.slice(0, limit));
      setSearchItem(event.target.value.trim());
      clearTimeout(timeoutId);
      setFileName("");
      setTobeViewedSloId(null);
      return;
    }
    const query = event.target.value;

    setSearchItem(query.trim());

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(() => {
        const results = filesData.filter((object) => {
          const regex = new RegExp(query, "gi");
          return object.name.match(regex);
        });
        if (results.length == 0) {
          setSearchFailed(true);

          return;
        }
        setSearchFailed(false);
        setFileName("");
        setTobeViewedSloId(null);
        setTableData(results);
      }, 1000) // 500ms delay
    );
  };

  const handlePrevClick = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    setTobeViewedSloId(null);
  };

  const handleNextClick = () => {
    setCurrentPage((currPage) => currPage + 1);
    setTobeViewedSloId(null);
  };

  const fetchFiles = async () => {
    console.log("inside fetch files");
    if (token.length === 0) return;
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("authToken", `Bearer ${token}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      mode: "cors",
    };

    try {
      setLoading(true);
      console.log("Calling");
      const response = await fetch(
        `${process.env.REACT_APP_SLOTH_API}` + "/getAllFiles",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        let readableData = await response.json();

        console.log(readableData);
        if (readableData.length % limit === 0) {
          let pages = Math.trunc(readableData.length / limit);
          setTotalPages(pages);
          // if (pages === currentPage) {
          //   setEndReached(true);
          // }
        } else {
          let pages = Math.trunc(readableData.length / limit);
          setTotalPages(pages + 1);
          // if (pages === currentPage) {
          //   setEndReached(true);
          // }
        }

        // let remainingPages = readableData.length % limit

        setFilesData(readableData);
        setTableData(() => readableData.slice(startIndex, endIndex));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  const fetchSlos = async () => {
    if (token.length === 0) return;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("filename", `${fileName}`);
    urlencoded.append("authToken", `Bearer ${token}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      mode: "cors",
    };

    try {
      setLoadingSloInfos(true);
      setLoadingSloInfosError(false);
      console.log("fileName ", fileName);
      const response = await fetch(
        `${process.env.REACT_APP_SLOTH_API}` + "/getfile",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        let readableData = await response.json();

        console.log(readableData);
        let fileContent = convertYamlToJsonObj(atob(readableData.content));
        setLoadedSlos(() => [...fileContent.slos]);
        console.log("fileContent ", fileContent);
        setLoadingSloInfos(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingSloInfosError(true);
      setLoadingSloInfos(false);
    }
  };
  function handleDownload(event, fUrl, fName) {
    console.log("Event ", event);
    event.stopPropagation();
    const fileUrl = `${fUrl}`;
    const fileName = `${fName}`;
    saveAs(fileUrl, fileName);
  }
  function getTime(timeString) {
    // create a new Date object from the UTC timestamp string
    const utcDate = new Date(timeString);

    // get the UTC timestamp in milliseconds
    const utcTimestamp = utcDate.getTime();

    // calculate the offset for IST (UTC+5:30)
    const istOffset = 5.5 * 60 * 60 * 1000;

    // create a new Date object for IST by adding the offset to the UTC timestamp
    const istDate = new Date(utcTimestamp + istOffset);

    // format the IST timestamp as a string (e.g. "2023-03-29T09:34:39.000Z")
    const istDateString = istDate.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const istDateTimeString = istDateString;
    return istDateTimeString;
  }
  const updateThisSlo = (fileName) => {
    navigate("/slo-service", { state: { fileName } });
  };

  const handleShowSlo = (event, indx, fName) => {
    console.log("index ", indx);
    console.log("tobeViedSloId ", tobeViewedSloId);
    event.preventDefault();
    event.stopPropagation();
    if (indx === tobeViewedSloId) {
      setTobeViewedSloId(null);

      return;
    }

    setFileName(fName);
    setTobeViewedSloId(indx);
  };
  console.log("reached here", tobeViewedSloId);
  const convertYamlToJsonObj = (yamlString) => {
    const object = yaml.load(yamlString);
    const jsonString = JSON.stringify(object);

    return JSON.parse(jsonString);
  };

  return (
    <>
      {/* <NavBar /> */}
      <div
        style={{ marginLeft: "15%", marginTop: "7rem", width: "84%" }}
        className="px-5"
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <h1 style={{ fontWeight: "200", marginBottom: "5px" }}>
              Service Level Objectives
            </h1>
            <h6 style={{ fontSize: "18px", fontWeight: "100" }}>
              {" "}
              Use Skydeck's SLO-as-a-Service page to create new SLOs or to view
              and manage all your existing SLOs
            </h6>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <input
            onChange={(e) => handleSearch(e)}
            value={searchItem}
            className="sloDashboard__searchBar"
          ></input>
          <Button
            style={{
              padding: "0",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="sloDashBoard__buttons"
          >
            <Link className="link" to="/slo-service">
              {" "}
              + Onboard New Service
            </Link>
          </Button>
        </div>

        <div
          style={{
            width: "100%",

            borderRadius: "5px",
            border: "1px solid #DCDCDC",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            marginTop: "10px",
          }}
          className="table-wrapper"
        >
          <Table
            className="sloDashboard__slosTable"
            style={{ height: "100%", overflowY: "scroll" }}
            hover
            size="sm"
          >
            <thead className="table-header">
              <th className="table-head">Service Name</th>
              <th className="table-head">Created By</th>
              <th className="table-head">Last Updated On</th>
              <th className="table-head">View | Download | Git</th>
            </thead>
            {searchFailed ? (
              <div
                style={{
                  width: "400%",
                  height: "400px",
                  textAlign: "center",
                  backgroundColor: "#EDEDED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#878787",
                }}
              >
                <h3>Sorry, No record found</h3>
              </div>
            ) : (
              <tbody style={{ border: "none", fontSize: "14px" }}>
                {loading ? (
                  <div style={{ width: "370%" }}>
                    <Loading />
                  </div>
                ) : error ? (
                  <div
                    style={{
                      width: "400%",
                      height: "400px",
                      textAlign: "center",
                      backgroundColor: "#EDEDED",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#878787",
                    }}
                  >
                    <h3>Error in fetching SLOs, please contact the SRE</h3>
                  </div>
                ) : (
                  tableData &&
                  tableData.map((file, indx) => {
                    return (
                      <>
                        <tr
                          onClick={(event) =>
                            handleShowSlo(
                              event,
                              indx,
                              file.name.replace(".yml", "")
                            )
                          }
                          className="sloDashboard__tableRow"
                        >
                          <td
                            className="sloDashboard__tableData"
                            style={{ width: "27%" }}
                          >
                            <button
                              className="sloDashboard__iconButton"
                              onClick={(event) =>
                                handleShowSlo(
                                  event,
                                  indx,
                                  file.name.replace(".yml", "")
                                )
                              }
                            >
                              <ArrowRightIcon
                                className={`sloDashboard__ArrowRightIcon ${
                                  indx === tobeViewedSloId ? "open" : "close"
                                }`}
                              />
                              {file.name.replace(".yml", "")}
                            </button>
                          </td>
                          <td
                            className="sloDashboard__tableData"
                            style={{ width: "27%" }}
                          >
                            {file.creator}
                          </td>
                          <td
                            className="sloDashboard__tableData"
                            style={{ width: "27%" }}
                          >
                            {getTime(file.lastUpdater)}
                          </td>
                          <td
                            className="sloDashboard__tableData"
                            style={{ width: "25%" }}
                          >
                            {/* <Tooltip title="Add SLO" placement="top">
                              <AddBoxIcon
                                className="sloDashboard__icon"
                                style={{ margin: "13px" }}
                                onClick={() =>
                                  updateThisSlo(file.name.replace(".yml", ""))
                                }
                              />
                            </Tooltip> */}
                            <button
                              className="sloDashboard__iconButton"
                              onClick={(event) =>
                                handleShowSlo(
                                  event,
                                  indx,
                                  file.name.replace(".yml", "")
                                )
                              }
                            >
                              <Tooltip
                                title="view slos"
                                placement="top"
                                style={{ fontSize: "20px" }}
                              >
                                {/* <IconButton> */}
                                <VisibilityIcon
                                  className="sloDashboard__icon"
                                  style={{ margin: "13px" }}
                                />
                              </Tooltip>
                            </button>
                            <button
                              className="sloDashboard__iconButton"
                              onClick={(event) =>
                                handleDownload(
                                  event,
                                  file.download_url,
                                  file.name
                                )
                              }
                            >
                              <Tooltip title="Download" placement="top">
                                <DownloadIcon
                                  className="sloDashboard__icon"
                                  style={{ margin: "13px" }}
                                />
                              </Tooltip>
                            </button>
                            <button
                              className="sloDashboard__iconButton"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <a
                                target="__blank"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                href={
                                  process.env.REACT_APP_SLO_SERVICE_REPO +
                                  `/${file.name}`
                                }
                              >
                                <Tooltip title="GitHub" placement="top">
                                  <GitHubIcon
                                    className="sloDashboard__icon"
                                    style={{ margin: "13px" }}
                                  />
                                </Tooltip>
                              </a>
                            </button>
                          </td>
                        </tr>
                        {tobeViewedSloId == indx && (
                          <tr>
                            <td colSpan={4}>
                              {loadingSloInfos && indx === tobeViewedSloId ? (
                                <Loading />
                              ) : loadingSloInfosError ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "max-content",
                                    textAlign: "center",
                                    backgroundColor: "#EDEDED",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#878787",
                                  }}
                                >
                                  <p>
                                    ohho! there was an error while loading your
                                    slos, please try again or contact the SRE
                                  </p>
                                </div>
                              ) : loadedSlos.length > 0 && !loadingSloInfos ? (
                                <div
                                  className={`sloDashBoard__showSloInfoWithTransition sloDashboard__loadedSlos ${
                                    tobeViewedSloId === indx
                                      ? "sloDashBoard__showSloInfo"
                                      : ""
                                  }`}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#003d6d",
                                      width: "98%",
                                      display: "flex",
                                      padding: "10px",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "20%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <p style={{ color: "white" }}>
                                        <strong>Name</strong>
                                      </p>
                                    </div>
                                    <div style={{ width: "40%" }}>
                                      <p
                                        style={{
                                          color: "white",
                                          textAlign: "left",
                                        }}
                                      >
                                        <strong>Description</strong>
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "20%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <p style={{ color: "white" }}>
                                        <strong>Type</strong>
                                      </p>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          color: "white",
                                          textAlign: "left",
                                        }}
                                      >
                                        <strong>Objective</strong>
                                      </p>
                                    </div>
                                    <div style={{ marginLeft: "5%" }}>
                                      <p
                                        style={{
                                          color: "white",
                                          textAlign: "right",
                                        }}
                                      >
                                        <strong>Grafana Dashboard</strong>
                                      </p>
                                    </div>
                                  </div>
                                  {loadedSlos.map((slo, id) => {
                                    console.log("SLO AS A SERVICE", slo);
                                    {
                                      /* setTimeout((slo,) => {

                                  }) */
                                    }
                                    return (
                                      <div
                                        style={{
                                          animationDelay: `${id * 0.01}s`,
                                        }}
                                        className="sloDashboard__slos"
                                      >
                                        <div
                                          style={{
                                            width: "50%",
                                            // display: "flex",
                                            // alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              margin: 0,
                                              display: "flex",
                                            }}
                                          >
                                            <BadgeIcon
                                              style={{ marginRight: "5px" }}
                                              className="sloDashboard__icon "
                                            />
                                            {slo.name}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "90%",
                                            textAlign: "left",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              margin: 0,
                                              display: "flex",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "100%",
                                            }}
                                          >
                                            <Description
                                              style={{ marginRight: "5px" }}
                                              className="sloDashboard__icon"
                                            />
                                            <TruncatedParagraph
                                              text={slo.description}
                                              limit={100}
                                            />
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "52%",
                                            display: "flex",
                                          }}
                                        >
                                          <p
                                            style={{
                                              margin: 0,
                                              display: "flex",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            <CategoryIcon
                                              style={{ marginRight: "5px" }}
                                              className="sloDashboard__icon"
                                            />
                                            {slo.alerting.labels.category}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "26%",
                                            // display: "flex",
                                            // alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              margin: 0,
                                              display: "flex",
                                            }}
                                          >
                                            <TrackChangesIcon
                                              style={{ marginRight: "5px" }}
                                              className="sloDashboard__icon"
                                            />
                                            {slo.objective}%
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "21%",
                                            // display: "flex",
                                            // alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              margin: 0,
                                              display: "flex",
                                            }}
                                          >
                                          
                                            <a
                                              style={{
                                                textDecoration: "none",
                                                color: "black",
                                                fontSize: "15px",
                                                display: "block",
                                               
                                              }}
                                              href={`https://grafana.maersk.io/d/da451a91-b97a-4a0e-946e-b53c6ad76156/slo-detail-prod?orgId=1&refresh=5m&var-Datasource=Prometheus&var-Env=prod&var-service=${fileName}&var-slo=${slo.name}&var-sli_window=5m&var-min_burning_rate=1&from=now-5m&to=now`}
                                              target="__blank"
                                            >
                                             
                                              view dashboard
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "98%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      onClick={() =>
                                        updateThisSlo(
                                          file.name.replace(".yml", "")
                                        )
                                      }
                                      style={{
                                        padding: "0",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "90px",
                                        marginBottom: "10px",
                                      }}
                                      className="sloDashBoard__buttons sloDashboard__AddButton"
                                    >
                                      {" "}
                                      + Add SLO
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {filesData && (
            <p style={{ marginLeft: "50%" }}>
              {currentPage} of {totalPages}
            </p>
          )}

          <div style={{ marginLeft: "auto", marginBottom: "20px" }}>
            {currentPage !== 1 && (
              <button
                onClick={handlePrevClick}
                // disabled={currentPage === 1}
                style={{
                  border: "none",
                  backgroundColor: "#003D6D",
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: "60px",
                }}
              >
                prev
              </button>
            )}

            {!(currentPage === totalPages && !loading) && (
              <button
                onClick={handleNextClick}
                style={{
                  border: "none",
                  backgroundColor: "#003D6D",
                  color: "white",
                  borderRadius: "5px",
                  marginLeft: "10px",
                  cursor: "pointer",
                  width: "60px",
                }}
              >
                next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SloDashboards;
