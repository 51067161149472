import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../../Components/Loading";
import { Row, Col } from "react-bootstrap";
import "../../../../StyleSheets/PostgressReport.css";
import ReactToPrint from "react-to-print";
import Error from "../../../../Components/Error";
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';

export const Neo4jReport = React.forwardRef((props, ref) => {
  var url;
  var componentRef = useRef();
  const [error, setError] = useState({ Error: "" });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { state } = useLocation();
  const { bolt, Uri, port, userName, password, setIsReportLoaded } = props;
  console.log(bolt, Uri, port, userName, password)

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  const [isAtTop, setIsAtTop] = useState(true);
  useEffect(() => {
    // Add an event listener to track scrolling
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsAtTop(true);
      } else {
        setIsAtTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchData();
    return () => {
      setLoading(true);
    };
  }, []);
  const fetchData = async () => {
    try {
      let config = {
        headers: {
          "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
        },
      };
      const wrapperApi = process.env.REACT_APP_APIGEE_WRAPPER_API;

      url =
        wrapperApi +
        `/neo4j/forensics?uri=${Uri.trim()}&port=${port}`;
      //&user=${userName.trim()}&password=${password.trim()}
      if (userName !== "") {
        url = url + `&user=${userName.trim()}`
      }
      if (password !== "") {
        url = url + `&password=${password.trim()}`;
      }

      console.log(url)

      //const res = await axios.get(url, config)
      const res = await axios.get(url, config)
      // .then(function (response) {
      //   console.log(response);

      //   return response.json();
      // })
      // .then(function (data) {
      //   console.log(data);
      //   setData(data);
      //   setLoading(false); // { "userId": 1, "id": 1, "title": "...", "body": "..." }
      // });

      if (res.status === 422) {

        // if (Object.keys(res.data).length === 0) {
        //   setError({
        //     ...error,
        //     Error: "No data available",
        //     comingFrom: "neo4jForensics",
        //   });
        //   return;
        // }
        setError({
          ...error,
          Error: res.data.message,
          comingFrom: "neo4jForensics",
        });
        return;
      }
      setData(res.data);
      setLoading(false);
      setIsReportLoaded(true);
    } catch (err) {
      //console.log(err);

      if (err.response.status === 504) {
        setError({
          ...error,
          Error: "Request Timed Out, Please try again",
          comingFrom: "neo4jForensics",
        });
      } else {
        setError({
          ...error,
          Error: err.response.data.message,
          comingFrom: "neo4jForensics",
        });
      }
      setLoading(false);
    }
  };
  console.log(typeof data)
  console.log(data)


  return (
    <>
      {loading ? (
        <div style={{ marginTop: "-20rem" }}><Loading /></div>
      ) : error.Error !== '' ? (
        <Error element={error} />
      ) : (
        <>
          <div className="fixedSection">
            {isAtTop ?
              <Button variant="outlined" style={{ marginRight: "12px" }} onClick={() => goToTop()} disabled>Go To Top</Button>
              :
              <Button variant="outlined" style={{ marginRight: "12px" }} onClick={() => goToTop()}>Go To Top</Button>
            }
            <ReactToPrint
              trigger={() => {
                return (
                  <Button variant="contained">Download PDF</Button>
                );
              }}
              content={() => componentRef}
            />
          </div>
          <div className="reportSection">
            <div ref={(el) => (componentRef = el)}>
              <br />
              <br />
              <div className="display-flex">
                <Row>
                  <Col className="sm-9">
                    <h2 className="text-center">Neo4j Forensics for</h2>
                    {/* <h2 className="text-center">{`bolt: ${bolt} `}</h2> */}
                    <h2 className="text-center">{`URI: ${Uri} `}</h2>
                  </Col>
                </Row>
              </div>
              <br />
              <br />
              {Array.isArray(data) &&
                data.map((d) => (
                  <div className="px-5 m-2" style={{ width: '100%' }} key={d.Title}>
                    <h3>{d.Title}</h3>
                    <p className="py-2">{d.Explanation}</p>
                    {d.Recommendation !== '' && (
                      <p className="py-2">
                        <b>Suggestion - </b>
                        {d.Recommendation}
                      </p>
                    )}
                    {d.error === '' ? (
                      <div>
                        {/* <h2>{d.Title}</h2>
                        <p>{d.Explanation}</p> */}
                        {Array.isArray(d.TableRecords) && d.TableRecords.length > 0 ? (
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                {Object.keys(d.TableRecords[0] || {}).map((currHeading) => (
                                  <th key={currHeading}>{currHeading}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {d.TableRecords.map((record, i) => (
                                <tr key={i}>
                                  {Object.values(record || {}).map((rec, j) =>
                                    typeof rec === 'object' && rec !== null ? (
                                      Object.entries(rec).map(([key, value]) => {
                                        if (key === 'description') return null; // Exclude the "description" field
                                        return (
                                          <td key={`${i}-${j}-${key}`}>
                                            {key}: {typeof value === 'object' ? value.value : value}
                                          </td>
                                        );
                                      })
                                    ) : (
                                      <td key={`${i}-${j}`}>{rec}</td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No records found.</p>
                        )}
                      </div>
                    ) : (
                      <h4>{d.error}</h4>
                    )}
                  </div>
                ))}
            </div>

            {/* <ReactToPrint
              trigger={() => {
                return (
                  <button className="downloadBtn" href="#">
                    Download PDF
                  </button>
                );
              }}
              content={() => componentRef}
            /> */}
          </div>
        </>
      )}
    </>
  );
});
