import React from "react";
function LoadingReport() {
  return (
    <>
      <h1  className="p-5" style={{textAlign: 'center'}}>Loading Report , please wait, this may take few minutes...</h1>
    </>
  );
}

export default LoadingReport;
