import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../../../StyleSheets/Sloth.css";
import ProductForm from "./SlothTabs/ProductFormTab/ProductForm";
import SloInfo from "./SlothTabs/SloInfoFormTab/SloInfo";
import NavBar from "../../../../Components/NavBar";
import UseSloInfo from "./SlothContext";
import { Alert } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { useSloInfoForm } from "./SlothTabs/SloInfoFormTab/Logic";
import { RingLoader } from "react-spinners";
import UserInfo from "../../../../hooks/PageLayout";
import yaml from "js-yaml";
import { usePopulateFields } from "./SlothTabs/ProductFormTab/Logic";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Sloth() {
  const [value, setValue] = React.useState(0);
  const [fName, setFileName] = React.useState("");
  const { token } = UserInfo();
  const { setSha, setUpdating,   currentTabIndex, setCurrentTabIndex } = { ...UseSloInfo() };
  const { state } = useLocation();

  if (fName.length == 0 && state) {
    setFileName(state.fileName);
  }
  const {
    alertPopUp,
    alertContent,
    alertSeverity,
    show,
    loading,
    setLoading,
    setShow,
    setAlertSeverity,
    setAlertContent,
    setAlertPopUp,
    populateSloInfoFormValues,
  } = { ...useSloInfoForm() };

  const { populateProductInfoFormValues } = { ...usePopulateFields() };
  const resetAlert = () => {
    setAlertPopUp(false);
    setAlertContent("");
    setAlertSeverity("");
    setShow(false);
  };

  const convertYamlToJsonObj = (yamlString) => {
    const object = yaml.load(yamlString);
    const jsonString = JSON.stringify(object);

    return JSON.parse(jsonString);
  };
  const handleChange = (event, newValue, calledByTab) => {
    console.log("CHANGING VALUE ", newValue)
    if(calledByTab && newValue === 1){
      return 
    }
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {
    if(state){
    fetchYamlFile();
    }
  }, []);

  const fetchYamlFile = async () => {
    let readableData = "";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("filename", `${fName}`);
    urlencoded.append("authToken", `Bearer ${token}`);
    // url
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      mode: "cors",
    };

    try {
      setLoading(true);
      setShow(true);
      const response = await fetch(
        `${process.env.REACT_APP_SLOTH_API}` + "/getfile",
        requestOptions
      );

      if (!response.ok) {
        readableData = await response.text();
        throw new Error(readableData);
      } else {
        readableData = await response.json();
        let jsonObject = convertYamlToJsonObj(atob(readableData.content));
        console.log("Response from API ", readableData);
        populateProductInfoFormValues(jsonObject);
        populateSloInfoFormValues(jsonObject.slos);
        setSha(readableData.sha);
        setUpdating(true);
        setLoading(false);
        handleChange("", 1, false)
        // setValue(1);
        resetAlert();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlertPopUp(true);
      setAlertSeverity("error");
      setAlertContent(error.message);
    }
  };

  return (
    <>
      {/* <NavBar /> */}

      <div
        style={{
          width: "84%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "70px",
          marginLeft:"15%"
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "40px",
          }}
        >
          <Modal show={show} onHide={""} backdrop="static">
            <Modal.Body
              style={{
                overflowY: "scroll",
                height: "10rem",
                padding: "20px",
                fontSize: "18px",
              }}
            >
              {/* {alertPopUp && */}
              {!loading ? (
                <>
                  {alertPopUp ? (
                    <Alert
                      style={{}}
                      severity={alertSeverity}
                      onClose={() => {
                        resetAlert();
                      }}
                    >
                      {alertContent}
                    </Alert>
                  ) : (
                    <div className="sloInfo__spinner"></div>
                  )}
                </>
              ) : (
                <div className="sloInfo__spinner">
                  <RingLoader size={20} color="#003D6D" />
                  <div></div>
                  <p>Fetching your product details</p>
                </div>
              )}

              {alertSeverity === "success" ? <p></p> : ""}
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "space-around" }}>
              <button
                style={{ width: "30%" }}
                className="sloinfoform__submitButton"
                onClick={resetAlert}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          <Box
            sx={{
              borderBottom: 1,
              width:"100%"
            }}
          >
            <Tabs
              value={currentTabIndex}
              onChange={(event, value) => handleChange(event, value, true)}
              // TabIndicatorProps={{
              //   style: { display: "none" },
              // }}
              // // indicatorColor="primary"
              // textColor="inherit"
              // variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                // className={
                //   value == 0
                //     ? "Tabs__tabBlueBackGround Tabs__tab"
                //     : "Tabs__tabWhitebackGround Tabs__tab"
                // }
                label="Product Info"
                {...a11yProps(0)}
              />
              <Tab
                // className={
                //   value == 1
                //     ? "Tabs__tabBlueBackGround Tabs__tab"
                //     : "Tabs__tabWhitebackGround Tabs__tab"
                // }
                label="SLO info"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={currentTabIndex} index={0}>
            <ProductForm navigate={handleChange} />
          </TabPanel>
          <TabPanel value={currentTabIndex} index={1}>
            <SloInfo navigate={handleChange} />
          </TabPanel>
        </div>
      </div>
    </>
  );
}

export default Sloth;
