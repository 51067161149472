import { useEffect, useRef, useState } from "react";
import UseSloInfo from "../../SlothContext";
import UserInfo from "../../../../../../hooks/PageLayout";
import { useNavigate } from "react-router-dom";
import { remove } from "./functions/remove";
import { edit } from "./functions/edit";
import { reset } from "./functions/reset";
import { submit } from "./functions/submit";
import { setContext } from "./functions/setContext";
import { calculate } from "./functions/calculateDownTime";
import { send } from "./functions/sendYamlWithApi";
import { populate } from "./functions/populateSloInfoFormValues";
import { view } from "./functions/view";
import { clear } from "./functions/clear";
import { Equalizer } from "@mui/icons-material";

export function useSloInfoForm() {
  //Defining References for each input fields
  const { token } = UserInfo();
  const [sloName, setSloName] = useState("");
  const [sloDescription, setSloDescription] = useState(
    ""
  );
  const [category, setCategory] = useState("");
  const [objective, setObjective] = useState("");
  const [errorQueryValidation, setErrorQueryValidation] = useState(false);
  const [totalQueryValidation, setTotalQueryValidation] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState("");
  // const [noOfOldSlos, setNoOfOldSlos] = useState(0);
  const errorQuery = useRef();
  const totalQuery = useRef();
  const alertName = useRef();
  const alertAnnotations = useRef();
  const [defaultSlo, setDefaultSlo] = useState(false);

  //State variables used for handling UI behaviour
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [show, setShow] = useState(false);
  const [yamlIsCorrect, setYamlIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlyViewSloInfo, setOnlyViewSloInfo] = useState(false);
  const [queryEnvironment, setQueryEnvironment] = useState("");
  const [queryDuration, setQueryDuration] = useState("");
  const [querySliMetric, setQuerySliMetric] = useState("");
  const [queryApplication, setQueryApplication] = useState("");
  const [queryMopTestSuiteId, setQueryMopTestSuiteId]= useState("");
  const [queryAddOnFields, setqueryAddOnFields] = useState([]);
  const navigate = useNavigate();
  
  const generateQueries = (eq,tq) => {
    errorQuery.current.value = eq
    totalQuery.current.value = tq
  }

  const [validationSuccess, setValidationSuccess] = useState(false);
  let yamlDoc = {};

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { graphData } = UserInfo();

  const {
    sha,
    sloData,
    setSloData,
    setUpdating,
    updating,
    slosContextArray,
    setSlosContextArray,
    setYamlString,
    yamlString,
    noOfOldSlos,
    setNoOfOldSlos,
  } = { ...UseSloInfo() };

  // useEffect(() => {

  //   sloName?.current?.value =
  //   sloDescription?.current?.value = "This is the description of the SLO";

  // },[])

  //Array That will contain the slo objects
  const [slos, setSlos] = useState([]);

  //state variable to determine if the current slo is been saved or not after editing
  const [editing, setEditing] = useState(false);

  //function that will set the slo category state

useEffect(() => {

  if(Array.isArray(slos) && !slosContextArray.find(slo => slo.sloName.includes("Operations_Excellence_Availability"))){
    setDefaultSlo(true);
    setSloName("Operations_Excellence_Availability");
    setSloDescription("Operations Excellence Availability");
    setCategory("Availability");
    setObjective("97.10");
  }
},[slosContextArray])


  
  const selectThisCategory = (category) => {
    setCategory(category);
  };

  //function that will remove the slo from the current slo state array, called when the delete or editing operation is invoked
  // remove(sloIndx, sloName, setSlos, setSlosContextArray, slos, slosContextArray)
  const removeThisSlo = (sloIndx, sloName) => {
    remove(
      sloIndx,
      sloName,
      setSlos,
      setSlosContextArray,
      slos,
      slosContextArray,
    
    );
  };

  //function that will handle the edit SLO condition
  const editThisSlo = (toBeEditedSloName) => {
    // if(!isNewSlo){
    //   setOnlyViewSloInfo(true)
    // }
    edit(
      editing,
      slos,
      toBeEditedSloName,
      setSloName,
      setSloDescription,
      errorQuery,
      totalQuery,
      alertName,
      alertAnnotations,
      setObjective,
      setCategory,
      removeThisSlo,
      setEditing,
      setDefaultSlo
    );
  };

  //This function is used to reinitialize the form with the initial values
  const resetForm = () => {
    reset(
      setSloName,
      setSloDescription,
      errorQuery,
      totalQuery,
      alertName,
      alertAnnotations,
      setObjective
    );
  };

  //function that will handle the submit form event, and it's called when you click on Add Slo button.
  const submitForm = (event) => {
    event.preventDefault();
    submit(
      sloName,
      sloDescription,
      objective,
      totalQuery,
      alertName,
      alertAnnotations,
      category,
      errorQuery,
      yamlDoc,
      sloData,
      setSlos,
      setSlosContextArray,
      setCategory,
      resetForm,
      setEditing,
      token,
      setLoading,
      setErrorQueryValidation,
      setTotalQueryValidation,
      setDisplayError,
      setDisplayErrorMessage,
      setDefaultSlo
    );
  };

  const slothContextData = () => {
    setContext(slosContextArray, setSlos);
  };

  const CalculateAllowedDowntime = (sloTarget, window) => {
    return calculate(sloTarget, window);
  };

  const sendYamlWithAPI = (event) => {
    console.log("SlosContextArray ", slosContextArray);
    send(
      graphData,
      sloData,
      updating,
      token,
      sha,
      setLoading,
      setAlertPopUp,
      setAlertContent,
      setAlertSeverity,
      yamlDoc,
      slosContextArray,
      setShow
    );
  };

  const populateSloInfoFormValues = (slos) => {


    populate(
      slos,
      setSlosContextArray,
      setSlos,
      setNoOfOldSlos,
    
    );
  };

  const viewThisSlo = (toBeEditedSloName, isNewSlo) => {
    console.log("new slo", isNewSlo);
    if (!isNewSlo) {
      setOnlyViewSloInfo(true);
    }
    view(
      slos,
      toBeEditedSloName,
      setSloName,
      setSloDescription,
      errorQuery,
      totalQuery,
      alertName,
      alertAnnotations,
      setObjective,
      setCategory
    );
  };

  const clearForm = () => {
    console.log("in clear form");
    clear(
      setSloName,
      setSloDescription,
      alertName,
      errorQuery,
      totalQuery,
      alertAnnotations,
      setCategory,
      setObjective,
      setOnlyViewSloInfo
    );
  };


  const reinitialised = () => {
    navigate("/slodashboarding");
    window.location.reload();
  };

  return {
    sloName,
    sloDescription,
    category,
    objective,
    errorQuery,
    totalQuery,
    alertName,
    alertAnnotations,
    editing,
    slos,
    alertPopUp,
    alertContent,
    alertSeverity,
    show,
    yamlString,
    yamlIsCorrect,
    sloData,
    loading,
    noOfOldSlos,
    onlyViewSloInfo,
    setLoading,
    setYamlIsCorrect,
    handleClose,
    setAlertSeverity,
    setAlertContent,
    setAlertPopUp,
    setObjective,
    selectThisCategory,
    removeThisSlo,
    editThisSlo,
    submitForm,
    slothContextData,
    CalculateAllowedDowntime,
    sendYamlWithAPI,
    reinitialised,
    setShow,
    populateSloInfoFormValues,
    viewThisSlo,
    clearForm,
    errorQueryValidation,
    totalQueryValidation,
    displayError,
    displayErrorMessage,
    setSloName,
    setSloDescription,
    defaultSlo,
    querySliMetric,
    queryEnvironment,
    queryDuration,
    queryAddOnFields,
    setQuerySliMetric,
    setQueryEnvironment,
    setQueryDuration,
    setqueryAddOnFields,
    queryApplication,
    setQueryApplication,
    queryMopTestSuiteId, 
    setQueryMopTestSuiteId,
    generateQueries
  };
}
