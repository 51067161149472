export const view = (
    slos,
    toBeEditedSloName,
    setSloName,
    setSloDescription,
    errorQuery,
    totalQuery,
    alertName,
    alertAnnotations,
    setObjective,
    setCategory,
) => {

    let toBeViewdSloIndex = slos.findIndex(
        (slo) => slo.sloName === toBeEditedSloName
      );
    
      //setting the current input box values with the slo values selected by the user for editing
      setSloName(() =>slos[toBeViewdSloIndex].sloName ) 
      setSloDescription(() => slos[toBeViewdSloIndex].sloDescription)
      errorQuery.current.value = slos[toBeViewdSloIndex].errorQuery;
      totalQuery.current.value = slos[toBeViewdSloIndex].totalQuery;
      alertName.current.value = slos[toBeViewdSloIndex].alertName;
      alertAnnotations.current.value = slos[toBeViewdSloIndex].alertAnnotations;
      setObjective(() => slos[toBeViewdSloIndex].objective);
      setCategory(() => slos[toBeViewdSloIndex].category);
}