import React from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import "../StyleSheets/navbar.css";
import brand from "../Images/maersk.svg";
import UserInfo from "../hooks/PageLayout"
import { Link } from "react-router-dom";
const NavBar = () => {
  const {
    graphData
  }=UserInfo();
 
  return (
    <>
      <Navbar
        className="navbarStyle fixed-top navbar-expand-lg navbar-light"
        expand="lg"
      >
        <Container className="container-fluid px-2 mx-2">
          <Navbar.Brand>
          <Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)"}} to="/"> <img src={brand} /></Link>
           
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse  id="basic-navbar-nav">
            <Nav  style={{fontSize:"18px"}} className="me-auto container-fluid">
              <Nav.Link >
              <Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)" }} to="/manifesto">Manifesto</Link>
              </Nav.Link>
              {/* <Nav.Link><Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)"}} to="/capability">Capabilities</Link></Nav.Link> */}
              <Nav.Link><Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)"}} to="/capability">Capabilities</Link></Nav.Link>
              <Nav.Link onClick={()=> window.open(`${process.env.REACT_APP_SRE_SKYDECK_LIBRARY_SITE}`, "_blank")} ><Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)"}}>Library</Link></Nav.Link>
{/* 
              <Nav.Link ><Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)"}} to="/contribute">UpComing</Link></Nav.Link>  */}
              <Nav.Link><Link style={{textDecoration:"none", color:"rgba(0,0,0,.55)"}} to="/about">Meet The Team</Link></Nav.Link>
             
              <NavDropdown style={{fontSize:"18px"}} title="Quick Links" id="basic-nav-dropdown">
                <NavDropdown.Item
                  className=""
                  href="https://maersk-bridge.datadoghq.eu/dashboard/7y4-g83-w7r/cloud-engineering-products-slo?from_ts=1644980513499&to_ts=1645066913499&live=true"
                  target="_blank"
                >
                  SLO DashBoard in DataDog
                </NavDropdown.Item>
                <NavDropdown.Item
                  className=""
                  href="https://grafana.maersk.io/d/slo-detail/slo-detail?orgId=1"
                  target="_blank"
                >
                  SLO DashBoard in Pensieve
                </NavDropdown.Item>
                <NavDropdown.Item
                  className=""
                  href={process.env.REACT_APP_STATUS_PAGE}
                  target="_blank"
                >
                  Status Page
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  className=""
                  href="/contribute"
                >
                  Contribute
                </NavDropdown.Item> */}
              </NavDropdown>
              
            </Nav>
            
          </Navbar.Collapse>
          
          
        </Container>
        {graphData && <Nav.Item className="ms-auto px-4 fnt-md-screen">{graphData.displayName}</Nav.Item>}
      </Navbar>
    </>
  );
};

export default NavBar;