import React from "react";
import "../StyleSheets/Loadingscreen.css";
import ReactLoading from "react-loading";
function Loading() {
  return (
    <>
      {" "}
      <div className="loading">
        {" "}
        <ReactLoading
          type="spinningBubbles"
          color="#00243d"
          height={50}
          width={50}
        />{" "}
         {/* <p style={{ textAlign: "center" }}>Loading</p>{" "} */}
      </div>{" "}
     
    </>
  );
}
export default Loading;
