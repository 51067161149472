import axios from "axios";

export const SloProductFormService = {
  getPortfolioData(instance, loginRequest, accounts, apiEndpoint) {
    return axios
      .get(apiEndpoint + "/api/getplatformportfoliodata")
      .then((response) => {
        return response.data;
      });
  },

  getPlatformData(instance, loginRequest, accounts, apiEndpoint, portfolioID) {
    return axios
      .get(apiEndpoint + "/api/portfolios/" + portfolioID + "/platforms")
      .then((response) => {
        return response.data;
      });
  },

  getProductData(instance, loginRequest, accounts, apiEndpoint, platformID) {
    return axios
      .get(apiEndpoint + "/api/platforms/" + platformID + "/products")
      .then((response) => {
        return response.data;
      });
  },

  getApplicationData(instance, loginRequest, accounts, apiEndpoint, ProductID) {
    return axios
      .get(apiEndpoint + "/api/products/" + ProductID + "/applications")
      .then((response) => {
        return response.data;
      });
  },

  getMACApplicationData(instance, loginRequest, accounts, apiEndpoint, AppID) {
    AppID = AppID.toUpperCase();

    return axios
      .get(apiEndpoint + `/api/macdata?appId=${AppID}`)
      .then((response) => {
        return response.data;
      });
  },

  getMyHedwigScopeData(instance, loginRequest, accounts, apiEndpoint) {
    return axios.get(apiEndpoint + "/me").then((response) => {
      return response.data;
    });
  },

  getHedwigScopeData(instance, loginRequest, accounts, apiEndpoint, query) {
    return axios.get(apiEndpoint + `/name?query=${query}`).then((response) => {
      return response.data;
    });
  },
};
