export const mailContainMaerskDomain = (email, emailDl, teamsHandle) => {
    console.log(email, emailDl, teamsHandle)
    let  emailsArray = email.split(',')
    // console.log(emailsArray)
     for(var i = 0; i<emailsArray.length; i++){
         if(emailsArray[i].indexOf("@maersk.com") === -1 ){
            console.log("returning from 1")
             return false
         }
     }
     let emailDlArray = emailDl.split(',')
     console.log(emailDlArray)
     for(var i=0;i<emailDlArray.length; i++){
         if(emailDlArray[i].indexOf("@maersk.com") === -1){
            console.log("returning from 2")
             return false;
         }
     }
 
     if(teamsHandle){
     if(teamsHandle.indexOf('@maersk.com') === -1){
        
         if(teamsHandle.length === 0){
             return true
         }
         console.log("returning from 3")
         return false
     }
 }
 
     return true;
 }