import React, { useState } from 'react';

const Environment = ({queryEnvironment,setQueryEnvironment}) => {
  const [selectedValue, setSelectedValue] = useState('prod');

  const handleChange = (event) => {
    setQueryEnvironment(event.target.value);
  };
  console.log("env=",queryEnvironment)
  return (
    <div>
      <select id="environment" value={queryEnvironment} onChange={handleChange}>
        <option value="dev">Development</option>
        <option value="prod">Production</option>
        {/* <option value="preprod">Pre-Production</option> */}
        {/* <option value="stage">Stage</option> */}
        {/* <option value="kube_pod_container_status_running">Kubernetes Microservice Availability</option> */}
        {/* <option value="mop_synthetics_test_result"></option> */}
      </select>
      
      {/* <div>
        <span>{queryEnvironment}</span>
      </div> */}
    </div>
  );
};

export default Environment;