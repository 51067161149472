import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

import "../StyleSheets/Form.css";
import NavBar from "../../Components/NavBar";
import { teamNameAlreadyExist } from "../util/checkIfAlreadyExist";
import { mailContainMaerskDomain } from "../util/mailContainMaerskDomain";

function TeamForm() {
  const [name, setName] = useState("");
  const [oldTeamName, setOldTeamName] = useState("");
  const [teamNames, setTeamNames] = useState([]);
  const [teamName, setTeamName] = useState();
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [teamsHandle, setTeamsHandle] = useState("");
  const [emailDl, setEmailDl] = useState("");
  const [teamId, setTeamId] = useState();
  const [loading, setLoading] = useState("");
  const [opp, setOpp] = useState("Insert");
  const [gettingTeamNames, setGettingTeamNames] = useState(false);
  const [gettingTeamData, setGettingTeamData] = useState(false);
  useEffect(() => {
    setTeamName("");
    setTeamsHandle("");
    setEmail("");
    setName("");
    setDescription("");
    setEmailDl("");
    if (opp === "Update" || opp === "Delete") {
      if (opp !== "") {
        fetchTeamNames();
      }
    }
  }, [opp]);

  useEffect(() => {
    if (!teamName) {
      return;
    }
    // setTeamName("");
    setTeamsHandle("");
    setEmail("");
    setName("");
    setDescription("");
    setEmailDl("");
    fetchTeamData();
  }, [teamName]);

  const fetchTeamNames = async () => {
    try {
      setGettingTeamNames(true);
      let tnames = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamNames`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
          },
        }
      );
      tnames = tnames.data.data;
      console.log(tnames);
      if (tnames.error) {
        throw new Error(tnames.message);
      } else {
        setTeamNames(tnames.team_names);
        setGettingTeamNames(false);
      }
    } catch (err) {
      setGettingTeamNames(false);
      alert(err.message);
    }
  };

  const fetchTeamData = async () => {
    let encodeTName = encodeURIComponent(teamName);
    try {
      setGettingTeamData(true);
      let teamData = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamData?name=${encodeTName}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
          },
        }
      );

      teamData = teamData.data.data;
      if (teamData.error) {
        throw new Error(teamData.message);
      } else {
        setTeamsHandle(teamData.data[0].ms_teams_handle);
        setEmailDl(teamData.data[0].email_dl);
        setEmail(teamData.data[0].emails);
        setDescription(teamData.data[0].description);
        setTeamId(teamData.data[0].id);
        setName(teamData.data[0].name);
        setOldTeamName(teamData.data[0].name);
        setGettingTeamData(false);
        //
      }
    } catch (err) {
      setGettingTeamData(false);
      alert(err);
    }
  };
  const formInputFieldData = [
    {
      name: "Name",
      type: "text",
      required: true,
      isTextArea: false,
      onChange: "setName",
      value: name,
    },

    {
      name: "Email DL",
      type: "text",
      required: true,
      isTextArea: false,
      onChange: "setEmailDl",
      value: emailDl,
    },
    {
      name: "Email ID",
      type: "text",
      required: true,
      isTextArea: false,
      onChange: "setEmail",
      value: email,
    },
    {
      name: "MS Teams Handle",
      type: "text",
      required: false,
      isTextArea: false,
      onChange: "setTeamsHandle",
      value: teamsHandle,
    },
  ];

  const changeValue = (value, e) => {
    switch (value) {
      case "setName":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setName(e.target.value);
        break;

      case "setDescription":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setDescription(e.target.value);
        break;

      case "setTeamsHandle":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setTeamsHandle(e.target.value);
        break;
      case "setEmail":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setEmail(e.target.value);
        break;

      case "setEmailDl":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setEmailDl(e.target.value);
      default:
        break;
    }
  };

  const handleOppChange = (e) => {
    setOpp(e.target.value);
  };

  const handleTeamNameUptDel = (e) => {
    setTeamName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    if (opp === "Delete") {
      if (
        !window.confirm(
          "Do you really want to delete your team?\nYour Platform, CIs, URLs all will be deleted"
        )
      ) {
        return;
      }
    }
    if (opp !== "Delete") {
      if (!mailContainMaerskDomain(email, emailDl, teamsHandle)) {
        alert(
          "Please enter only maersk domain email address in Email ID and Email Dl inputs\n Press ok to continue"
        );
        return;
      }
    }
    setLoading(true);

    if (opp !== "Delete" && opp !== "Update") {
      if (await teamNameAlreadyExist(name)) {
        setLoading(false);
        alert("Team Name already exists!\nPress ok to continue");
        setName("");
        return;
      }
    }

    let na = name.trim();
    let desc = description.trim();
    let e_mail = email.replace(/\s/g, "");
    let teams_Handle = teamsHandle && teamsHandle.replace(/\s/g, "");
    let email_Dl = emailDl.replace(/\s/g, "");

    if (opp == "Update") {
      if (oldTeamName !== name) {
        if (await teamNameAlreadyExist(name)) {
          alert("Team Name already exists\nPress ok to continue");
          setLoading(false);
          setName("");
          return;
        }
      }

      try {
        const body = { na, desc, e_mail, teams_Handle, email_Dl, teamId };
        const response = await axios.post(
          `${process.env.REACT_APP_ONBOARDER_API}/updateTeam`,
          {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
            },
            ...body,
          }
        );
        let responseData = response.data.data;
        if (responseData.error) {
          throw new Error(
            "Unable to Update your team\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          setName("");
          setEmail("");
          setEmailDl("");
          setDescription("");
          setOpp("");
          setTeamName("");
          alert(
            "Your team has been successfully Updated\nPress ok to continue"
          );
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    } else if (opp === "Insert") {
      try {
        const body = { na, desc, e_mail, teams_Handle, email_Dl };
        const response = await axios.post(
          `${process.env.REACT_APP_ONBOARDER_API}/addinteams`,
          {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
            },
            ...body,
          }
        );
        // console.log(response.data)
        let responseData = response.data.data;
        console.log(responseData);
        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }

          throw new Error(
            "Unable to onboard your team\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          setName("");
          setEmail("");
          setEmailDl("");
          setDescription("");
          setOpp("");
          alert(
            "Your team has been successfully onboarded\nPress ok to continue"
          );
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    } else {
      try {
        const body = { teamId };
        const response = await axios.delete(
          `${process.env.REACT_APP_ONBOARDER_API}/deleteTeam`,
          {
            mode: "cors",
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
            },
            data: {
              teamId: teamId,
            },
          }
        );
        console.log(body);

        let responseData = response.data.data;

        if (responseData.error) {
          throw new Error(
            "Unable to Delete your team\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          setName("");
          setEmail("");
          setEmailDl("");
          setDescription("");
          setTeamName("");
          setOpp("");
          alert(
            "Your team has been successfully deleted\nPress ok to continue"
          );
          // window.location.reload();
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
  };

  return (
    <>
      <NavBar />
      <div className="topContainer">
        {loading ? (
          <h2 style={{ fontWeight: "100" }}>
            {opp === "Insert"
              ? "Onboarding "
              : opp === "Update"
              ? "Updating "
              : "Deleting "}
            your team, Please wait!!
          </h2>
        ) : (
          <>
            {/* <h1 style={{ fontWeight: "100" }}>Team Info Form</h1> */}
            <div className="formContainer">
              <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Form.Label
                  style={{ marginBottom: "40px" }}
                  className="formLabel"
                >
                  Operation
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Box
                  sx={{
                    minWidth: 120,
                    marginBottom: "20px",
                    marginTop: "20px",
                    border: "none",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Operation
                    </InputLabel>
                    <Select
                      style={{ borderTop: "0px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={opp}
                      label="Select Opeartion"
                      onChange={(e) => handleOppChange(e)}
                    >
                      <MenuItem
                        style={{ fontFamily: "Maersk Text" }}
                        value="Insert"
                      >
                        Onboard Team
                      </MenuItem>
                      <MenuItem
                        style={{ fontFamily: "Maersk Text" }}
                        value="Update"
                      >
                        Update Team
                      </MenuItem>
                      <MenuItem
                        style={{ fontFamily: "Maersk Text" }}
                        value="Delete"
                      >
                        Delete Team
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Form.Group
                  className="mb-3 formGroup"
                  controlId="formBasicEmail"
                >
                  {opp === "Update" || opp === "Delete" ? (
                    <Box
                      sx={{
                        minWidth: 120,
                        marginBottom: "20px",
                        marginTop: "20px",
                        border: "none",
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {gettingTeamNames
                            ? "Loading Teams"
                            : "Select Your Team"}
                        </InputLabel>

                        <Select
                          style={{ borderTop: "0px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={gettingTeamNames}
                          value={teamName}
                          label="Select Opeartion"
                          onChange={(e) => handleTeamNameUptDel(e)}
                        >
                          {teamNames &&
                            teamNames.map((teamName) => {
                              return (
                                <MenuItem
                                  style={{ fontFamily: "Maersk Text" }}
                                  value={teamName.name}
                                >
                                  {teamName.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}

                  {formInputFieldData.map((data) => {
                    return (
                      <div className="formContentDiv">
                        {data.name === "Email DL" && (
                          <p
                            style={{
                              fontSize: "15px",
                              marginBottom: "20px",
                              color: "gray",
                            }}
                          >
                            Enter multiple DLs comma separated
                          </p>
                        )}
                        {data.name === "Email ID" && (
                          <p
                            style={{
                              fontSize: "15px",
                              marginBottom: "20px",
                              color: "gray",
                            }}
                          >
                            Enter multiple Emails comma separated
                          </p>
                        )}
                        <Form.Label className="formLabel">
                          {data.name}{" "}
                          {data.required && (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              *
                            </span>
                          )}
                        </Form.Label>

                        <Form.Control
                          className="inputBox"
                          type={data.type}
                          placeholder={
                            gettingTeamData && "Loading Team Details"
                          }
                          onChange={(e) => changeValue(data.onChange, e)}
                          value={data.value}
                          required={data.required}
                          disabled={opp === "Delete" || gettingTeamData}
                        />
                      </div>
                    );
                  })}
                </Form.Group>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Form.Label className="formLabel">
                    Description
                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    style={{ marginBottom: "40px", fontSize: "16px" }}
                    as="textarea"
                    type="text"
                    rows="5"
                    placeholder={
                      gettingTeamData
                        ? "Loading Team Details"
                        : "Please Describe your Team"
                    }
                    onChange={(e) => changeValue("setDescription", e)}
                    value={description}
                    required={true}
                    disabled={opp === "Delete"}
                  />
                </div>
                <div className="buttonContainer">
                  <Button type="submit" className="submitButton">
                    {opp === "Insert"
                      ? "Submit"
                      : opp === "Update"
                      ? "Update"
                      : "Delete"}
                  </Button>
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TeamForm;
