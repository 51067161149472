export const validateInputs = ( selectedFormDetails) => {


  console.log("selectedFormDetails",selectedFormDetails.hedwigScope)
  console.log("selectedFormDetails",selectedFormDetails.Portfolio)
  console.log("selectedFormDetails",selectedFormDetails.Platform)
  console.log("selectedFormDetails",selectedFormDetails.Product)
  console.log("selectedFormDetails",selectedFormDetails.Application)
  console.log("selectedFormDetails",selectedFormDetails.Environment)

return (
   
  !selectedFormDetails?.hedwigScope || 
  !selectedFormDetails?.Environment || 
  !selectedFormDetails?.Application ||
  !selectedFormDetails?.Product ||
  !selectedFormDetails?.Platform ||
  !selectedFormDetails?.Portfolio ||
  selectedFormDetails?.Portfolio.label?.length === 0 ||
  selectedFormDetails?.Platform.label?.length === 0 ||
  selectedFormDetails?.Product?.label?.length === 0 ||
  selectedFormDetails?.Application.label?.length === 0 ||
  selectedFormDetails?.Environment?.value?.length === 0 ||
  selectedFormDetails?.hedwigScope?.value?.length === 0 

)
}