import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { createUrlArray } from "../util/createUrlArray";
import { getCleanUrls } from "../util/getCleanUrls";
import { urlsArevalid } from "../util/urlsAreValid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function UrlsOnboardForm({
  port,
  teamName,
  ciName,
  flag,
  loading,
  setLoading,
  opp,
  urls,
  setOpp,
  setTeamName,
  setPort,
  setCiName,
  setActiveFlag,
  setUrls,
  gettingUrls
}) {
  const [urlFieldsToUpdate, setUrlFieldstoUpdate] = useState(urls);
  useEffect(() => {
    setUrlFieldstoUpdate(urls);
  }, [urls]);
console.log("opp", opp)
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [url3, setUrl3] = useState("");
  const [url4, setUrl4] = useState("");
  const [url5, setUrl5] = useState("");
  const [url6, setUrl6] = useState("");
  const [url7, setUrl7] = useState("");
  const [url8, setUrl8] = useState("");
  const [url9, setUrl9] = useState("");
  const [url10, setUrl10] = useState("");
  const [urlsToDelete, setUrlsToDelete] = useState([]);
  const [deleteAll, setDeleteAll] = useState(false);


  const formInputFieldData = [
    {
      name: "Url 1",
      type: "text",
      value: url1,
      onChange: "setUrl1",
    },
    {
      name: "Url 2",
      type: "text",
      value: url2,
      onChange: "setUrl2",
      disabled: url1,
      req: 1,
    },
    {
      name: "Url 3",
      type: "text",
      value: url3,
      onChange: "setUrl3",
      disabled: url2,
      req: 2,
    },
    {
      name: "Url 4",
      type: "text",
      value: url4,
      onChange: "setUrl4",
      disabled: url3,
      req: 3,
    },
    {
      name: "Url 5",
      type: "text",
      value: url5,
      onChange: "setUrl5",
      disabled: url4,
      req: 4,
    },
    {
      name: "Url 6",
      type: "text",
      value: url6,
      onChange: "setUrl6",
      disabled: url5,
      req: 5,
    },
    {
      name: "Url 7",
      type: "text",
      value: url7,
      onChange: "setUrl7",
      disabled: url6,
      req: 6,
    },
    {
      name: "Url 8",
      type: "text",
      value: url8,
      onChange: "setUrl8",
      disabled: url7,
      req: 7,
    },
    {
      name: "Url 9",
      type: "text",
      value: url9,
      onChange: "setUrl9",
      disabled: url8,
      req: 8,
    },
    {
      name: "Url 10",
      type: "text",
      value: url10,
      onChange: "setUrl10",
      disabled: url9,
      req: 9,
    },
  ];

  const changeValue = (value, e) => {
    switch (value) {
      case "setUrl1":
        setUrl1(e.target.value);
        break;
      case "setUrl2":
        setUrl2(e.target.value);
        break;
      case "setUrl3":
        setUrl3(e.target.value);
        break;
      case "setUrl4":
        setUrl4(e.target.value);
        break;
      case "setUrl5":
        setUrl5(e.target.value);
        break;
      case "setUrl6":
        setUrl6(e.target.value);
        break;
      case "setUrl7":
        setUrl7(e.target.value);
        break;
      case "setUrl8":
        setUrl8(e.target.value);
        break;
      case "setUrl9":
        setUrl9(e.target.value);
        break;
      case "setUrl10":
        setUrl10(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleUpdate = (index, e, choice) => {
    let data = "";
    switch (choice) {
      case "url":
        data = [...urlFieldsToUpdate];
        data[index]["url"] = e.target.value;
        setUrlFieldstoUpdate(data);
        break;
      case "active_flag":
        data = [...urlFieldsToUpdate];
        data[index]["active_flag"] = e.target.value;
        setUrlFieldstoUpdate(data);
        break;
      default:
        break;
    }
  };

  const handleDelete = (e) => {
    // if(deleteAll)setDeleteAll(!deleteAll)
    let data = [...urlsToDelete];
    if (data.includes(parseInt(e.target.name))) {
      const index = data.indexOf(parseInt(e.target.name));
      data.splice(index, 1);
      setUrlsToDelete([...data]);
    } else {
      data.push(parseInt(e.target.name));
      setUrlsToDelete([...data]);
    }
  };

  const handleDeleteAll = (e) => {
    let data = [...urlsToDelete];
    if (!deleteAll) {
      for (var i = 0; i < urlFieldsToUpdate.length; i++) {
        if (data.indexOf(urlFieldsToUpdate[i].id) === -1) {
          data.push(urlFieldsToUpdate[i].id);
        }
      }
      setUrlsToDelete([...data]);
      setDeleteAll(true);
    } else {
      data = [];
      setUrlsToDelete([...data]);
      setDeleteAll(false);
    }
  };

  const makeDisable = () => {
    if (opp === "Insert") {
      if (!url1) {
        return true;
      } else {
        return false;
      }
    }

    if (opp === "Update") {
      if (urlFieldsToUpdate.length === 0) {
        return true;
      } else {
        return false;
      }
    }

    if (opp === "Delete") {
      if (urlsToDelete.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getButtonClass = () => {
    if (opp === "Insert") {
      if (!url1) {
        return "disabledButton";
      } else {
        return "submitButton";
      }
    }

    if (opp === "Update") {
      if (urlFieldsToUpdate.length === 0) {
        return "disabledButton";
      } else {
        return "submitButton";
      }
    }
    if (opp === "Delete") {
      if (urlsToDelete.length === 0) {
        return "disabledButton";
      } else {
        return "submitButton";
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ciName === "" || teamName === "") {
      alert(
        "Please enter mandatory fields in url Onboard Info Form\nPress ok to continue"
      );
      return ;
    }
    if (opp === "Insert") {
      setLoading(true);
      let teamidData = "";
      try {
        teamidData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${teamName}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamidData = teamidData.data.data;
        if (teamidData.error) {
          if (teamidData.status === 401) {
            throw new Error(teamidData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the team id"
          );
        } else {
        }

        let data = [
          url1,
          url2,
          url3,
          url4,
          url5,
          url6,
          url7,
          url8,
          url9,
          url10,
        ];
        const urls = createUrlArray(data, "Insert");

        const isUrlsValid = urlsArevalid(urls, "Insert");

        const cleanUrls = getCleanUrls(urls, "Insert");

        if (isUrlsValid.valid) {
          let teamID = teamidData.data.id;

          let p = 443;

          let body = { teamID, ciName, p, cleanUrls, flag };
          let response = await axios.post(
            `${process.env.REACT_APP_ONBOARDER_API}/addinurl`,
            {
              mode: "cors",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              ...body,
            }
          );
          let responseData = response.data.data;

          if (responseData.error) {
            if (responseData.status === 401) {
              throw new Error(responseData.message);
            }
            throw new Error(
              "Unable To Onboard URLs\nPossible Reasons:\n1: You may have entered an already onboarded url.\n2 You may have included the protocol and/or port in the URL \n3: Network Error\n4: Database is down\nContact SRE\nPress ok to continue"
            );
          } else {
            setLoading(false);
            // setVariant("success");
            // setAlertHeading("Success");
            // setAlertContent("Your URLs have been successfully onboarded!\nPress 'ok' to add more URLs")
            // setShowAlert(true)
            alert(
              "Your URLs have been successfully onboarded!\nPress 'ok' to add more URLs"
            );

            setUrl1("");
            setUrl2("");
            setUrl3("");
            setUrl4("");
            setUrl5("");
            setUrl6("");
            setUrl7("");
            setUrl8("");
            setUrl9("");
            setUrl10("");
            setTeamName("");
            setCiName("");
            setActiveFlag("");
            setPort("");
          }
        } else {
          setLoading(false);
          alert(
            `ERR - please check your url no. ${isUrlsValid.urlNo}. Accepted formats are: \n https, or http or www. or none\nExample: \nhttps://www.maersk.com\nhttp://www.maersk.com\nwww.maersk.com\nmaersk.com`
          );
        
          return false
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
        setCiName("");
        setTeamName("");
      }
    }
    //UPDATION
    else if (opp === "Update") {
      setLoading(true);
      let teamidData = "";
      try {
        teamidData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${teamName}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamidData = teamidData.data.data;
        if (teamidData.error) {
          if (teamidData.status === 401) {
            throw new Error(teamidData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the team id"
          );
        } 

        const urls = createUrlArray(urlFieldsToUpdate, "Update");

        const isUrlsValid = urlsArevalid(urls, "Update");

        const cleanUrls = getCleanUrls(urls, "Update");

        if (isUrlsValid.valid) {
          let teamID = teamidData.data.id;

          let p = 443;

          let body = { teamID, ciName, p, cleanUrls, flag };
          let response = await axios.post(
            `${process.env.REACT_APP_ONBOARDER_API}/updateUrls`,
            {
              mode: "cors",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              ...body,
            }
          );
          let responseData = response.data.data;

          if (responseData.error) {
            if (responseData.status === 401) {
              throw new Error(responseData.message);
            }
            throw new Error(
              "Unable To Update URLs\nPossible Reasons:\n1: You may have entered an already onboarded url.\n2: Network Error\n3: Database is down\nContact SRE\nPress ok to continue"
            );
          } else {
            setLoading(false);
            alert(
              "Your URLs have been successfully updated!\nPress 'ok' to continue"
            );

            setTeamName("");
            setCiName("");
            setUrls("");
          }
        } else {
          setLoading(false);
          alert(
            `ERR - please check your url no. ${isUrlsValid.urlNo}. Accepted formats are :\n https, or http or www. or none\nExample: \nhttps://www.maersk.com\nhttp://www.maersk.com\nwww.maersk.com\nmaersk.com`
          );
          return 
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
  
      }
    }

    //DELETION
    else {
      setLoading(true);

      try {
        let response = await axios.delete(
          `${process.env.REACT_APP_ONBOARDER_API}/deleteUrls`,
          {
            mode: "cors",
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              urlsToDelete: urlsToDelete,
            },
          }
        );
        let responseData = response.data.data;

        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable To Delete URLs\nPossible Reasons:.\n1 Network Error\n2: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          alert(
            "Your URLs have been successfully deleted!\nPress 'ok' to continue"
          );

          setTeamName("");
          setCiName("");
          setUrls("");
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
    // return false
  };

  return (      
  <>
    <h3 style={{ fontWeight: "100" }}>Onboard URLs</h3>
    <div className="urlOnBoardFormContainer" style={{ width: "75%" , overflow:"auto"}}>
        <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
        {(opp === "Insert" || opp === "Update")?  
        <p style={{fontSize:"12.8px", marginBottom:"12px"}}>  <span style={{ color: "red", fontWeight: "bold" }}>*</span>Enter just the base URL without port - example: www.maersk.com - not www.maersk.com:443"</p>
        :""  
      }
        
        {opp === "Delete" && urlFieldsToUpdate.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <Form.Check
              type="checkbox"
              id="default-checkbox"
              checked={deleteAll}
              onChange={() => handleDeleteAll()}
              label="Delete All"
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div>
            <h4>Urls</h4>
          </div>
          <div
            style={{
              display: "flex",
              width:
                opp === "Delete" && urlFieldsToUpdate.length > 0
                  ? "65%"
                  : "60%",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{paddingLeft:opp === "Insert" || opp === "Update" || (opp === "Delete" && urlFieldsToUpdate.length === 0)? "129px": ""}}>Port</h4>
            <h4
              style={{
                marginRight:
                  opp === "Delete" && urlFieldsToUpdate.length > 0
                    ? ""
                    : "15px",
                paddingLeft:
                  opp === "Delete" && urlFieldsToUpdate.length > 0
                    ? "34px"
                    : "",
              }}
            >
              Active Flag
            </h4>
            {opp === "Delete" && urlFieldsToUpdate.length > 0 && (
              <h4>Delete</h4>
            )}
          </div>
        </div>
        <Form.Group className="mb-3 formUrlGroup" controlId="formBasicEmail">
          {/* <div></div> */}

          {urlFieldsToUpdate.length > 0
            ? urlFieldsToUpdate.map((urlField, index) => {
                return (
                  <>
                    <div style={{ display: "flex", flexDirection: "column", width:(opp === "Update")? "50%" : "" }}>
                      <Form.Control
                        style={{ marginTop: "22px" }}
                        className="inputBox"
                        placeholder={gettingUrls ? "Loading Urls" : ""}
                        type="text"
                        name={urlField.id}
                        onChange={(e) => handleUpdate(index, e, "url")}
                        value={urlField.url}
                        required={true}
                        disabled={opp === "Delete" || gettingUrls}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: opp === "Update" ? "40%" : "65%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ 
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          width: opp === "Delete" || opp ===  "Update" ? "70px" : "",
                          marginLeft: opp === "Delete" ? "" : "",
                        }}
                      >
                        <div></div>
                        <Form.Control
                          style={{ marginBottom: "0px" }}
                          className="inputBox"
                          type="text"
                          value={443}
                          disabled={true}
                        />
                      </div>
                      <div>
                        <Box
                          sx={{
                            minWidth: 120,
                            marginBottom: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {"Active"}
                            </InputLabel>
                            <Select
                              style={{ borderTop: "0px", height: "40px" }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={urlField.active_flag}
                              label={"Active"}
                              disabled={opp === "Delete"}
                              name={urlField.id}
                              // placeholder={data.disabled === "" && `url ${data.req} is required`}
                              onChange={(e) =>
                                handleUpdate(index, e, "active_flag")
                              }
                            >
                              <MenuItem
                                style={{
                                  fontFamily: "Maersk Text",
                                }}
                                value={true}
                              >
                                true
                              </MenuItem>
                              <MenuItem
                                style={{
                                  fontFamily: "Maersk Text",
                                }}
                                value={false}
                              >
                                false
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      {opp === "Delete" && (
                        <div>
                          <Form.Check
                            type="checkbox"
                            name={urlField.id}
                            id="default-checkbox"
                            checked={urlsToDelete.indexOf(urlField.id) !== -1}
                            disabled={deleteAll}
                            onChange={(e) => handleDelete(e)}
                          />
                        </div>
                      )}
                    </div>
                  </>
                );
              })
            : formInputFieldData.map((data) => {
                return (
                  <>
                    <div style={{ display: "flex", flexDirection: "column", width:"50%" }}>
                      <Form.Control
                        style={{ marginTop: "22px" , }}
                        className="inputBox"
                        placeholder={gettingUrls ? "Loading Urls" : ""}
                        type={data.type}
                        onChange={(e) => changeValue(data.onChange, e)}
                        value={data.value}
                        required={data.required ? true : false}
                        disabled={gettingUrls}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "40%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          width:"70px"
                        }}
                      >
                        <div></div>
                        <Form.Control
                          style={{ marginBottom: "0px" }}
                          className="inputBox"
                          type={data.type}
                          // placeholder={data.disabled === "" && `url ${data.req} is required`}
                          onChange={(e) => changeValue(data.onChange, e)}
                          value={443}
                          //  required={data.required ? true : false}
                          disabled={true}
                        />
                      </div>
                      <div>
                        <Box
                          sx={{
                            minWidth: 120,
                            marginBottom: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {"Active"}
                            </InputLabel>
                            <Select
                              style={{ borderTop: "0px", height: "40px" }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={true}
                              label={"Active"}
                              onChange={(e) => changeValue(data.onChange, e)}
                              disabled={true}
                            >
                              <MenuItem
                                style={{
                                  fontFamily: "Maersk Text",
                                }}
                                value={true}
                              >
                                true
                              </MenuItem>
                              <MenuItem
                                style={{
                                  fontFamily: "Maersk Text",
                                }}
                                value={false}
                              >
                                false
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </div>
                  </>
                );
              })}
          {/* {} */}
        </Form.Group>
        <div className="buttonContainer">
          <button
            disabled={makeDisable()}
            type="submit"
            className={getButtonClass()}
          >
            {opp === "Insert"
              ? "Add Now"
              : opp === "Update"
              ? "Update"
              : "Delete"}
          </button>
        </div>
      </Form>
    </div>
</>
   
  );
}

export default UrlsOnboardForm;
