import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../../Components/Loading";
import { Row, Col } from "react-bootstrap";
import "../../../../StyleSheets/PostgressReport.css";
import ReactToPrint from "react-to-print";
import Error from "../../../../Components/Error";
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';

export const SQLReport = React.forwardRef((props, ref) => {
  var url;
  var componentRef = useRef();
  const [error, setError] = useState({ Error: "" });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const { state } = useLocation();
  const { host, port, userName, password, DB, setIsReportLoaded } = props;

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  const [isAtTop, setIsAtTop] = useState(true);
  useEffect(() => {
    // Add an event listener to track scrolling
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsAtTop(true);
      } else {
        setIsAtTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchData();
    return () => {
      setLoading(true);
    };
  }, []);
  const fetchData = async () => {
    try {
      let config = {
        headers: {
          "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
        },
      };
      const wrapperApi = process.env.REACT_APP_APIGEE_WRAPPER_API;


      url =
        wrapperApi +
        `/sql/forensics?host=${host.trim()}&dbname=${DB.trim()}`;

      if (userName !== "") {
        url = url + `&user=${userName.trim()}`
      }
      if (password !== "") {
        url = url + `&password=${password.trim()}`;
      }
      if (port != 0 && port.toString().length !== 0) {
        url = url + `&port=${port}`;
      }

      const res = await axios.get(url, config);

      if (res.status === 422) {
        setError({
          ...error,
          Error: res.data.message,
          comingFrom: "sqlForensics",
        });
        return;
      }
      setData(res.data);
      setLoading(false);
      setIsReportLoaded(true);
    } catch (err) {
      console.log(err);

      if (err.response.status === 504) {
        setError({
          ...error,
          Error: "Request Timed Out, Please try again",
          comingFrom: "sqlForensics",
        });
      } else {
        setError({
          ...error,
          Error: err.response.data.message,
          comingFrom: "sqlForensics",
        });
      }
      setLoading(false);
    }
  };
  console.log(typeof data)
  console.log(data)


  return (
    <>
      {loading ? (
        <div style={{ marginTop: "-20rem" }}><Loading /></div>
      ) : error.Error !== "" ? (
        <Error element={error} />
      ) : (
        <>
          <div className="fixedSection">
            {isAtTop ?
              <Button variant="outlined" style={{ marginRight: "12px" }} onClick={() => goToTop()} disabled>Go To Top</Button>
              :
              <Button variant="outlined" style={{ marginRight: "12px" }} onClick={() => goToTop()}>Go To Top</Button>
            }
            <ReactToPrint
              trigger={() => {
                return (
                  <Button variant="contained">Download PDF</Button>
                );
              }}
              content={() => componentRef}
            />
          </div>
          <div className="reportSection">
            <div ref={(el) => (componentRef = el)}>
              <br></br>
              <br></br>
              <div className="display-flex">
                <Row>
                  <Col className="sm-9">
                    <h2 className="text-center">SQL Forensics for</h2>
                    <h2 className="text-center">{`DB Name: ${DB} `}</h2>
                    <h2 className="text-center">{`Server Name: ${host} `}</h2>
                  </Col>
                </Row>
              </div>

              <br />
              <br />

              {data &&
                data.map((d) => {
                  return (
                    <div className="px-5 m-2" style={{ width: "100%" }}>
                      <h3>{d.Title}</h3>
                      <p className="py-2">{d.Explanation}</p>
                      {d.Recommendation !== "" ? <p className="py-2"><b>Suggestion - </b>{d.Recommendation}</p> : ""}
                      {d.error == "" ? <table className="table table-striped">
                        <thead>
                          <tr>
                            {Object.keys(d.TableRecords[0]).map(
                              (currHeading) => {
                                return <th>{currHeading}</th>;
                              }
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {d.TableRecords.map((currRecord) => {
                            return (
                              <tr>
                                {Object.values(currRecord).map((rec) => {
                                  return <td>{rec === null ? "0" : rec}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table> : <h4>{d.error}</h4>}
                      <p></p>
                      <br />
                    </div>
                  );
                })}
            </div>

            {/* <ReactToPrint
              trigger={() => {
                return (
                  <button className="downloadBtn" href="#">
                    Download PDF
                  </button>
                );
              }}
              content={() => componentRef}
            /> */}
          </div>
        </>
      )}
    </>
  );
});