import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavBar from "../Components/NavBar";
import illustration from "../Images/Team-Logo-11_vignette_HS.png";
import "../StyleSheets/Home.css";
import { Link } from 'react-router-dom';
function Home() {
  return (
    <>
      <NavBar />
      <Container fluid className="Container">
        <Row className="row1">
          <Col className="col1">
            <h1 className="mainHeading">
              Welcome to SRE &amp; Performance Engineering
            </h1>
            <h5 className="subHeading" style={{ marginBottom: "2rem" }}>
              {" "}
              Home for Reliability and Performance Engineering tools, shared
              capabilities &amp; playbooks
              <br /> for designing and running reliable and scalable services. {" "}
            </h5>
            <Row className="buttonRow">
              <Col>
                {" "}
                <Button className="getStartedButton" style={{}}>
                 <Link className="getStartedButtonLink" to="/capability"> Get Started</Link>
                </Button>
              </Col>
              {/* <Col>
                <Button
                  variant="outline-dark"
                  className="contributeButton"
                  style={{}}
                >
                 <Link className="contributeButtonLink" to="/contribute">Contribute</Link>
                </Button>
              </Col> */}
            </Row>
            <br/>
            <h5  className="subHeading">To Contribute Contact <Link style={{textDecoration:"none", color:"#00243D"}} to="/about"><strong>SRE Team</strong></Link></h5>
          </Col>
          <Col className="col2">
            <img className="image" src={illustration} alt="image" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
