import { generate } from "./generateYaml";

export const send = async (
  graphData,
  sloData,
  updating,
  token,
  sha,
  setLoading,
  setAlertPopUp,
  setAlertContent,
  setAlertSeverity,
  yamlDoc,
  slosContextArray,
  setShow
) => {
  console.log("SlosContextArray ", slosContextArray);
  const yamlString = generate(yamlDoc, sloData, slosContextArray, setShow);
  const committer = JSON.stringify({
    name: graphData.displayName,
    email: graphData.mail,
  });
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  let filename = ""
  if(sloData?.Application?.value){
    filename = sloData.Application.value.replace(/[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/g, '_').replace(/\s+/g, '_')
  }else{
    filename = sloData.Product.value.replace(/[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/g, '_').replace(/\s+/g, '_')

  }
  urlencoded.append(
    "message",
    `successfully committed ${filename}.yml to sloth`
  );
  urlencoded.append("content", `${btoa(yamlString)}`);
  urlencoded.append("filename", `${filename}` + ".yml");
  urlencoded.append("authToken", `Bearer ${token}`);
  urlencoded.append("committer", committer);
  urlencoded.append("email",graphData.mail) 
  urlencoded.append("env", sloData.Environment.value)
  // url

  if (updating) {
    urlencoded.append("sha", `${sha}`);
  }
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
    mode: "cors",
  };

  console.log("Body ", requestOptions.body);
  try {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_SLOTH_API}` + "/slo",

      requestOptions
    );

    if (!response.ok) {
      // console.log("Response ", response.body.JSON())
      throw new Error(response.statusText);
    } else {
      setLoading(false);
      setAlertPopUp(true);
      setAlertSeverity("success");
      if (updating) {
        setAlertContent(
          `${filename} updated successfully, please wait for two minutes`
        );
      } else {
        setAlertContent(
          `${filename} submitted successfully, please wait for two minutes`
        );
      }
    }
  } catch (error) {
    setLoading(false);
    setAlertPopUp(true);
    setAlertSeverity("error");
    if (updating) {
      setAlertContent("Error in updating yaml file, please contact the SRE");
    } else {
      setAlertContent(
        "Error in submiting yaml file, SLO Already Exists or you have used invalid auth token, please contact the SRE"
      );
    }

    // console.error("There was a problem:", error);
  }
};
