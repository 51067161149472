import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Components/NavBar";
import "../StyleSheets/Manifesto.css";

function Manifesto() {
  const data = [
    {
      heading: "Monitoring & Alerting",
      placing: "left",
      color: "#00243D",
      Records: [
        {
          title: "Every Product has SLA and SLO",
          content:
            "SLA measured over month , SLO is reliability per our systems and users. Error budgets drive our priorities",
        },
        {
          title: "Systems are architected for Observability",
          content:
            "Metrics, Traces, and logs are seamlessly integrated with central observability platform",
        },
        {
          title: "We are the first to know our incidents",
          content:
            "All relevant metrics must trigger alerts once they reach a critical threshold. Alert and metric design are part of the architecture review",
        },
      ],
    },
    {
      heading: "Reliability & Resiliency",
      placing: "left",
      color: "#77C6E0",
      Records: [
        {
          title: "Products stay responsive",
          content:
            "Services stay responsive in the face of failure. Regularly tested based on chaos principles. Top tier services will not go down, in the eventuality the services will be available and serving in degraded state",
        },
        {
          title: "Components and services limit risk of failure",
          content:
            "Resiliency is achieved by containing failures within each component through use of patterns - Replication, containment, isolation, timeout, circuit breaker, fallback, throttling and others ",
        },
      ],
    },
    {
      heading: "Performance & Scale",
      placing: "right",
      color: "#878787",
      Records: [
        {
          title: "Products scale and support rapid growth",
          content:
            "Regular load tests are conducted to ramp up by at least 3x of current traffic/load",
        },
        {
          title: "Performance testing is integrated into pipelines",
          content: "P95 is the measurement criteria and will be improved QoQ",
        },
        {
          title: "Systems will become cost efficient",
          content:
            "As we scale, by design systems will accommodate increased workload by applying cost-effective strategies",
        },
      ],
    },
    {
      heading: "Deployment & Automation",
      placing: "right",
      color: "#FA6A55",
      Records: [
        {
          title: "We run 24x7",
          content:
            "Zero-downtime deployments through canary or blue-green deployments or rolling updates. We conduct small and frequent changes.",
        },
        {
          title: "Automation is just reliable operations",
          content:
            "Operations is automated, testing is automated, access is self-service, deployment is continuous delivery. Our infrastructure is a code",
        },
      ],
    },
    {
      heading: "Operations & Support",
      placing: "right",
      color: "#058EC6",
      Records: [
        {
          title: "We continously learn",
          content:
            "We conduct blameless post-mortems. We keep our runbooks and playbooks updated and tested. We work together to improve the reliability, performance and security of our systems.",
        },
        {
          title: "We are accountable for our Customer Experience",
          content:
            "We know our customers and we understand why you’re here. We aim to elevate our customer experience",
        },
      ],
    },
  ];
  return (
    <>
      <NavBar />

      <Container fluid className="contentContainer marginTopContainer mr-5 paddingTopContainer">
        <Row>
          <Col className="py-5 marginTopContainerHeading">


            <h1 className="px-3 headingContainer">SRE Manifesto</h1>



            {data.map((d) => (
              <>
                {d.placing == "left" ? (
                  <>
                    <Container className="px-4">
                      <Row className="my-4 paddingHeadingContainer">
                        <Col
                          xs={2}
                          style={{ backgroundColor: d.color }}
                          className="containerNew"
                        >
                          <p
                            style={{ color: "#fff", fontWeight: "bold" }}
                            className="contentStyle paraFontSize"
                            
                          >
                            {d.heading}
                          </p>
                        </Col>
                        <Col>
                          {d.Records.map((p) => (
                            <>
                              <h5
                                className="py-1 my-0 headingFontSize"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#003D6D",
                                }}
                              >
                                {p.title}
                              </h5>
                              <p className="paraFontSize pb-2 mb-2 mt-0 pt-0 ">{p.content}</p>
                            </>
                          ))}
                        </Col>
                      </Row>
                    </Container>
                  </>
                ) : (
                  " "
                )}
              </>
            ))}
          </Col>
          <Col className="py-2 my-2">
            {data.map((d) => (
              <>
                {d.placing == "right" ? (
                  <>
                    <Container>
                      <Row className="py-2 my-2">
                        <Col
                          xs={2}
                          style={{ backgroundColor: d.color }}
                          className="containerNew"
                        >
                          <p className="paraFontSize" style={{ color: "#fff", fontWeight: "bold" }}>
                            {d.heading}
                          </p>
                        </Col>
                        <Col>
                          {d.Records.map((p) => (
                            <>
                              <h5
                                className="py-1 my-0 headingFontSize"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#003D6D",
                                }}
                              >
                                {p.title}
                              </h5>
                              <p className="paraFontSize pb-2 mb-2 mt-0 pt-0">{p.content}</p>
                            </>
                          ))}
                        </Col>
                      </Row>
                    </Container>
                  </>
                ) : (
                  " "
                )}
              </>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Manifesto;