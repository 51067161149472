import React from 'react';
import "./Style.css";

const HomeHeading = () => {
    return (
        <>
            <div className="HeadingWrapper">
                <h2><strong >SRE and Performance Capabilities</strong></h2>
                <p style={{ color: "#00243D", fontSize: "16px" }} className="capabilityPage__headingPara">
                    SkyDeck products and capabilities for improving your systems'
                    reliability!
                    <br />Access the products from the sidebar menu.
                    <br />
                    <br />
                    Refer to <nbsp></nbsp>
                    <a style={{ textDecoration: "none" }} href={`${process.env.REACT_APP_SRE_SKYDECK_LIBRARY_SITE}`} target="_blank">
                        Library
                    </a>{" "}
                    for more tools, patterns, documents for your reference and use.
                    <br />
                    You can also contribute to SkyDeck capabilities, tools and documents.
                    <br />
                </p>
            </div>
        </>
    )
};

export default HomeHeading;