export const calculate = (sloTarget, window) => {
   sloTarget = parseInt(sloTarget)
    const downtimeSeconds =
    (1 - parseFloat(sloTarget) / 100) * window * 24 * 60 * 60;

  const downtimeMinutes = Math.floor(downtimeSeconds / 60);
  const downtimeHours = Math.floor(downtimeMinutes / 60);

  if (!isNaN(downtimeMinutes) && !isNaN(downtimeHours))
    return `${downtimeHours} hours and ${downtimeMinutes % 60} minutes`;
}