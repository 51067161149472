import React, { useState } from "react";
import "./styles.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PostgresForensicForm from "../../../../Capabilities/Reliability/Forensics/PostgresForensics/PostgresForensicForm";
import SQLForensicForm from "../../../../Capabilities/Reliability/Forensics/SqlForensics/SQLForensicForm";
import Neo4jForensicForm from "../../../../Capabilities/Reliability/Forensics/Neo4jForensics/Neo4jForensicForm";

const Forensic = () => {
    const [selectedOption, setSelectedOption] = useState("postgres");

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
    };

    return (
        <>
            <div className="optionSection">

                <Box sx={{ width: '100%' , display:"flex" , justifyContent:"flex-start"}}>
                    <Tabs
                        value={selectedOption}
                        onChange={handleChange}
                        aria-label="wrapped label tabs example"
                    >
                        <Tab
                            style={{ fontSize: "14px" }}
                            value="postgres"
                            label="Postgres"
                            // wrapped
                        />
                        <Tab style={{ fontSize: "14px" }} value="sql_server" label="SQL Server" />
                        <Tab style={{ fontSize: "14px" }} value="neo4j" label="Neo4j Server" />
                    </Tabs>
                </Box>

            </div>

            <div className="TabsBox">

                <div className="Contents">
                    <div className={selectedOption === "postgres" ? "content active-content" : "content"}>
                        {selectedOption === "postgres" && <PostgresForensicForm/>}
                    </div>
                    <div className={selectedOption === "sql_server" ? "content active-content" : "content"}>
                        {selectedOption === "sql_server" && <SQLForensicForm /> }
                    </div>
                    <div className={selectedOption === "neo4j" ? "content active-content" : "content"}>
                        {selectedOption === "neo4j" && <Neo4jForensicForm /> }
                    </div>
                </div>
            </div>

        </>
    );
};

export default Forensic;