import React, { useState } from "react";
import NavBar from "../../../Components/NavBar";
import "../../../StyleSheets/URLBatch.css";
import { Button } from "react-bootstrap";
import Error from "../../../Components/Error";
import LoadingValidate from "../../../Components/LoadingValidate";
import { URLBatchReport } from "./URLBatchReport";
function URLBatch() {
  const [urlreport, setURLreport] = useState(false);
    const [detailURL, setDetailURLReport] = useState(false);
    const [loading, setloading] = useState(true);
    const [validateload, setValidateLoad] = useState(false);
    const [stat, setstat] = useState(0);
    const [error, setError] = useState({ Error: "" });

  // const handleSubmit = () => {
  //   setURLreport(true);
  //   setDetailURLReport(false);
  //   setValidateLoad(false);
  // };
const handleDetailedSubmit =() =>{
    setDetailURLReport(true);
    setURLreport(false);
    setValidateLoad(false);
    
}
 
  return (
    <>
      <NavBar />
      <div className="container-fluid py-5 my-3 text-center batchReport">
        <h2 className="headingURLReport">Validate Onboarded URLs</h2>
        <div className="container imgContainerReport p-4 containDivReport">
          
          <Button
            type="submit"
            className="btn btn-dark m-3 text-center submitBtnBatch"
            onClick={handleDetailedSubmit}
          >
           Report
          </Button>
         
        </div>
      </div>

      {validateload ? (
        loading ? (
        
          <LoadingValidate />
        ) : stat === 200 ? (
          ""
         
        ) : error ? (
          <Error element={error} />
        ) : (
          <h1>Error Occured , Please contact SRE team</h1>
        )
      ) : 
      urlreport ? (
          <URLBatchReport detailReport={true} />
        ) : (
          ""
      )}

      {detailURL ? (
        <URLBatchReport detailReport={true} />
      ) : (
        ""
      )}
      </>

    

  );
}

export default URLBatch;
