import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import NavBar from "../../Components/NavBar";
import { onlyNumeric } from "../util/checkOnlyNumeric";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import UrlsOnboardForm from "./UrlsOnboardForm";

function UrlOnBoardInfoForm() {
  const [port, setPort] = useState("");
  const [urls, setUrls] = useState([]);
  const [activeFlag, setActiveFlag] = useState(true);
  const [teamName, setTeamName] = useState("");
  const [teamNames, setTeamNames] = useState([]);
  const [ciNames, setCiNames] = useState([]);
  const [ciName, setCiName] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [opp, setOpp] = useState("Insert");
  const [gettingTeamName, setGettingTeamName] = useState(false)
  const [gettingUrls, setGettingUrls] = useState(false)

  const [formType,setFormType] = useState("onboarding_info");

  const calledOnce = useRef(false);

  useEffect(() => {
    setUrls([]);
    if (opp === "Update" || opp === "Delete") {
      if ((ciName !== "") & (teamName !== "")) fetchUrls();
    }
  }, [opp, ciName, teamName]);

  const fetchUrls = async () => {
    let encodeciName = encodeURIComponent(ciName);
    let encodeTeamName = encodeURIComponent(teamName);
    let teamId = "";
    try {
      setGettingUrls(true)
      let teamData = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamData?name=${encodeTeamName}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      teamData = teamData.data.data;
      teamId = teamData.data[0].id;
    } catch (error) {
      // console.log(error);
    }
    try {
      let urls = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getUrls?name=${encodeciName}&teamId=${teamId}`,
        {
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      urls = urls.data.data;
      if (urls.error) {
        if (urls.status === 401) {
          throw new Error(urls.message);
        }
        throw new Error(
          "No Urls are associated with this CI\nPlease Enter some urls first"
        );
      } else {
        setUrls(urls.data);
        setGettingUrls(false)
      }
    } catch (err) {
      setGettingUrls(false)
      alert(err);
    }
  };


  useEffect(() => {
    if (calledOnce.current) {
      return;
    }
    fetchNames();
    calledOnce.current = true;
  }, []);
  const fetchNames = async () => {
    try {
      let ciNames = await axios.get(`${process.env.REACT_APP_ONBOARDER_API}/getCiNames`, {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      ciNames = ciNames.data.data;
      if (ciNames.error) {
        throw new Error(ciNames.message);
      } else {
        setCiNames(ciNames.ci_names);
      }
    } catch (err) {
     
      alert(err);
    }
  };
  useEffect(() => {
    fetchTeamName();
  }, [ciName]);

  const fetchTeamName = async () => {
    if (ciName == "") return;
    let ciNameEncode = encodeURIComponent(ciName);
    try {
      setGettingTeamName(true)
      let tName = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamName?ci_name=${ciNameEncode}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      tName = tName.data.data;
      if (tName.error) {
        throw new Error(tName.message);
      } else {
        setGettingTeamName(false)
        setTeamName(tName.data.name);
       
      }
    } catch (err) {
      setGettingTeamName(false)
      alert(err);
    }
  };

  const formInputFieldData = [
    {
      name: "CI",
      type: "select",
      required: true,
      isTextArea: false,
      onChange: "setCiName",
      value: ciName,
      names: ciNames,
      text: "Select CI",
    },

    {
      name: "Team Name",
      type: "text",
      required: true,
      isTextArea: false,
      onChange: "setTeamName",
      value: teamName,
      // disabled: true,
      names: teamNames,
      text: "Team",
    },
  ];

  const changeValue = (value, e) => {
    switch (value) {
      case "setCiName":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          setCiName("");
          return;
        }
        setTeamName("");
        // setActiveFlag("")
        setCiName(e.target.value);
        break;
      case "setTeamName":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          setTeamName("");
          return;
        }
        setCiName("");
        setActiveFlag("");
        setTeamName(e.target.value);
        break;

      case "setActiveFlag":
        if (opp === "") {
          alert("Please select an operation first\nPress ok to continue");
          setActiveFlag("");
          return;
        }
        if (e.target.value === "true") setActiveFlag(true);
        else setActiveFlag(false);
        break;
      default:
        break;
    }
  };
  const handleOppChange = (e) => {
    setOpp(e.target.value);
  };

  return (

    <>
      {/* <NavBar /> */}
      <div className="topContainer">
      {loading ? (
        <h2
          style={{ fontWeight: "100", marginTop: "5rem", textAlign: "center" }}
        >
          {opp === "Insert"
            ? "Onboarding"
            : opp === "Update"
            ? "Updating"
            : "Deleting"}{" "}
          your Urls, Please wait!!
        </h2>
      ) : (
        <>
          <div className="formsContainer">
            <div className="urlInfoForm">
              <h3 style={{ fontWeight: "100" }}>URL Onboarding Info</h3>
              <div className="urlOnBoardFormContainer" style={{width:"100%"}}>
                <Form style={{ width: "100%" }}>
                  <Form.Group
                    className="mb-3 formGroup"
                    controlId="formBasicEmail"
                  >
                    <Form.Label style={{}} className="formLabel">
                      Operation
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </Form.Label>
                    <Box
                      sx={{
                        minWidth: 120,
                        marginBottom: "20px",
                        marginTop: "20px",
                        border: "none",
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Operation
                        </InputLabel>
                        <Select
                          style={{ borderTop: "0px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={opp}
                          label="Select Opeartion"
                          onChange={(e) => handleOppChange(e)}
                        >
                          <MenuItem
                            style={{ fontFamily: "Maersk Text" }}
                            value="Insert"
                          >
                            Onboard URLs
                          </MenuItem>
                          <MenuItem
                            style={{ fontFamily: "Maersk Text" }}
                            value="Update"
                          >
                            Update URLs
                          </MenuItem>
                          <MenuItem
                            style={{ fontFamily: "Maersk Text" }}
                            value="Delete"
                          >
                            Delete URLs
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    {formInputFieldData.map((data) => {
                      // console.log(data);
                      return (
                        <>
                          <Form.Label className="formLabel">
                            {data.name}{" "}
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              *
                            </span>
                          </Form.Label>
                          {/* {!data.isTextArea ? ( */}
                          {data.type === "select" ? (
                            <Box
                              sx={{
                                minWidth: 120,
                                marginBottom: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {data.text}
                                </InputLabel>
                                <Select
                                  style={{ borderTop: "0px" }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={data.value}
                                  label={data.text}
                                  onChange={(e) =>
                                    changeValue(data.onChange, e)
                                  }
                                  disabled={data.text === ""}
                                >
                                  {data.names.length != 0 &&
                                    data.names.map((name) => {
                                      return (
                                        // console.log(name.name),
                                        <MenuItem
                                          style={{
                                            fontFamily: "Maersk Text",
                                          }}
                                          value={name.name}
                                        >
                                          {name.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                          ) : (
                            <Form.Control
                              className="inputBox"
                              type={data.type}
                              placeholder={gettingTeamName ? "Loading Team" : ""}
                              onChange={(e) => changeValue(data.onChange, e)}
                              value={data.value}
                              required={data.required ? true : false}
                              disabled={true}
                            />
                          )}
                        </>
                      );
                    })}
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="urlInfoForm" style={{width:"65%"}}>
              <UrlsOnboardForm
                port={port}
                teamName={teamName}
                ciName={ciName}
                flag={activeFlag}
                loading={loading}
                setLoading={setLoading}
                opp={opp}
                urls={urls}
                setOpp={setOpp}
                setActiveFlag={setActiveFlag}
                setCiName={setCiName}
                setPort={setPort}
                setTeamName={setTeamName}
                setUrls={setUrls}
                gettingUrls={gettingUrls}
              />
            </div>
          </div>
        </>
      )}
      
      </div>
    </>
  );
}

export default UrlOnBoardInfoForm;
