import Dropdown from "react-bootstrap/Dropdown";
import React, { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import "../StyleSheets/DropDown.css";
import { Link } from "react-router-dom";
// import { DropdownButton } from 'react-bootstrap'

function Dropdowns({ links, dropDownName, dropDownItemStyles }) {
  console.log(dropDownItemStyles);
  return (
    <>
      <DropdownButton
        id="dropdown-basic-button"
        variant="secondary"
        drop="end"
        title={dropDownName}
          
        style={{ padding: "0px" }}
        className="mt-2"
      >
        {links.map((link, i) => {
          return (
            <Dropdown.Item
              // href={link.link}
              className="dropDown__fontSize"
              // onClick={() => window.open(link.link, "_blank")}
              style={{
                textDecoration: "none",
                color: "#353535",
                padding: "5px",
                ...dropDownItemStyles,
              }}
            >
              {link.open_in_new_tab ? (
                <Link
                  onClick={() => window.open(link.link, "_blank")}
                  style={{ textDecoration: "none", color: "#353535" }}
                  to=""
                >
                  {" "}
                  {link.link_text}
                </Link>
              ) : (
                <Link
                  style={{ textDecoration: "none", color: "#353535" }}
                  to={link.link}
                >
                  {link.link_text}
                </Link>
              )}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </>
  );
}

export default Dropdowns;

// <option value="saab">Saab</option>
// <option value="mercedes">Mercedes</option>
// <option value="audi">Audi</option>

{
  /* <>



 <DropdownButton
        id="dropdown-basic-button"
        variant="secondary"
        title={
          dropDownName == "Efficiency"
            ? "Efficiency - Upcoming!!"
            : dropDownName
        }
        className="mt-2"
      >
       
      </DropdownButton>  
            
<Dropdown.Item
 style={{
      textDecoration: "none",
      fontSize: "20px",
      color: "#353535",
    }}
href={link.link} target="popup"


>
  {/* <Link
    onClick={handleClick}
    style={{
      textDecoration: "none",
      fontSize: "18px",
      color: "#353535",
    }}
    to={isOpen ? null : link.link}
    target={isOpen ? null : "_blank"}
    rel="noopener noreferrer"
  >
    {link.link_text}
  </Link> */
}
//   {link.link_text}
// </Dropdown.Item>
// </> */}
