import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import brand from "../../../Images/URL.png";
import brand1 from "../../../Images/Certificates.png";
import "../../../StyleSheets/Forensics.css";
import NavBar from "../../../Components/NavBar";
function Certificates() {
  return (
    <>
      <section>
        <NavBar />
        <div className="py-3 my-5">
        <Row className="m-5">
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{ width: "15 rem", height: "15 rem" }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5 style={{ fontSize: "20px" }}>SSL Certificate File Validation with Pensieve Integration</h5>
                    <p>
                      Check the expiry dates of certificate files in hosts, VMs, Kubernetes etc and view the expiries in Grafana Dashboards.
                    
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3"
                style={{
                
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>Validate a URL</h5> */}
                    <a style={{ textDecoration: "none" }} href="https://github.com/Maersk-Global/SRE-cert-check-script-telegraf" target="_blank">
                    Validation Script
                    </a>

        
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{
                  // width: "15rem",
                 
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <Card.Body className="text-center">
                  <Card.Text
                    style={{
                      display: "flex",
                      justifyContent: "center",
                     
                    }}
                  >
                    {/* <h5 style={{fontSize: "20px"}}>Certificate Validation Report</h5> */}
                    <a style={{ textDecoration: "none" }} href="https://grafana.maersk.io/d/rhoK5d5Vz/sre-model-dashboards-sre-certificate-expiry-model-dashboard?orgId=1&refresh=1m" target="_blank">
                     Dashboard
                    </a>
                   
                    {/* <a style={{textDecoration:"none"}} href="https://github.com/Maersk-Global/SRE-certificate-check-job" target="_blank">
                      Code
                    </a> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            
          </Row>
          <Row className="m-5">
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{ width: "15 rem", height: "15 rem" }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    <h5 style={{ fontSize: "20px" }}>SSL Certificate Validation for URLs</h5>
                    <p>
                      Validate the certificates of URLs for expiry date, issuer
                      and other attributes. Also, get a validation report for
                      already onboarded URLs.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{
                 
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>Validate a URL</h5> */}
                    <a style={{ textDecoration: "none" }} href="/urlsingle">
                      Validate a URL
                    </a>
                  

                  
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{
                 
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <Card.Body className="text-center">
                  <Card.Text
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <h5 style={{fontSize: "20px"}}>Certificate Validation Report</h5> */}
                    <a style={{ textDecoration: "none" }} href="/onBoarding">
                      Onboard Your URLS
                    </a>
                 
                    {/* <a style={{textDecoration:"none"}} href="https://github.com/Maersk-Global/SRE-certificate-check-job" target="_blank">
                      Code
                    </a> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-3">
              <Card
                className="shadow py-2 my-3  imgContainer"
                style={{
                 
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <Card.Body className="text-center">
                  <Card.Text>
                    {/* <h5 style={{fontSize: "20px"}}>Certificate Validation Report</h5> */}
                    <a
                      style={{ textDecoration: "none" }}
                      href="/URLBatchReport"
                    >
                      Certificate Validation Report
                    </a>
                   
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
         
        </div>
      </section>
    </>
  );
}

export default Certificates;
