import React from "react";
import NavBar from "../Components/NavBar";
import "../StyleSheets/Contribute.css";
import { Col, Container, Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { useRef } from "react";
function SRELibrary() {
  const reliability = useRef(null);
  const performance = useRef(null);
  const efficiency = useRef(null);
  const runbooks = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <>
      <section style={{ backgroundColor: "white" }}>
        <NavBar />
        {/* <Container className="py-3 my-5 fluid"> */}
        <div className="my-5 wrapper" style={{ width: "100%" }}>
          
          <div
            style={{ borderRight: "1px solid #EDEDED", top: "100px" }}
            className="sidebar"
          >
            <ul style={{ listStyleType: "none", padding: "15px 0 0 0" }}>
              <li
                className="side-bar-link"
                onClick={() => scrollToSection(reliability)}
              >
                Reliability
              </li>
              <li
                className="side-bar-link"
                onClick={() => scrollToSection(performance)}
              >
                Performance
              </li>
              <li
                className="side-bar-link"
                onClick={() => scrollToSection(efficiency)}
              >
                Efficiency
              </li>
              <li
                className="side-bar-link"
                onClick={() => scrollToSection(runbooks)}
              >
                RunBooks
              </li>
            </ul>
          </div>
         
          <div className="main" style={{ paddingLeft: "30px" }}>
            <h1
              style={{ textAlign: "left", marginBottom: 0, marginTop: "3rem" }}
              className="text-left"
            >
              <strong>Welcome to SRE Library</strong>
            </h1>
            <p style={{ color: "#00243D", fontSize: "18px" }} className="my-3">
              Here are various SRE runbooks, documents, coding & process
              patterns, tools and templates, for enhancing reliability and end
              user experience.
              <br />
              <br />
              <ul style={{ paddingInlineStart: "20px", marginBottom: "0" }}>
                <li>
                  <strong>Utilize</strong> the artefacts to achieve higher
                  reliability in your products and improve processes
                </li>
                <li>
                  <strong>Contribute</strong> to SRE tools and capabilities by
                  collaborating with us.
                  <br />
                  Capabilities, Patterns, and their Git Repos are{" "}
                  <strong>linked</strong> below.
                  <br />
                  Enhance our existing tools, develop capabilities jointly, or
                  integrate your team’s tools with SkyDeck
                </li>
              </ul>
              <br />
              Contact us for more information or discussion.
            </p>

            <table
              ref={reliability}
              class="my-3"
              style={{ width: "60%", backgroundColor: "#EDEDED" }}
            >
              <th style={{ width: "45%", fontSize: "18px", color: "#0073AB" }}>
                <strong>Reliability Capabilities</strong>
              </th>
              <th style={{ fontSize: "18px" }}>
                <strong>Reliability Patterns</strong>
              </th>
              <tbody>
                <tr>
                  <td>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-certificate-check-api"
                      target="_blank"
                    >
                      Certificate Check API
                    </a>
                    <br />
                    Status Page -{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-PE-Status-Page-UI.git"
                      target="_blank"
                    >
                      UI, <nbsp></nbsp>
                    </a>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-PE-Status-Page-Backend.git"
                      target="_blank"
                    >
                      Backend API
                    </a>
                  </td>
                  <td>
                    Resiliency Patterns
                    <br />
                    <a
                      style={{
                        textDecoration: "none",
                      }}
                      // className="link"
                      href="https://github.com/Maersk-Global/SRE-Pattern-resiliency"
                      target="_blank"
                    >
                      - Resiliency Patterns <nbsp></nbsp>
                    </a>
                    <br />
                    <a
                      style={{ textDecoration: "none" }}
                      // className="link"
                      href="https://github.com/Maersk-Global/SRE-Pattern-resiliency-graceful-degradation"
                      target="_blank"
                    >
                      - Graceful Degradation
                    </a>
                    <br />
                  
                    <a
                      style={{
                        textDecoration: "none",
                      }}
                      // className="link"
                      href="https://github.com/Maersk-Global/SRE-Pattern-monitoring-and-alerting"
                      target="_blank"
                    >
                      Monitoring and Alerting <nbsp></nbsp>
                    </a>
                    <br />
                    <a
                      style={{
                        textDecoration: "none",
                      }}
                      // className="link"
                      href="https://github.com/Maersk-Global/SRE-Pattern-operations-and-support"
                      target="_blank"
                    >
                      Operations and support <nbsp></nbsp>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              ref={performance}
              class="my-3"
              style={{ width: "60%", backgroundColor: "#EDEDED" }}
            >
              <th style={{ width: "45%", fontSize: "18px", color: "#0073AB" }}>
                <strong>Performance Capabilities</strong>
              </th>
              <th style={{ fontSize: "18px" }}>
                <strong>Performance Patterns</strong>
              </th>
              <tbody>
                <tr>
                  <td>
                    Database Forensics <br />
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-postgres-forensics-api"
                      target="_blank"
                    >
                      - Postgres Forensic API
                    </a>
                    <br />
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-sql-forensics-api"
                      target="_blank"
                    >
                      - SQL Forensic API
                    </a>
                  </td>
                  <td>
                    <a
                      style={{
                        textDecoration: "none",
                      }}
                      // className="link"
                      href="https://github.com/Maersk-Global/SRE-Pattern-resiliency"
                      target="_blank"
                    >
                      Performance and Scaling
                    </a>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>iPACE - </strong><nbsp></nbsp>
                    <p style={{fontSize:"14px", display:"inline"}}>
                       Load testing and Chaos suite
                    </p>
                    <br/>
                    <p style={{fontSize:"14px", marginBottom:"5px"}}>- Upcoming</p>
                    
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-Chaos-Library"
                      target="_blank"
                    >
                     Chaos Library
                    </a> <nbs></nbs>
                    <p style={{fontSize:"14px", display:"inline"}}>
                      - A Library of Chaos Tools & Documents
                    </p>
                    <br />
                    <p style={{fontSize:"14px"}}>- Some tools and documents are linked below.<br/> 
                     - For the full list refer to the Chaos Library linked above</p>
                    
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-Chaos-Library/blob/main/Azure-Chaos/Azure-webapp-multi-region.md"
                      target="_blank"
                    >
                      - Chaos Testing of Azure web-app
                    </a>
                    <br/>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-Chaos-host-load-util"
                      target="_blank"
                    >
                      - Load Generator for Host and VMs
                    </a>
                    <br/>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://github.com/Maersk-Global/SRE-Chaos-Library/tree/main/Chaos-Mesh"
                      target="_blank"
                    >
                      - Chaos Mesh
                    </a>
                  </td>
                  <td>
                    
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              ref={efficiency}
              class="my-3"
              style={{ width: "60%", backgroundColor: "#EDEDED" }}
            >
              <th style={{ width: "45%", fontSize: "18px", color: "#0073AB" }}>
                <strong>Efficiency Capabilities</strong>
              </th>
              <th style={{ fontSize: "18px" }}>
                <strong>Efficiency Patterns</strong>
              </th>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                  <a
                      style={{
                        textDecoration: "none",
                      }}
                      // className="link"
                      href="https://github.com/Maersk-Global/SRE-Pattern-automation"
                      target="_blank"
                    >
                      Automation Patterns <nbsp></nbsp>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ width: "100%" }}>
              <p style={{ fontSize: "18px" }} className="my-3">
                <strong>Runbooks and Tools</strong>
              </p>
              <table
                ref={runbooks}
                className="my-3"
                style={{ width: "60%", backgroundColor: "#EDEDED" }}
              >
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <a
                        className="link"
                        href="https://maersk-tools.atlassian.net/wiki/spaces/SPE/pages/181511716002/Application+Performance+Metrics+-+Questionnaire"
                      >
                        SRE Questionnaire to App Teams for performance
                        engagements
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        className="link"
                        href="https://teamsite.maerskgroup.com/:w:/r/teams/Taskforce-Incidentsrecovery/_layouts/15/Doc.aspx?sourcedoc=%7BC48AA4C2-59B1-4303-BF83-3A72AA2AEE22%7D&file=SQL%20Database%20Performance_Tasks_CheckList.docx&action=default&mobileredirect=true&cid=54f2bc9a-fbff-4cdb-9dc9-3e5056ac7f15"
                      >
                        SQL Server Performance Checklist
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a className="link" href="https://availability.sre.xyz/">
                        Availability Calculator
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* </Row> */}
        </div>
        {/* </Container> */}
      </section>
    </>
  );
}

export default SRELibrary;
