export const check = (yamlString, sloData, token, setLoading, setErrorQueryValidation, setTotalQueryValidation, setDisplayError, setDisplayErrorMessage) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("content", yamlString);
    urlencoded.append("filename", `${sloData.Application.value.replace(/ /g, "_").replace(/\(/g, "_").replace(/\)/g, "_").replace(/_$/, "")}` + ".yml");
    urlencoded.append("authToken", `Bearer ${token}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
      mode: "cors",
    };
    console.log(requestOptions);
    try {
      setLoading(true);
      return fetch(
        `${process.env.REACT_APP_SLOTH_API_VALIDATE_QUERY}`,

        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data.code);
          if (data.code != 200) {
            console.log("I am here");
            // console.log("Response ", response.body.JSON())
            console.log("Response ", data.error);
            setErrorQueryValidation(false);
            setTotalQueryValidation(false);
            setDisplayError(false);
            setDisplayErrorMessage("");
            if (
              data.error.includes("ErrorQuery") &&
              !data.error.includes("TotalQuery")
            ) {
              console.log("I am in 1");
              setDisplayError(true);
              setDisplayErrorMessage(data.error);
              setErrorQueryValidation(true);
              setTotalQueryValidation(false);
            } else if (
              data.error.includes("TotalQuery") &&
              !data.error.includes("ErrorQuery")
            ) {
              console.log("I am in 2");
              setDisplayError(true);
              setDisplayErrorMessage(data.error);
              setTotalQueryValidation(true);
              setErrorQueryValidation(false);
            } else {
              console.log("I am in 3");
              setDisplayError(true);
              setDisplayErrorMessage(data.error);
              setErrorQueryValidation(true);
              setTotalQueryValidation(true);
            }
            throw data;
          } else {
            setDisplayError(false);
            setDisplayErrorMessage("");
            setErrorQueryValidation(false);
            setTotalQueryValidation(false);
            console.log("Response ", data);
            return true;
          }
        })
        .catch((error) => {
          console.log("First Block Error: ", error);
          return false;
        });
    } catch (error) {
      console.log("Error : ", error);
      setErrorQueryValidation(true);
      setTotalQueryValidation(true);
      return false;
      // console.error("There was a problem:", error);
    }
}