import React from 'react';
import "./Styles.css";
import logo from "../../Assets/2.png";
import Button from '@mui/material/Button';

export default function Autotron() {

    return (
        <div className="ContentWrapper">
            <img className='image-logo' src={logo} alt="about" />
            <div className='innerContent'>

                <h4>Home of Reliable And Secure Automation</h4>
                <p >
                    {/* Empower and Unify:  */}
                    <strong>Secure and efficient automation</strong> for
                    <strong> installs, remediation, maintenance and troubleshooting </strong>
                    in Data Centers with enhanced <strong>performance and control.</strong>
                </p>
                <p className='noteText'>
                    <strong>Login to Autotron with a VDI because it is open to only Maersknet/DC-Network
                    </strong>
                </p>
                <p style={{ marginLeft: "-4%", marginTop: "1%", fontSize: "14px", wordSpacing: "5px", fontWeight: "bold" }}>
                    Access Autotron here <strong style={{ color: "#878787", marginLeft: "5px", cursor: "pointers" }}>{`${process.env.REACT_APP_AUTOTRON}`} </strong>
                    {/* <a href="https://autotron.crb.apmoller.net" target="_blank" style={{ color: "#878787", marginLeft: "5px", cursor:"pointers" }} rel="noreferrer">https://autotron.crb.apmoller.net</a> */}
                </p>

            </div>
        </div>
    )
}
