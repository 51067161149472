import { validateInputs } from "./validateInputs";

export const submit = (event, navigate, setSloData, serviceName, productId, headwigScope, OrgId, env,
  setMacValues, selectedFormDetails,
  cachedFormDetails
  
  ) => {
 //Preventing the default behavior of the form, i.e. don't reload the page
 event.preventDefault();

//  if(!selectedFormDetails?.Portfolio.label){
//   navigate(null, 1);
//   return 
//  }

 //if any of these fields are empty then don't submit the form



 if (validateInputs(selectedFormDetails)){
   alert("Please fill all fields before moving to next tab");
   return;
 }

 console.log("selectedFormDetails",selectedFormDetails.hedwigScope)

 //initializing productData variable with current input values
 const productData = {
   serviceName: serviceName,
   productId: productId,
   orgId: OrgId,
   environment: env,
   Portfolio: selectedFormDetails.Portfolio,
    Product: selectedFormDetails.Product,
    Platform: selectedFormDetails.Platform,
    Application: selectedFormDetails.Application,
    AppID: selectedFormDetails.AppID,
    macDropDownValues: cachedFormDetails,
    Environment: selectedFormDetails.Environment,
    hedwigScope: selectedFormDetails.hedwigScope
   
 };

 //setting the current state product info to context level state  
 setSloData({ ...productData });

 //switching to next tab, i.e. sloInfo form
 navigate(null, 1);
}