import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CardGenerator.css"

function CardGenerator({ cardText, inlineStyle, linkText, link }) {
  return (
    <>
      <Card style={inlineStyle} className="shadow py-2 my-3 card-width-md">
        <Card.Body className="text-center">
          <Card.Text className={link && "link-center"}>
            {link ? (
              <Link to={link}
                className="font-20px"
                style={{ textDecoration: "none" }}
                href={`${link}`}
              >
                {linkText}
              </Link>
            ) : (
              <h6 className="font-20px">{cardText}</h6>
            )}

            {/* </a> */}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default CardGenerator;
