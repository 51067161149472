//UI design for capability page

//Each parent javascript object is a capability Eg: Reliability
//tag_line -> Object that contains short description of Capability and styles are required for that description
//styles -> Object that will define styles for the tag_line
//style: -> Object that contains styles for the div that contains the capability
//dropDownStyles -> Object that contains styles for the dropDown container
//dropDownItemStyle -> Object that contains styles for each dropDown Item.

//Pictorical Representation of each capability container
// |-------------------------------------------------|                                                                                                  |                                                  
// |                Capability Name                  |
// |-------------------------------------------------|                                                 
// |                                                 |
// |                   Tag Line                      |
// |-------------------------------------------------|                                                 
// |                                                 |
// |                                                 |
// |               Drop Down Container               |
// |                                                 |
// |                                                 |
// |                                                 |
// |-------------------------------------------------|




var UI_CONF = [
  {
    name: "Reliablity",
    tag_line: {
      line: "Self-Service and Open-Source Capabilities for Reliability of your systems",
      styles: {
        backgroundColor: "#42B0D5",
        width:"100%",
        color:"#003D6D"
        
      },
    },
    style: { backgroundColor: "#005487",  },
    dropDownStyles:{backgroundColor:"#77C6E0", padding:"20px", width:"100%", display:"flex", alignItems: "center", justifyContent: "center"},
    dropDownItemStyle:{backgroundColor:"#B5E0F5",  borderRadius: "10px !important"},
    links: [
      {
        link: "/certificates",
        link_text: "Certificate Validation",
        open_in_new_tab: false,
      },
      {
        link: "/slodashboarding",
        link_text: "SLO As a Service",
        open_in_new_tab: false,
      },
      {
        link: `${process.env.REACT_APP_STATUS_PAGE}`,
        link_text: "Status Page",
        open_in_new_tab: true,
      },
      {
        link: `${process.env.REACT_APP_SRE_SKYDECK_LIBRARY_SITE}`,
        link_text: "Runbooks and Templates",
        open_in_new_tab: true,
      },
    ],
  },
  {
    name: "Performance",
    tag_line: {
      line: "Tools to assess and improve Performance of your applications and components",
      styles: {
        backgroundColor: "#FFB302",
        width:"100%",
        color:"#003D6D"
      },
    },
    style:{"backgroundColor": "#F07508"},
    dropDownStyles:{backgroundColor:"#FFC319", padding:"20px", width:"100%", display:"flex", alignItems: "center", justifyContent: "center"},
    dropDownItemStyle:{backgroundColor:"#FFDE74", borderRadius: "10px !important"},
    links: [
      {
        link: "/forensics",
        link_text: "Forensics",
        open_in_new_tab: false,
      },
      {
        link: `${process.env.REACT_APP_LOADSTAR}`,
        link_text: "iPACE",
        open_in_new_tab: true,
      },
    ],
  },
  {
    name: "Efficiency - Upcoming!",

    tag_line: {
      line: "View and improve Efficiency of your application and product landscape",
      styles: {
        backgroundColor: "#4DB5AB",
        width:"100%",
        color:"#003D6D"
      },
    },

    style: { backgroundColor: "#00897A" },
    dropDownStyles:{backgroundColor:"#4CC6BC", padding:"20px", width:"100%", display:"flex", alignItems: "center", justifyContent: "center"},
    dropDownItemStyle:{backgroundColor:"#AAEAE0", borderRadius: "10px !important"},
    links: [
      {
        link: "/capability#",
        link_text: "Watch this Space!!",
        open_in_new_tab: false,
      },
    ],
  },
];

export default UI_CONF;
