import React, { memo, useEffect } from "react";
import "../../../../../../StyleSheets/SlothProductForm.css";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useProductInfoForm, useValidation } from "./Logic";
import UseSloInfo from "../../SlothContext";
import {
  Input,
  ReactSelect,
} from "@anchor/react-components/dist/lib/components";
import { validateInputs } from "./functions/validateInputs";
import { log } from "async";

function ProductForm({ navigate }) {
  const {
    serviceName,
    productId,
    headwigScope,
    OrgId,
    env,
    submitProductInfo,
    setServiceName,
    setProductId,
    setHeadwigScope,
    selectThisEnv,
    selectThisOrgId,
    slothContextData,
    selectedFormDetails,
    setSelectedFormDetails,
    cachedFormDetails,
    setCachedFormDetails,
    handleDropDownClick,
    isMac,
    isLoading,
    clearDropDowns,
    inValidValue,
    getHedwigScopes,
  } = { ...useProductInfoForm() };

  // const {
  //   selectedFormDetails,
  //   setSelectedFormDetails,
  //   cachedFormDetails,
  //   setCachedFormDetails,
  //   handleDropDownClick,
  //   isMac,
  //   isLoading,
  //   clearDropDowns,
  // } = useServiceController();

  const { updating } = { ...UseSloInfo() };

  useEffect(() => {
    slothContextData();
  }, []);

  console.log("SELECTED FORM DETAILS ", selectedFormDetails)
  const { isValidProductId, isValidServiceName, isValidHeadwigScope } =
    useValidation(
      { serviceName, productId, headwigScope },
      { pattern: /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/ }
    );

  return (
    <>
      <form className="productForm">
        {/* <div className="productForm__inputContainer">
          <label className="productForm__label">
            What is the service name of your product?
          </label>
          <div style={{ width: "100%", display: "flex" }}>
            <input
              value={serviceName}
              className="productForm__input"
              onChange={(e) => setServiceName(e.target.value)}
              required
              placeholder="eg: myservice"
              disabled={updating}
            ></input>
            {serviceName ? (
              isValidServiceName ? (
                <div style={{ padding: "10px" }}>
                  <DoneIcon style={{ color: "green", fontSize: "20px" }} />
                </div>
              ) : (
                <div style={{ padding: "10px" }}>
                  <CloseIcon style={{ color: "red", fontSize: "20px" }} />
                </div>
              )
            ) : (
              ""
            )}
          </div>

          <div style={{ textAlign: "left", width: "100%" }}>
            {" "}
            <InfoSharpIcon style={{ fontSize: "15px", color: "#058EC6" }} />
            <p className="sloth__productFormInfo">
              Please mention your service name without spaces
            </p>
          </div>
        </div>

        <div className="productForm__inputContainer">
          <label className="productForm__label">What is the Product ID?</label>
          <div style={{ width: "100%", display: "flex" }}>
            <input
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              required
              placeholder="eg: prod_id"
              className="productForm__input"
              disabled={updating}
            ></input>
            {productId ? (
              isValidProductId ? (
                <div style={{ padding: "10px" }}>
                  <DoneIcon style={{ color: "green", fontSize: "20px" }} />
                </div>
              ) : (
                <div style={{ padding: "10px" }}>
                  <CloseIcon style={{ color: "red", fontSize: "20px" }} />
                </div>
              )
            ) : (
              ""
            )}
          </div>
          <div style={{ textAlign: "left", width: "100%", fontSize: "10px" }}>
            {" "}
            <InfoSharpIcon style={{ fontSize: "15px", color: "#058EC6" }} />
            <p className="sloth__productFormInfo">
              The product code within Admiral where this application is part of
            </p>
          </div>
        </div>

        <div className="productForm__inputContainer">
          <label className="productForm__label">
            Which organization does your product belongs to?
          </label>
          <div className="choiceDiv__container">
            <div
              disabled={updating}
              onClick={() =>
                !updating && selectThisOrgId("APMT IOT and Automation")
              }
              className={
                OrgId === "APMT IOT and Automation"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              APMT IOT and Automation
            </div>
            <div
              disabled={updating}
              onClick={() =>
                !updating && selectThisOrgId("Business Enabling Platform")
              }
              className={
                OrgId === "Business Enabling Platform"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Business Enabling Platform
            </div>

            <div
              disabled={updating}
              onClick={() => !updating && selectThisOrgId("Corporate Platform")}
              className={
                OrgId === "Corporate Platform"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Corporate Platform
            </div>
            <div
              disabled={updating}
              onClick={() =>
                !updating &&
                selectThisOrgId("Cyber Security and Security Operations")
              }
              className={
                OrgId === "Cyber Security and Security Operations"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Cyber Security and Security Operations
            </div>
            <div
              disabled={updating}
              onClick={() =>
                !updating && selectThisOrgId("Technology Services Engineering")
              }
              className={
                OrgId === "Technology Services Engineering"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Technology Services Engineering
            </div>
            <div
              onClick={() =>
                !updating && selectThisOrgId("Global Data Analytics")
              }
              className={
                OrgId === "Global Data Analytics"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Global Data Analytics
            </div>
            <div
              onClick={() =>
                !updating && selectThisOrgId("Transported By Maersk")
              }
              className={
                OrgId === "Transported By Maersk"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Transported By Maersk
            </div>
            <div
              onClick={() =>
                !updating && selectThisOrgId("Fulfilled By Maersk")
              }
              className={
                OrgId === "Fulfilled By Maersk"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Fulfilled By Maersk
            </div>

            <div
              onClick={() => !updating && selectThisOrgId("Managed By Maersk")}
              className={
                OrgId === "Managed By Maersk"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              Managed By Maersk
            </div>
          </div>
        </div> */}

        {/* <div className="productForm__inputContainer">
          <label className="productForm__label">
            Which environment does the SLO belongs to?
          </label>
          <div className="choiceDiv__container">
            <div
              onClick={() => !updating && selectThisEnv("DEV")}
              className={
                env === "DEV"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              DEV
            </div>

            <div
              onClick={() => !updating && selectThisEnv("PROD")}
              className={
                env === "PROD"
                  ? "productForm__ChoiceDivSelected"
                  : "productForm__ChoiceDivUnselected"
              }
            >
              PROD
            </div>
          </div>
        </div>
        <div className="productForm__inputContainer">
          <label className="productForm__label">
            <span>
              What is the name of hedwig scope you want SLO alerts to be
              routed?(optional)
            </span>
          </label>
          <div style={{ width: "100%", display: "flex" }}>
            <input
              className="productForm__input"
              onChange={(e) => setHeadwigScope(e.target.value)}
              value={headwigScope}
              // required
              // placeholder="coming soon"
              // disabled="true"
            ></input>
            {headwigScope ? (
              isValidHeadwigScope ? (
                <div style={{ padding: "10px" }}>
                  <DoneIcon style={{ color: "green", fontSize: "20px" }} />
                </div>
              ) : (
                <div style={{ padding: "10px" }}>
                  <CloseIcon style={{ color: "red", fontSize: "20px" }} />
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div> */}

        <div className="rowDesign" style={{ marginTop: "20px" }}>
          <div className="rowCell">
            <Input
              fit="medium"
              id="textInput"
              label="Application ID"
              width={100}
              loading={isLoading.AppID}
              hint={isLoading.AppID ? "Please wait for application data" : null}
              onChange={(evt) =>
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  AppID: evt.target.value,
                }))
              }
              placeholder="Application ID"
              type="text"
              value={selectedFormDetails.AppID}
              variant="default"
              error={inValidValue.AppID}
              errorMessage={
                inValidValue.AppID ? "Invalid Application ID" : null
              }
              disabled={updating}
            />
          </div>
          <div className="rowCell">
            <ReactSelect
              className="react-select"
              closeMenuOnSelect
              fit="medium"
              width={100}
              hintPosition="left"
              id="selectCountry"
              isClearable
              label="Portfolio Name"
              name="PortfolioName"
              isLoading={isLoading.Portfolio}
              onChange={(evt) => {
                console.log("evt", evt);
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  Portfolio: evt ? evt.value : "",
                }));
                clearDropDowns("Portfolio");
                handleDropDownClick(evt?.id, "Platform");
              }}
              options={
                cachedFormDetails?.Portfolio ?
                cachedFormDetails.Portfolio.map((portfolio) => {
                  return {
                    id: portfolio.portfolioId,
                    label: portfolio.portfolioName,
                    value: {
                      id: portfolio.portfolioId,
                      label: portfolio.portfolioName,
                      value: portfolio.portfolioName,
                    },
                  };
                }) : []
              }
              disabled={updating}
              orientation="vertical"
              placeholder="Select Portfolio"
              value={selectedFormDetails.Portfolio}
              variant="default"
            />
          </div>
        </div>

        <div className="rowDesign">
          <div className="rowCell">
            <ReactSelect
              className="react-select"
              closeMenuOnSelect
              fit="medium"
              width={100}
              hintPosition="left"
              id="selectCountry"
              isClearable
              label="Platform Name"
              name="PlatformName"
              isLoading={isLoading.Platform}
              onChange={(evt) => {
                console.log("evt", evt);
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  Platform: evt ? evt.value : "",
                }));
                clearDropDowns("Platform");
                handleDropDownClick(evt?.id, "Product");
              }}
              options={
                cachedFormDetails?.Platform &&
                cachedFormDetails.Platform.map((platform) => {
                  return {
                    id: platform.platformId,
                    label: platform.platformName,
                    value: {
                      id: platform.platformId,
                      label: platform.platformName,
                      value: platform.platformName,
                    },
                  };
                })
              }
              disabled={updating}
              orientation="vertical"
              placeholder="Select Platform"
              value={selectedFormDetails.Platform}
              variant="default"
            />
          </div>
          <div className="rowCell">
            <ReactSelect
              className="react-select"
              closeMenuOnSelect
              fit="medium"
              width={100}
              hintPosition="left"
              id="selectCountry"
              isClearable
              label="Product Name"
              name="ProductName"
              isLoading={isLoading.Product}
              onChange={(evt) => {
                console.log("evt", evt);
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  Product: evt ? evt.value : "",
                }));
                clearDropDowns("Product");
                handleDropDownClick(evt?.id, "Application");
              }}
              options={
                cachedFormDetails?.Product &&
                cachedFormDetails.Product.map((product) => {
                  return {
                    id: product.productId,
                    label: product.productName,
                    value: {
                      id: product.productId,
                      label: product.productName,
                      value: product.productName,
                    },
                  };
                })
              }
              disabled={updating}
              orientation="vertical"
              placeholder="Select Platform"
              value={selectedFormDetails.Product}
              variant="default"
            />
          </div>
        </div>

        <div className="rowDesign">
          <div
            className={
              isMac && selectedFormDetails.Application === ""
                ? "ifNotInMac"
                : "rowCell"
            }
          >
            <ReactSelect
              className="react-select"
              closeMenuOnSelect
              fit="medium"
              width={100}
              hintPosition="left"
              id="selectCountry"
              isClearable
              label="Application Name"
              name="ApplicationName"
              isLoading={isLoading.Application}
              onChange={(evt) => {
                console.log("evt", evt);
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  Application: evt ? evt.value : "",
                }));
                clearDropDowns("Application");
                handleDropDownClick(evt?.id, "");
              }}
              options={
                cachedFormDetails?.Application &&
                cachedFormDetails.Application.map((application) => {
                  return {
                    id: application.applicationId,
                    label: application.applicationName,
                    value: {
                      id: application.applicationId,
                      label: application.applicationName,
                      value: application.applicationName,
                    },
                  };
                })
              }
              disabled={updating}
              orientation="vertical"
              placeholder="Select Application"
              value={selectedFormDetails.Application}
              variant="default"
            />
          </div>
          {console.log("selectedFormDetails @525", selectedFormDetails)}
          <div className="rowCell">
            <ReactSelect
              className="react-select"
              closeMenuOnSelect
              fit="medium"
              width={100}
              hintPosition="left"
              id="selectCountry"
              isClearable
              label="Environment"
              name="Environment"
              onChange={(evt) => {
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  Environment: evt?.value 
                }));
            
              }}
              options={[
                {
                  id: "dev",
                  label: "DEV",
                  value: {
                    id: "dev",
                    label: "Dev",
                    value: "dev",
                  },
                },
                {
                  id: "prod",
                  label: "PROD",
                  value: {
                    id: "prod",
                    label: "Prod",
                    value: "prod",
                  },
                },
              ]}
              disabled={updating}
              orientation="vertical"
              placeholder="Select Environment for SLO"
              value={selectedFormDetails.Environment}
              variant="default"
            />
          </div>
        </div>

        <div className="rowDesign">
          <div style={{ width: "100%" }}>
            <ReactSelect
              className="react-select"
              closeMenuOnSelect
              customNoOptionsMessage={<p>Not a valid hedwig scope</p>}
              error={inValidValue.hedwigScope}
              errorMessage="Invalid hedwig scope"
              isLoading={isLoading.hedwigScope}
              fit="medium"
              hint={
                inValidValue.hedwigScope
                  ? null
                  : "Type to search your hedwig scope"
              }
              hintPosition="left"
              id="hedwigScope"
              isClearable
              isSearchable
              label="Hedwig Scope"
              name="hedwigScope"
              onChange={(evt) => {
                console.log("hedwingScope clicked", evt);
                setSelectedFormDetails((prevState) => ({
                  ...prevState,
                  hedwigScope: evt ? evt.value : "",
                }));
              }}
              onInputChange={(evt) => {
                console.log("hedwingScope", evt);
                // setSelectedFormDetails((prevState) => ({
                //   ...prevState,
                //   hedwigScope: evt ? evt : "",
                // }));
                getHedwigScopes(evt);
              }}
              options={
                cachedFormDetails?.hedwigScope &&
                cachedFormDetails.hedwigScope.map((hedwigScope) => {
                  return {
                    id: hedwigScope.id,
                    label: hedwigScope.name,
                    value: {
                      id: hedwigScope.id,
                      label: hedwigScope.name,
                      value: hedwigScope.name,
                    },
                  };
                })
              }
              disabled={updating}
              orientation="vertical"
              placeholder="What is the name of hedwig scope you want SLO alerts to be
            routed?"
              // required
              suggestType="static"
              // tooltipDescription="Hello"
              value={selectedFormDetails.hedwigScope}
              variant="default"
              width={100}
            />
          </div>

          {/* <Input
            fit="medium"
            id="textInput"
            label="hedwig scope"
            onChange={(evt) =>
              setSelectedFormDetails((prevState) => ({
                ...prevState,
                hedwigScope: evt.target.value,
              }))
            }
            width={100}
            placeholder="What is the name of hedwig scope you want SLO alerts to be
            routed?(optional)"
            type="text"
            value={selectedFormDetails.hedwigScope}
            variant="default"
          /> */}
        </div>

        <div className="productForm__submitButtonContainer">
          <button
            type="submit"
            value="Next"
            className="productForm__submitButton"
            onClick={(e) => submitProductInfo(e, navigate)}
            // disabled={
            //   selectedFormDetails?.Portfolio?.label?.length === 0 ||
            //   selectedFormDetails?.Platform?.label?.length === 0 ||
            //   selectedFormDetails?.Product?.label?.length === 0 ||
            //   selectedFormDetails?.Application?.label?.length === 0 ||
            //   !selectedFormDetails?.Environment ||
            //   !selectedFormDetails?.hedwigScope
            // }
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
}

export default memo(ProductForm);
