export const urlsArevalid = (urls, opp) => {
  let validUrls = urls
  switch (opp) {
    case "Insert":
      for (var i = 0; i< urls.length; i++) {
        validUrls[i] = validUrls[i].trim()

        // let url = new URL(urls[i])
        // console.log(new URL(urls[i]).port)
        // if(url.port){
        //   return {valid : false, urlNo: i+1}
        // }
       
        if(validUrls[i].indexOf("ftp://") !== -1  || validUrls[i].indexOf("tcp://") !== -1){
          return {valid: false, urlNo: i+1}
        }
        validUrls[i] =  validUrls[i].replace(/(^\w+:|^)\/\//, '');

        //Checking if port no string exist in url example :443 or :anynumber
        let removePortRegEx = /:\d+/
        if(validUrls[i].match(removePortRegEx)){
          return {valid: false, urlNo: i+1}
        }
      
        if(validUrls[i].length <= 4){
          return {valid : false, urlNo: i+1}
        }
      }
      break;
    case "Update":
      for (var i = 0; i< urls.length; i++) {
        validUrls[i].url = validUrls[i].url.trim()

        if(validUrls[i].url.indexOf("ftp://") !== -1  || validUrls[i].url.indexOf("tcp://") !== -1){
          return {valid: false, urlNo: i+1}
        }
   
      

        validUrls[i].url =  validUrls[i].url.replace(/(^\w+:|^)\/\//, '');

        //Checking if port no string exist in url example :443 or :anynumber
        let removePortRegEx = /:\d+/
        if(validUrls[i].url.match(removePortRegEx)){
          return {valid: false, urlNo: i+1}
        }
        if(validUrls[i].length <= 4){
          return {valid : false, urlNo: i+1}
        }
   
      }
      break;  
      default:
        break;
  }
  
  
    // if(validUrls[i].indexOf("https://") != 0 && validUrls[i].indexOf("http://") != 0 && validUrls[i].indexOf("www.") != 0 ){
    //     return {valid : false, urlNo: i+1}
    // }
  
  
 return {valid : true}
}

