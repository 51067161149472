
import React from "react";
import Loading from "../../../Components/Loading";


function UrlSingleReport({url, apiData, isLoading}) {
 

  return isLoading ?  
  (
    <div style={{marginTop:"-44vh"}}><Loading/></div> 
    ) 
     : (
        <div className="px-5 m-2" style={{ width: "100%" }}>
          <h2 style={{ marginTop: "100px", textAlign: "center" }}>
            Certificate Details for <br />
            {apiData.HostName}
          </h2>
          <table
            style={{ backgroundColor: "#fff" }}
            className="table table-striped"
          >
            <thead>
              <tr>
                <th>Url</th>
                <th>Host Name</th>
                <th>Issuer</th>
                <th>Expiry</th>
                <th>Reachable</th>
                <th>SslCheck</th>
                <th>HostNameCheck</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{apiData.Url}</td>
                <td>{apiData.HostName}</td>
                <td>{apiData.Issuer}</td>
                <td>{apiData.Expiry}</td>
                <td>{apiData.Reachable ? "Yes" : "No"}</td>
                <td>{apiData.SslCheck ? "Success" : "Failure"}</td>
                <td>{apiData.HostNameCheck ? "Success" : "Failure"}</td>
                <td>{apiData.Error}</td>
              </tr>
            </tbody>
          </table>
        </div>
    )
}

export default UrlSingleReport;
