import React from "react";
import { Col, Row } from "react-bootstrap";
import NavBar from "../Components/NavBar";
import ".././StyleSheets/OnBoarding.css";
import CardGenerator from "../Components/CardGenerator/CardGenerator";

function OnBoarding() {
  return (
    <>
      <NavBar />
   
      <div className="py-3 my-5 container-fluid">
      <h1 className="margin-top-40px text-left"style={{textAlign:"left", marginLeft:"95px"}} >Onboard your Components to SkyDeck</h1>
      <Row className="m-5">
          <Col className="col-3 onboardingContainer-col1">
            <CardGenerator
              inlineStyle={{ backgroundColor: "#EDEDED", border: "none", width:"20rem", fontSize:"" }}
              styleclass="shadow py-2 my-3"
              cardText="Team and Application"
            />
          </Col>
        {/* </Row> */}
        
          <Col className="col-3">
            <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"20rem" }}
              styleclass="shadow py-2 my-3"
              link="/team"
              linkText="Team"
            />
           
          </Col>
          <Col className="col-3">
            <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"20rem" }}
              styleclass="shadow py-2 my-3"
              link="/platform"
              linkText="Platform"
            />
           
          </Col>
          <Col className="col-3">
            <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"20rem" }}
              styleclass="shadow py-2 my-3"
              link="/ci"
              linkText="Configuration Item"
            />
          </Col>
          {/* <Col className="col-2">
            <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"12rem" }}
              styleclass="shadow py-2 my-3"
              link="/cert_url"
              linkText="URLs for Certificate Check"
            />
          </Col> */
          //==========================================================================================
          <Row className="">
          <Col className="col-3 onboardingContainer-col1">
            <CardGenerator
              inlineStyle={{ backgroundColor: "#EDEDED", border: "none", width:"20rem" }}
              styleclass="shadow py-2 my-3"
              cardText="Components"
            />
          </Col>
        {/* </Row> */}
        
       
          <Col className="col-3">
             <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"20rem" }}
              styleclass="shadow py-2 my-3"
              link="/cert_url"
              linkText="URLs for Certificate Check"
            />
          </Col>
          {/* <Col className="col-2">
            <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"15rem" }}
              styleclass="shadow py-2 my-3"
              link="/ci"
              linkText="CI"
            />
          </Col> */}
          {/* <Col className="col-2">
            <CardGenerator
              inlineStyle={{ backgroundColor: "white", border: "none", width:"12rem" }}
              styleclass="shadow py-2 my-3"
              link="/cert_url"
              linkText="URLs for Certificate Check"
            />
          </Col> */}
        </Row>}
        </Row>
      </div>
    </>
  );
}

export default OnBoarding;
