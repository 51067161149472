export const populate = (slos, setSlosContextArray, setSlos,setNoOfOldSlos,



) => {
    let tempArray = [];
    let defaultSloExists = false
    slos.forEach((slo) => {
      let data = {
        sloName: slo.name,
        sloDescription: slo.description,
        category: slo.alerting.labels.category,
        objective: slo.objective,
        errorQuery: slo.sli.events.error_query,
        totalQuery: slo.sli.events.total_query,
        alertName: slo.alerting.name,
        alertAnnotations: slo.alerting.annotations.summary,
        newSlo: false,
      };

      tempArray.push(data);
  
      if (slo.name.includes("Operations_Excellence_Availability")) {
        defaultSloExists = true
     
      }
    });
    // console.log("SLOS ",slos.length)
    console.log("Default Slo Exists",defaultSloExists)
    // if(defaultSloExists){
    //   setDefaultSlo(false);
    //   setSloName("");
    //   setSloDescription("");
    //   setCategory("");
    //   setObjective("");
    
    // }else{
    //   setDefaultSlo(true);
    //   setSloName("Operations_Excellence_Availability");
    //   setSloDescription("Operations Excellence Availability");
    //   setCategory("Availability");
    //   setObjective(97.10);
    // }
    setNoOfOldSlos(slos.length)
    setSlosContextArray(() => [...tempArray]);
    setSlos(() => [...tempArray]);
    
}