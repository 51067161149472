import React from "react";

import axios from "axios";


// const RequestInterceptorContext = createContext({});
const RequestInterceptor = ({ children }) => {


  /* eslint-disable no-param-reassign */

  axios.interceptors.request.use(async (config) => {
    const idToken = `Bearer ${sessionStorage.getItem("idToken")}`;
    // setToken(response.idToken);
    config.headers.Authorization = idToken;

    // config.headers["Access-Control-Allow-Origin"] = "*"
    config.headers["consumer-key"] = process.env.REACT_APP_CONSUMER_KEY;
    return config;
    // console.log("Form Request Interceptor,", config)
    // if (!account) {
    //   throw Error(
    //     "DEBUG - RequestInterceptor.jsx: No active account! Verify a user has been signed in."
    //   );
    // }
    // console.log("ACCOUNT ", account[0])
    // let response;
    // try {
    //   // response = await instance.acquireTokenSilent({
    //   //   ...loginRequest,
    //   //   account,
    //   // });

    //   return config;
    // } catch (error) {
    //   console.log("ERROR IN REQUIEST INTERCEPTOR ", error)
    //   if (error instanceof InteractionRequiredAuthError) {
    //     console.log(
    //       "DEBUG - RequestInterceptor.jsx: got InteractionRequiredAuthError, retrying acquiring token"
    //     );
    //     instance
    //       .acquireTokenRedirect({ ...loginRequest, account: accounts[0] })
    //       .then(function (response) {
    //         // Acquire token interactive success
    //         console.log(
    //           "DEBUG - RequestInterceptor.jsx: token acquired successfully"
    //         );
    //       })
    //       .catch(function (error) {
    //         console.log("Error ", error)
    //       });

    //     const idToken = `Bearer ${response.idToken}`;
    //     config.headers.Authorization = idToken;
    //     config.headers["consumer-key"] = process.env.REACT_APP_CONSUMER_KEY;
    //     // setToken(response.idToken);
    //     return config;
    //   }
    // }
  });

  return <>{children}</>;
};
export default RequestInterceptor;
