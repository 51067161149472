import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
// import NavBar from "../../Components/NavBar";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { platformNameAlreadyExist } from "../util/checkIfAlreadyExist";

function PlatformForm() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamNames, setTeamNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [platformName, setPlatformName] = useState("");
  const [oldPlatformName, setOldPlatformName] = useState("");
  const [platformNames, setPlatformNames] = useState([]);
  const [platformId, setplatformId] = useState("");
  const [opp, setOpp] = useState("Insert");
  const [gettingPlatformNames, setGettingPlatformNames] = useState(false);
  const [gettingPlatformData, setGettingPlatformData] = useState(false);

  useEffect(() => {
    fetchTeamNames();
  }, []);
  const fetchTeamNames = async () => {
    try {
      let names = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamNames`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      names = names.data.data;
      if (names.error) {
        throw new Error(names.message);
      } else {
        setTeamNames(names.team_names);
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    setTeamName("");
    setPlatformName("");
    setName("");
    setDescription("");
    if (opp === "Update" || opp === "Delete") {
      if (opp !== "") {
        fetchPlatformNames();
      }
    }
  }, [opp]);

  const fetchPlatformNames = async () => {
    try {
      setGettingPlatformNames(true);
      let pnames = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getPlatformNames`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      pnames = pnames.data.data;
      if (pnames.error) {
        throw new Error(pnames.message);
      } else {
        setGettingPlatformNames(false);
        setPlatformNames(pnames.platform_names);
      }
    } catch (err) {
      setGettingPlatformNames(false);
      alert(err);
    }
  };

  useEffect(() => {
    if (!platformName) {
      return;
    }
    setTeamName("");
    setName("");
    setDescription("");
    fetchPlatformData();
  }, [platformName]);

  const fetchPlatformData = async () => {
    let encodePName = encodeURIComponent(platformName);
    try {
      setGettingPlatformData(true);
      let platformData = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getPlatformData?name=${encodePName}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      platformData = platformData.data.data;
      if (platformData.error) {
        throw new Error(platformData.message);
      } else {
        setplatformId(platformData.data[0].id);
        setName(platformData.data[0].name);
        setOldPlatformName(platformData.data[0].name);
        setDescription(platformData.data[0].description);

        let teamData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamData?id=${platformData.data[0].team_id}`,
          {
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        teamData = teamData.data.data;
        setTeamName(teamData.data[0].name);
        setGettingPlatformData(false);
      }
    } catch (err) {
      setGettingPlatformData(false);
      alert(
        "Unable to get  Platform data from server, please refresh the page"
      );
    }
  };

  const formInputFieldData = [
    {
      name: "Name",
      type: "text",
      required: true,
      isTextArea: false,
      onChange: "setName",
      value: name,
      disabled: false,
    },
    {
      name: "Team Name",
      type: "select",
      required: true,
      isTextArea: false,
      onChange: "setTeamName",
      value: teamName,
      disabled: false,
    },
    {
      name: "Description",
      type: "text",
      required: true,
      isTextArea: true,
      onChange: "setDescription",
      value: description,
      disabled: false,
    },
  ];
  const handleOppChange = (e) => {
    setOpp(e.target.value);
  };
  const handlePlatformNameUptDel = (e) => {
    setPlatformName(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    e.preventDefault();
    window.scrollTo(0, 0);

    if (opp === "Delete") {
      if (
        !window.confirm(
          "Do you really want to delete your Platform?\nAll CIs and URLs associated with this platform will be deleted"
        )
      ) {
        return;
      }
    }
    setLoading(true);
    if (opp !== "Delete" && opp !== "Update") {
      if (await platformNameAlreadyExist(name)) {
        alert("Platform Name already exists\nPress ok to continue");
        setLoading(false);
        setName("");
        return;
      }
    }

    //=======================================================================================================================================
    //Insertion
    if (opp === "Insert") {
      let teamidData = "";
      try {
        let encodeTName = encodeURIComponent(teamName);
        teamidData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${encodeTName}`,
          {
            method: "GET",

            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamidData = teamidData.data.data;
        if (teamidData.error) {
          if (teamidData.status === 401) {
            throw new Error(teamidData.message);
          }

          throw new Error(
            "An internal error has occured\nPossible Reasons:\n1: Server is down\n2: Network error\nPress ok to continue"
          );
        } else {
          let teamID = teamidData.data.id;
          let desc = description.trim();
          let na = name.trim();
          let body = { teamID, desc, na };
          const response = await axios.post(
            `${process.env.REACT_APP_ONBOARDER_API}/addinplatform`,
            {
              mode: "cors",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              ...body,
            }
          );
          let responseData = response.data.data;
          // console.log(responseData);
          if (responseData.error) {
            if (responseData.status === 401) {
              throw new Error(responseData.message);
            }
            throw new Error(
              "Unable to onboard your platform\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
            );
          } else {
            setLoading(false);
            setDescription("");
            setName("");
            setTeamName("");
            alert(
              "Your platform has been successfully onboarded!!\nPress ok to continue"
            );
          }
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }

    //=======================================================================================================================================
    //Updation
    else if (opp === "Update") {
      if (oldPlatformName !== name) {
        if (await platformNameAlreadyExist(name)) {
          alert("Platform Name already exists\nPress ok to continue");
          setLoading(false);
          setName("");
          return;
        }
      }
      let teamidData = "";
      try {
        let encodeTName = encodeURIComponent(teamName);
        teamidData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${encodeTName}`,
          {
            method: "GET",

            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamidData = teamidData.data.data;
        if (teamidData.error) {
          if (teamidData.status === 401) {
            throw new Error(teamidData.message);
          }
          throw new Error(
            "An internal error has occured\nPossible Reasons:\n1: Server is down\n2: Network error\nPress ok to continue"
          );
        } else {
          let teamID = teamidData.data.id;
          let desc = description.trim();
          let na = name.trim();
          let body = { teamID, desc, na, platformId };
          const response = await axios.post(
            `${process.env.REACT_APP_ONBOARDER_API}/updatePlatform`,
            {
              mode: "cors",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              ...body,
            }
          );
          let responseData = response.data.data;
          // console.log(responseData);
          if (responseData.error) {
            if (responseData.status === 401) {
              throw new Error(responseData.message);
            }
            throw new Error(
              "Unable to update your platform\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
            );
          } else {
            setLoading(false);
            setDescription("");
            setName("");
            setTeamName("");
            setOpp("");
            setPlatformName("");
            alert(
              "Your platform has been successfully updated!!\nPress ok to continue"
            );
          }
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
    //=======================================================================================================================
    //DELETION
    else {
      try {
        // const body = { platformId };
        const response = await axios.delete(
          `${process.env.REACT_APP_ONBOARDER_API}/deletePlatform`,
          {
            mode: "cors",
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              platformId: platformId,
            },
          }
        );

        let responseData = response.data.data;

        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable to Delete your Platform\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          alert(
            "Your platform has been successfully deleted\nPress ok to continue"
          );
          setLoading(false);
          setName("");
          setDescription("");
          setTeamName("");
          setOpp("");
        }
      } catch (err) {
        setLoading(false);
        // console.log(err)
        alert(err.message);
      }
    }
  };

  const changeValue = (value, e) => {
    switch (value) {
      case "setName":
        if (opp === "") {
          setName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setName(e.target.value);
        break;

      case "setDescription":
        if (opp === "") {
          setDescription("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setDescription(e.target.value);
        break;

      case "setTeamName":
        if (opp === "") {
          setTeamName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setTeamName(e.target.value);

        break;

      default:
        break;
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <div className="topContainer">
        {loading ? (
          <h2 style={{ fontWeight: "100" }}>
            {opp === "Insert"
              ? "Onboarding "
              : opp === "Update"
              ? "Updating "
              : "Deleting "}
            your platform, Please wait!!
          </h2>
        ) : (
          <>
            {/* <h1 style={{ fontWeight: "100" }}>Platform Info Form</h1> */}
            <div className="formContainer">
              <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3 formGroup"
                  controlId="formBasicEmail"
                >
                  <Form.Label style={{}} className="formLabel">
                    Operation
                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                  </Form.Label>
                  <Box
                    sx={{
                      minWidth: 120,
                      marginBottom: "20px",
                      marginTop: "20px",
                      border: "none",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Operation
                      </InputLabel>
                      <Select
                        style={{ borderTop: "0px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={opp}
                        label="Select Operation"
                        onChange={(e) => handleOppChange(e)}
                      >
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Insert"
                        >
                          Onboard Platform
                        </MenuItem>
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Update"
                        >
                          Update Platform
                        </MenuItem>
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Delete"
                        >
                          Delete Platform
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {opp === "Update" || opp === "Delete" ? (
                    <Box
                      sx={{
                        minWidth: 120,
                        marginBottom: "20px",
                        marginTop: "20px",
                        border: "none",
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {gettingPlatformNames
                            ? "Loading Platforms"
                            : "Select Platform"}
                        </InputLabel>
                        <Select
                          style={{ borderTop: "0px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={gettingPlatformNames}
                          value={platformName}
                          label="Select Platform"
                          onChange={(e) => handlePlatformNameUptDel(e)}
                        >
                          {platformNames.length > 0 &&
                            platformNames.map((platformName) => {
                              return (
                                <MenuItem
                                  style={{ fontFamily: "Maersk Text" }}
                                  value={platformName.name}
                                >
                                  {platformName.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}

                  {formInputFieldData.map((data) => {
                    return (
                      <>
                        <Form.Label className="formLabel">
                          {data.name}{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            *
                          </span>
                        </Form.Label>
                        {!data.isTextArea ? (
                          data.type === "select" ? (
                            <Box sx={{ minWidth: 120, marginBottom: "20px" }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {gettingPlatformData
                                    ? "Loading Platform Details"
                                    : "Select Team"}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={teamName}
                                  disabled={
                                    opp === "Delete" || gettingPlatformData
                                  }
                                  label="Select Team"
                                  onChange={(e) =>
                                    changeValue(data.onChange, e)
                                  }
                                >
                                  {teamNames.length !== 0 &&
                                    teamNames.map((teamName) => {
                                      return [
                                        <MenuItem
                                          style={{ fontFamily: "Maersk Text" }}
                                          value={teamName.name}
                                        >
                                          {teamName.name}
                                        </MenuItem>,
                                      ];
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                          ) : (
                            <Form.Control
                              className="inputBox"
                              type={data.type}
                              placeholder={
                                gettingPlatformData && "Loading Platform Details"
                              }
                              onChange={(e) => changeValue(data.onChange, e)}
                              value={data.value}
                              required={data.required ? true : false}
                              disabled={opp === "Delete" || gettingPlatformData}
                            />
                          )
                        ) : (
                          <Form.Control
                            as="textarea"
                            className="multiLineInput"
                            type={data.type}
                            placeholder={
                              gettingPlatformData
                                ? "Loading Platform Details"
                                : "Describe your Platform"
                            }
                            rows="5"
                            onChange={(e) => changeValue(data.onChange, e)}
                            value={data.value}
                            required={data.required ? true : false}
                            disabled={opp === "Delete" || gettingPlatformData}
                          />
                        )}
                      </>
                    );
                  })}

                  <div className="buttonContainer">
                    <Button type="submit" className="submitButton">
                      {opp === "Insert"
                        ? "Submit"
                        : opp === "Update"
                        ? "Update"
                        : "Delete"}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PlatformForm;
