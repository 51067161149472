import React from 'react'
import Sloth from './Sloth'
import { SlothLayoutProvider } from './SlothContext'

function SlothUI() {
  return (
    <SlothLayoutProvider>
    <Sloth/>
    </SlothLayoutProvider>
  )
}

export default SlothUI