import React from 'react';

const Application = ({ queryApplication, setQueryApplication }) => {
  
  const handleChange = (event) => {
    setQueryApplication(event.target.value);
  };

  console.log("this SLI Application", queryApplication);

  return (
    <div>
      <input
        type="text"
        name="Application"
        placeholder="afls"
        value={queryApplication}
        onChange={handleChange}
      />
      {/* <div>
        <span>{queryApplication}</span>
      </div> */}
    </div>
  );
};

export default Application;


// import React, { useState } from 'react';

// const Application = ({queryApplication,setQueryApplication}) => {
//   const [selectedValue, setSelectedValue] = useState('');

//   const handleChange = (event) => {
//     setQueryApplication(event.target.value);
//   };
//   console.log("this SLI Application",queryApplication)

//   return (
//     <div>

//       <input
//             type="text"
//             name="Application"
//             placeholder="afls"
//             value={fieldSet.testValue}
//             onChange={(event) => handleChange(index, event)}
//           />
      
//       <div>
//         <span >{queryApplication}</span>
//       </div>
//     </div>
//   );
// };

// export default Application;


