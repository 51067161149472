import { check } from "./checkSloQuery";
import yaml from "js-yaml";

export const submit = (
  sloName,
  sloDescription,
  objective,
  totalQuery,
  alertName,
  alertAnnotations,
  category,
  errorQuery,
  yamlDoc,
  sloData,
  setSlos,
  setSlosContextArray,
  setCategory,
  resetForm,
  setEditing,
  token,
  setLoading,
  setErrorQueryValidation,
  setTotalQueryValidation,
  setDisplayError,
  setDisplayErrorMessage,
  setDefaultSlo
) => {
  console.log("in submit ",sloData)
  if (
    sloName.length == 0 ||
    sloDescription.length == 0 ||
    objective == 0 ||
    totalQuery.current?.value.length == 0 ||
    // alertName.current?.value.length == 0 ||
    // alertAnnotations.current?.value.length == 0 ||
    category.length == 0
  ) {
    alert("Please fill all fields now");
    return;
  } else if (sloData.headwigScope && sloData?.headwigScope?.value) {
    if (
      alertName.current?.value.length == 0 ||
      alertAnnotations.current?.value.length == 0
    ) {
      alert("Please fill all fields now");
      return;
    }
  }

  //initializing a temporary variable with the current input values for sloinfo form
  console.log("HANDLE SLO ", sloData)
  const sloInfoData = {
    sloName: sloName,
    sloDescription: sloDescription,
    category: category,
    objective: objective,
    errorQuery: errorQuery.current?.value,
    totalQuery: totalQuery.current?.value,
    alertName: alertName.current?.value,
    alertAnnotations: alertAnnotations.current?.value,
    newSlo: true,
  };
  // console.log("Slo info Data ", sloInfoData);
  //checking SQL query
  let sloInfoDataNew = [];
  sloInfoDataNew.push(sloInfoData);
  let hedwigScopeValue=sloData.hedwigScope? sloData.hedwigScope.value :"";
  yamlDoc = {
    version: "prometheus/v1",
    service: `${sloData.Application.value.replace(/ /g, "_").replace(/\(/g, "_").replace(/\)/g, "_").replace(/_$/, "")}`,
    labels: {
      portfolio: `${sloData.Portfolio.value}`,
      platform: `${sloData.Platform.value}`,
      product: `${sloData.Product.value}`,
      product_id: `${sloData.Application.value.replace(/ /g, "_").replace(/\(/g, "_").replace(/\)/g, "_").replace(/_$/, "")}`,
      env: `${sloData.Environment.value.toLowerCase()}`,
      email_dl: `${sloData.emailDl}`,
      ...(hedwigScopeValue !== "" ? { hedwig_scope: hedwigScopeValue } : {})
    },
    slos: [],
  };
  sloInfoDataNew.map((sloContextData) => {
    {sloContextData.alertAnnotations!=undefined?yamlDoc["slos"].push({
      name: `${sloContextData.sloName}`,
      objective: parseFloat(sloContextData.objective),
      description: `${sloContextData.sloDescription}`,
      sli: {
        events: {
          error_query: `\n${sloContextData.errorQuery.trim().replace(/\n/g, '').replace(/ {2,}/g, '')}`,
          total_query: `\n${sloContextData.totalQuery.trim().replace(/\n/g, '').replace(/ {2,}/g, '')}`,
        },
      },
      alerting: {
        name: `${sloContextData.alertName}`,
        labels: {
          category: `${sloContextData.category}`,
        },
        annotations: {
          summary: `${sloContextData.alertAnnotations}`,
        },
        page_alert: {
          labels: {
            severity: "critical",
          },
        },
        ticket_alert: {
          labels: {
            severity: "warning",
          },
        },
      },
    }):
    yamlDoc["slos"].push({
      name: `${sloContextData.sloName}`,
      objective: parseFloat(sloContextData.objective),
      description: `${sloContextData.sloDescription}`,
      sli: {
        events: {
          error_query: `\n${sloContextData.errorQuery.trim().replace(/\n/g, '').replace(/ {2,}/g, '')}`,
          total_query: `\n${sloContextData.totalQuery.trim().replace(/\n/g, '').replace(/ {2,}/g, '')}`,
        },
      },
      alerting: {
        name: `${sloContextData.alertName}`,
        labels: {
          category: `${sloContextData.category}`,
        },
        annotations: {
          summary: `${sloContextData.alertAnnotations}`,
        },
        page_alert: {
          disable: true ,
        },
        ticket_alert: {
          disable:true,
        },
      },
    });}
    
  });
  let yamlString = yaml.dump(yamlDoc, { lineWidth: -1 });
  // console.log(yamlString.split("\n").filter(line => line.trim() !== '').map(line => line.replace(/(error_query|total_query): \|-/, '$1: |')).join("\n"))
  yamlString = yamlString.split("\n").filter(line => line.trim() !== '').map(line => line.replace(/(error_query|total_query): \|-/, '$1: |')).join("\n")
  // console.log("YAML STRING ", yamlString)
  // console.log(
  //   "yamlString",
  //   yamlString
  //     .split("\n")
  //     .map((line) => {
  //       const trimmedLine = line.trim();
  //       if (
  //         trimmedLine.startsWith("sum") 
  //         // trimmedLine.startsWith("sum")
  //       ) {
  //         return " ".repeat(1) + trimmedLine;
  //       }
  //       return line;
  //     })
  //     .join("\n")
  // );
console.log("YAML STRING ", yamlString);
  const SLOQueryResponse = check(
    yamlString,
    sloData,
    token,
    setLoading,
    setErrorQueryValidation,
    setTotalQueryValidation,
    setDisplayError,
    setDisplayErrorMessage,

  ).then((response) => {
    console.log("New Response", response);
    console.log("Sloinfo Data ",sloInfoData)

    if (response == true) 
      //      
    // (true)

    {
      setDefaultSlo(false)
      //pushing the current slo info in local state slos array
      setSlos((currentSloData) => [...currentSloData, sloInfoData]);

      //pushing the current slo info into context state slos array
      setSlosContextArray((currentSloContextArray) => [
        ...currentSloContextArray,
        sloInfoData,
      ]);
      // console.log("generateYaml",generateYaml());

      //set the category state to initial state
      setCategory((currentCategoryData) => "");

      //scroll to the top after submitting form
      window.scrollTo(0, 0);

      //reset the form
      resetForm();

      //unlocking the editing for other SLOs
      setEditing(false);
    } else {
      console.log("Doing Nothing");
    }
  });
  // const SLOQueryResponse = checkSLOQuery(yamlString)
  console.log(SLOQueryResponse);
};