export   const remove = (sloIndx, sloName, setSlos, setSlosContextArray, slos, slosContextArray) => {

  console.log("in remove function", sloName)
    //if we are removing the last slo in the state array just set the state slo Array as empty array
    if (sloIndx === 0 && slos.length === 1) {
      setSlos(() => []);
      setSlosContextArray(() => []);
      return;
    }

    //copying the state slo array to a temporary Array, just a standard practice to avoid operations directly on state variables
    let currentSlos = [...slos];

    //removing the slo object which is to be deleted or edited from the temporary Array
    currentSlos = currentSlos.filter((slo) => slo.sloName !== sloName);

    //copying the current context state slo array to a temporary Array, just a standard practice to avoid operations directly on state variables
    let currentSloContextArray = [...slosContextArray];

    //removing the slo object which is to be deleted or edited from the temporary Array initialized at step 69
    currentSloContextArray = currentSloContextArray.filter(
      (slo) => slo.sloName !== sloName
    );

    //setting the state slo Array with new Array
    setSlos(() => [...currentSlos]);

    //setting the context slo Array with new Array
    setSlosContextArray(() => [...currentSloContextArray]);

    // setNoOfOldSlos((noOfOldSlos) => noOfOldSlos - 1); //
  };