import yaml from "js-yaml";
export const generate = (yamlDoc, sloData, slosContextArray, setShow) => {
  console.log("Generarting Yamls");
  console.log("sloData in generate yaml", sloData);
  let hedwigScopeValue=sloData.hedwigScope? sloData.hedwigScope.value :"";
  yamlDoc = {
    version: "prometheus/v1",
    service: `${sloData.Application.value.replace(/ /g, "_").replace(/\(/g, "_").replace(/\)/g, "_").replace(/_$/, "")}`,
    labels: {
      portfolio: `${sloData.Portfolio.value}`,
      platform: `${sloData.Platform.value}`,
      product: `${sloData.Product.value}`,
      product_id: `${sloData.Application.value.replace(/ /g, "_").replace(/\(/g, "_").replace(/\)/g, "_").replace(/_$/, "")}`,
      env: `${sloData.Environment.value.toLowerCase()}`,
      email_dl: `${sloData.emailDl}`,
      ...(hedwigScopeValue !== "" ? { hedwig_scope: hedwigScopeValue } : {})
    },
    slos: [],
  };
  slosContextArray.map((sloContextData) => {
    {
      sloContextData.alertAnnotations != undefined
        ? yamlDoc["slos"].push({
            name: `${sloContextData.sloName}`,
            objective: parseFloat(sloContextData.objective),
            description: `${sloContextData.sloDescription}`,
            sli: {
              events: {
                error_query: `\n${sloContextData.errorQuery
                  .trim()
                  .replace(/\n/g, "")
                  .replace(/ {2,}/g, "")}`,
                total_query: `\n${sloContextData.totalQuery
                  .trim()
                  .replace(/\n/g, "")
                  .replace(/ {2,}/g, "")}`,
              },
            },
            alerting: {
              name: `${sloContextData.alertName}`,
              labels: {
                category: `${sloContextData.category}`,
              },
              annotations: {
                summary: `${sloContextData.alertAnnotations}`,
              },
              page_alert: {
                labels: {
                  severity: "critical",
                },
              },
              ticket_alert: {
                labels: {
                  severity: "warning",
                },
              },
            },
          })
        : yamlDoc["slos"].push({
            name: `${sloContextData.sloName}`,
            objective: parseFloat(sloContextData.objective),
            description: `${sloContextData.sloDescription}`,
            sli: {
              events: {
                error_query: `\n${sloContextData.errorQuery
                  .trim()
                  .replace(/\n/g, "")
                  .replace(/ {2,}/g, "")}`,
                total_query: `\n${sloContextData.totalQuery
                  .trim()
                  .replace(/\n/g, "")
                  .replace(/ {2,}/g, "")}`,
              },
            },
            alerting: {
              name: `${sloContextData.alertName}`,
              labels: {
                category: `${sloContextData.category}`,
              },
              annotations: {
                summary: `${sloContextData.alertAnnotations}`,
              },
              page_alert: {
                disable: true,
              },
              ticket_alert: {
                disable: true,
              },
            },
          });
    }
  });
  let yamlString = yaml.dump(yamlDoc, { lineWidth: -1 });
  // console.log(yamlString.split("\n").filter(line => line.trim() !== '').map(line => line.replace(/(error_query|total_query): \|-/, '$1: |')).join("\n"))
  yamlString = yamlString
    .split("\n")
    .filter((line) => line.trim() !== "")
    .map((line) => line.replace(/(error_query|total_query): \|-/, "$1: |"))
    .join("\n");
  console.log("YAML STRING ", yamlString);
  setShow(true);
  return yamlString;
};
