import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactToPrint from "react-to-print";
import "../../../StyleSheets/URLBatch.css";
import LoadingReport from "../../../Components/LoadingReport";
import { ImCross } from "react-icons/im";
import Error from "../../../Components/Error";
export const URLBatchReport = React.forwardRef((ref) => {
  var DetailedReportcondition = true;

  var url;
  var firstWord;
  var intfirstWord;
  var corssExpiry = false;
  var componentRef = useRef();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchData();
    return () => {
      setLoading(true);
    };
  }, []);

  const fetchData = async () => {
    try {

      let config = {
        headers: {
          "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
        }
      }
      const wrapperApi = process.env.REACT_APP_APIGEE_WRAPPER_API
      url = wrapperApi + "/cert/url/list"

      const res = await axios.get(url, config);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
    }
  };
  DetailedReportcondition = true;
  return (
    <>
      {loading ? (
        <LoadingReport />
      ) : (
        <>
          {data ?
            <>
              <div className="reportSection">
                <div ref={(el) => (componentRef = el)}>
                  <div style={{ width: "100%" }}>
                    {DetailedReportcondition ? (
                      <h2 className="text-center py-4">
                        URL Certificate Expiry - Detailed Report
                      </h2>
                    ) : (
                      <h2 className="text-center py-4">
                        URL Certificate Expiry Report
                      </h2>
                    )}

                    <div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>CI Id</th>
                            <th>CI Name</th>
                            <th>URL</th>
                            <th>Port</th>
                            <th>Expiry</th>
                            <th>Time to Expiry</th>
                            <th>Team Name</th>
                            <th>Team Email DL</th>
                            {DetailedReportcondition ? (
                              <>
                                <th>Reachable</th>


                                <th>Issuer</th>
                                <th>SSL Check</th>
                                <th>Hostname Check</th>
                                <th>Record Created</th>
                                <th>Record Updated </th>
                                <th>Error</th>
                              </>
                            ) : (
                              ""
                            )}
                            <th>Expiry Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((d) => (
                            <>
                              <tr>
                                <td>{d.ci_id}</td>
                                {d.ci_name ? (
                                  <td>{d.ci_name} </td>
                                ) : (
                                  <td
                                    className="text-center"
                                    style={{ color: "red" }}
                                  >

                                  </td>
                                )}
                                <td>{d.url}</td>
                                <td>{d.port}</td>
                                {d.expiry ? (
                                  <td>{d.expiry}</td>
                                ) : (
                                  <td className="text-center" style={{ color: "red" }}>
                                    <ImCross />
                                  </td>
                                )}
                                {d.time_to_expiry ? (

                                  <td>{d.time_to_expiry}</td>
                                ) : (
                                  <td className="text-center" style={{ color: "red" }}>
                                    <ImCross />
                                  </td>
                                )}

                                {d.team_name ? (
                                  <td>{d.team_name} </td>
                                ) : (
                                  <td
                                    className="text-center"
                                    style={{ color: "red" }}
                                  >

                                  </td>
                                )}
                                {d.team_email_dl ? (
                                  <td>{d.team_email_dl} </td>
                                ) : (
                                  <td
                                    className="text-center"
                                    style={{ color: "red" }}
                                  >

                                  </td>
                                )}
                                {DetailedReportcondition ? (
                                  <>
                                    {d.reachable ? (
                                      <td>{d.reachable ? "true" : " "} </td>
                                    ) : (
                                      (corssExpiry = true && (
                                        <td
                                          className="text-center"
                                          style={{ color: "red" }}
                                        >
                                          <ImCross />
                                        </td>
                                      ))
                                    )}


                                    {d.issuer ? (
                                      <td>{d.issuer} </td>
                                    ) : (
                                      <td
                                        className="text-center"
                                        style={{ color: "red" }}
                                      >
                                        <ImCross />
                                      </td>
                                    )}
                                    {d.ssl_check ? (
                                      <td>{d.ssl_check ? "true" : ""} </td>
                                    ) : (
                                      (corssExpiry = true && (
                                        <td
                                          className="text-center"
                                          style={{ color: "red" }}
                                        >
                                          <ImCross />
                                        </td>
                                      ))
                                    )}
                                    {d.hostname_check ? (
                                      <td>{d.hostname_check ? "true" : ""} </td>
                                    ) : (
                                      (corssExpiry = true && (
                                        <td
                                          className="text-center"
                                          style={{ color: "red" }}
                                        >
                                          <ImCross />
                                        </td>
                                      ))
                                    )}
                                    {d.record_created ? (
                                      <td>{d.record_created} </td>
                                    ) : (
                                      <td
                                        className="text-center"
                                        style={{ color: "red" }}
                                      >

                                      </td>
                                    )}
                                    {d.record_updated ? (
                                      <td>{d.record_updated} </td>
                                    ) : (
                                      <td
                                        className="text-center"
                                        style={{ color: "red" }}
                                      >

                                      </td>
                                    )}
                                    {d.error ? (
                                      <td style={{ color: "red" }}>{d.error} </td>
                                    ) : (
                                      <td></td>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                {d.time_to_expiry ? (
                                  <>
                                    {(() => {
                                      firstWord = d.time_to_expiry.substr(
                                        0,
                                        d.time_to_expiry.indexOf(" ")
                                      );
                                      intfirstWord = parseInt(firstWord, 10);

                                    })()}
                                  </>
                                ) : (
                                  ""
                                )}

                                {d.reachable &&
                                  d.ssl_check &&
                                  d.hostname_check &&
                                  d.error === null ? (
                                  <>
                                    {intfirstWord < 30 ? (
                                      <td style={{ backgroundColor: "red" }}></td>
                                    ) : intfirstWord < 60 ? (
                                      <td style={{ backgroundColor: "orange" }}></td>
                                    ) : intfirstWord < 90 ? (
                                      <td style={{ backgroundColor: "yellow" }}></td>
                                    ) : (
                                      <td style={{ backgroundColor: "green" }}></td>
                                    )}{" "}
                                  </>
                                ) : (
                                  <td className="text-center" style={{ color: "red" }}>
                                    <ImCross />
                                  </td>
                                )}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <br />
                  </div>
                </div>
              </div>
              <ReactToPrint
                trigger={() => {
                  return (
                    <button className="downloadBtn" href="#">
                      Download PDF
                    </button>
                  );
                }}
                content={() => componentRef}
              />
            </> : (error ?
              <Error element={error} /> :
              <Error element={data} />
            )}

        </>
      )}
    </>
  );
});
