export const getCleanUrls = (urls, opp) => {
  // console.log(urls)
  let cleanUrls = urls;

  switch (opp) {
    case "Insert":
      for (let i = 0; i < cleanUrls.length; i++) {
        // cleanUrls[i] = cleanUrls[i]+'.com'
        // cleanUrls[i] = cleanUrls[i].replace(/\\n/g,"").trim()

        if (cleanUrls[i].indexOf("http://") == 0) {
          cleanUrls[i] = cleanUrls[i].replace("http://", "");
        }

        if (cleanUrls[i].indexOf("https://") == 0) {
          cleanUrls[i] = cleanUrls[i].replace("https://", "");
        }
      }
      break;
    case "Update":
      for (let i = 0; i < cleanUrls.length; i++) {
        // cleanUrls[i] = cleanUrls[i]+'.com'
        // cleanUrls[i] = cleanUrls[i].replace(/\\n/g,"").trim()

        if (cleanUrls[i].url.indexOf("http://") == 0) {
          cleanUrls[i].url = cleanUrls[i].url.replace("http://", "");
        }

        if (cleanUrls[i].url.indexOf("https://") == 0) {
          cleanUrls[i].url = cleanUrls[i].url.replace("https://", "");
        }
      }
      break;
    default:
      break;
  }

  // console.log(cleanUrls);
  return cleanUrls;
};
