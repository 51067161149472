import React, { useState } from "react";
import "./styles.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DBForensic from "./DBForensic";


const Forensic = () => {
  const [selectedOption, setSelectedOption] = useState("DBforensic");

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  return (
    <>
      <div className="bodyWrapper">

        <div className="optionSection">

          <Box sx={{ width: '100%' }}>
            <Tabs
              value={selectedOption}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="DBforensic"
                label="Database Forensic"
                // wrapped
              />
              <Tab value="application_forensic" label="Application Forensic" />
            </Tabs>
          </Box>

        </div>

        <div className="TabsBox">

          <div className="Contents">
            <div className={selectedOption === "DBforensic" ? "content active-content" : "content"}>
              {selectedOption === "DBforensic" && <DBForensic />}
            </div>
            <div className={selectedOption === "application_forensic" ? "content active-content" : "content"}>
              {selectedOption === "application_forensic" && <div style={{display:"flex",alignItems:"center",justifyContent:"center",fontSize:"30px",color:"gray",fontWeight:"600",minHeight:"40rem"}}> COMING SOON</div>}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Forensic;