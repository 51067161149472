import React, { useState } from "react";
import "./styles.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import URLSingle from "../../../../Capabilities/Reliability/CertValidation/URLSingle";
import { URLBatchReport } from "../../../../Capabilities/Reliability/CertValidation/URLBatchReport";
import OnboardUrl from "../OnboardUrl/OnboardUrl";

const ValidationForUrls = () => {
  const [selectedOption, setSelectedOption] = useState("ValidateUrl");

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  return (
    <>
      <div className="bodyWrapper">

        <div className="optionSection">

          <Box sx={{ width: '100%' }}>
            <Tabs
              value={selectedOption}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="ValidateUrl"
                label="Validate Url"
                // wrapped
              />
              <Tab value="onboard_urls" label="Onboard Urls" />
              <Tab value="certificate_validation_report" label="Certificate  Validtion Report" />
            </Tabs>
          </Box>

        </div>

        <div className="TabsBox">

          <div className="Contents">
            <div className={selectedOption === "ValidateUrl" ? "content active-content" : "content"}>
              {selectedOption === "ValidateUrl" && < URLSingle />}
            </div>
            <div className={selectedOption === "onboard_urls" ? "content active-content" : "content"}>
              {selectedOption === "onboard_urls" && < OnboardUrl />}
            </div>
            <div className={selectedOption === "certificate_validation_report" ? "content active-content" : "content"}>
              {selectedOption === "certificate_validation_report" && < URLBatchReport /> } 
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ValidationForUrls;