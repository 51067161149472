import React from 'react';

const MopTestSuiteId = ({ queryMopTestSuiteId, setQueryMopTestSuiteId }) => {
  
  const handleChange = (event) => {
    setQueryMopTestSuiteId(event.target.value);
  };

  console.log("this SLI MopTestSuiteId", queryMopTestSuiteId);

  return (
    <div>
      <input
        type="text"
        name="MopTestSuiteId"
        placeholder="2123"
        value={queryMopTestSuiteId}
        onChange={handleChange}
      />
      {/* <div>
        <span>{queryMopTestSuiteId}</span>
      </div> */}
    </div>
  );
};

export default MopTestSuiteId;
