import React from 'react'
import Dropdowns from '../../Components/Dropdowns'
import "../../StyleSheets/Capability.css"

function Capabilities({capabilities}) {
  console.log(capabilities)
  return (
    <div className="capabilities__container">{capabilities.map(capability => {
      return (
        <div className='capabilities__capabilityContainer' style={{...capability.style}}>
          <h5 style={{color:"white", fontWeight:"800", textAlign:"center", padding:"20px", marginBottom:"0px"}}>{capability.name}</h5>
          <p style={{...capability.tag_line.styles}} className="capabilities__capabilityTagLine"><strong><i>{capability.tag_line.line}</i></strong></p>
          <div style={{...capability.dropDownStyles}}>
          <Dropdowns links = {capability.links} dropDownName={capability.name} dropDownItemStyles={capability.dropDownItemStyle}/>
          </div>
        </div>
      )
    })}</div>
  )
}

export default Capabilities