export const createUrlArray = (data, opp) => {

    // console.log(data, opp)

    let result = []

    switch (opp) {
        case "Insert":
            for(var i = 0; i<data.length; i++) {
                if(data[i] !== "" && data[i] !== " " ){
                    result.push(data[i])
                }
            }
            break;
        case "Update":
            for(var i = 0; i<data.length; i++) {
                if(data[i].url !== "" && data[i].url !== " " ){
                    result.push({id:data[i].id, url:data[i].url, active_flag:data[i].active_flag})
                }
            }
        default:
            break;
    }
    // console.log("Result ", result)
    return result
}