import axios from "axios";

let config = {
  mode: "cors",
  method: "GET",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "consumer-key": process.env.REACT_APP_CONSUMER_KEY,
  },
};

export const ciIdAlreadyExist = async (ciId) => {
  const ciIdExist = await axios.get(
    `${process.env. REACT_APP_ONBOARDER_API}/checkCiIdExist?ciId=${ciId}`,config
  );

  if (ciIdExist.data.data.exist) {
    return true;
  }
  return false;
};

export const ciNameAlreadyExist = async (ciName) => {
  const ciNameExist = await axios.get(
    `${process.env.REACT_APP_ONBOARDER_API}/checkCiNameExist?name=${ciName}`,config);
  if (ciNameExist.data.data.exist) {
    return true;
  }
  return false;
};

export const teamNameAlreadyExist = async (teamName) => {
  console.log("TeamName ", teamName);
  const teamNameExist = await axios.get(
    `${process.env.REACT_APP_ONBOARDER_API}/checkTeamNameExist?name=${teamName}`,config);

  if (teamNameExist.data.data.exist) {
    return true;
  }
  return false;
};

export const platformNameAlreadyExist = async (platformName) => {
  const platformNameExist = await axios.get(
    `${process.env.REACT_APP_ONBOARDER_API}/checkPlatformNameExist?name=${platformName}`,config);

  if (platformNameExist.data.data.exist) {
    return true;
  }
  return false;
};

export const platformExist = async(tid) => {

  const platfromExist = await axios.get( `${process.env.REACT_APP_ONBOARDER_API}/checkIfPlatformExist?tid=${tid}`,config)

  if(platfromExist.data.data.exist === false) {
    return false;
  }
  return true
}
