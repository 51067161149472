import { ConnectingAirportsOutlined } from "@mui/icons-material";

export const reset = (
  setSloName,
  setSloDescription,
  errorQuery,
  totalQuery,
  alertName,
  alertAnnotations,
  setObjective
) => {
  // {console.log('Nikki',sloName, sloDescription, errorQuery, totalQuery,alertName, alertAnnotations)}
  // {console.log("Nikki Gupta",alertName?.current)}
  if (alertName?.current == undefined) {
    setSloName(()=> "")
    setSloDescription(()=> "")
    // sloName.current.value = "";
    // sloDescription.current.value = "";
    errorQuery.current.value = "";
    totalQuery.current.value = "";
    // alertName.current.value = "";
    // alertAnnotations.current.value = "";
    setObjective(() => "");
  } else {
    setSloName(()=> "")
    setSloDescription(()=> "")
    errorQuery.current.value = "";
    totalQuery.current.value = "";
    alertName.current.value = "";
    alertAnnotations.current.value = "";
    setObjective(() => "");
  }
};
