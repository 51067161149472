import React, { useState } from 'react';

const SLI_Metric = ({querySliMetric,setQuerySliMetric}) => {
  const [selectedValue, setSelectedValue] = useState('mop_synthetics_test_result');

  const handleChange = (event) => {
    setQuerySliMetric(event.target.value);
  };
  console.log("this SLI metric",querySliMetric)

  return (
    <div>
      
      <select id="SLI_Metric" value={querySliMetric} onChange={handleChange}>
          <option value='probe_success'>Application Availability</option>
          <option value='kube_pod_container_status_running'>Kubernetes Microservice Availability</option>
          <option value='mop_synthetics_test_result'>MOP - Synthetic Monitoring</option>
        {/* <option value='mop_synthetics_test_result'></option> */}
      </select>
      
      {/* <div>
        <span >{querySliMetric}</span>
      </div> */}
    </div>
  );
};

export default SLI_Metric;