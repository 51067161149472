export const populate = (data, setdata) => {
   
console.log("DATA @3", data)

    const productData = {
    Portfolio: {id:data?.labels.portfolio, label:data?.labels.portfolio, value:data?.labels.portfolio},
    Product: {id:data?.labels.product, label:data?.labels.product, value:data?.labels.product},
    Platform: {id:data?.labels.platform, label:data?.labels.platform, value:data?.labels.platform},
    Application: {id:data?.service, label:data?.service, value:data?.service},
    Environment: {id:data?.labels.env, label:data?.labels.env, value:data?.labels.env},
    hedwigScope: {id:data?.labels.hedwig_scope, label:data?.labels.hedwig_scope, value:data?.labels.hedwig_scope},
 
    };

 

    setdata({ ...productData });
}