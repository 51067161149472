import React from 'react';
import "./styles.css";

const CertficateValidation = () => {
    return (
        <>
            <div className="ContentWrapperSection">
                <h2><strong >Certificate Validation</strong></h2>
                <p style={{ color: "#00243D", fontSize: "18px"}} >
                    <h4>SSL Certificate File Validation with Pensieve Integration</h4>
                    Check the expiry dates of certificate files in hosts, VMs, Kubernetes etc 
                    And view the expiries in Grafana Dashboards.
                    <br />
                    <br />
                    {/* <br /> */}
                    <h4>SSL Certificate Validation for URLs</h4>
                    Validate the certificates of URLs for expiry date, issuer and other attributes.
                    <br /> Also, get a validation report for already onboarded URLs.
                    <br />
                </p>
            </div>
        </>
    )
};

export default CertficateValidation;