export const edit = (
  editing,
  slos,
  toBeEditedSloName,
  setSloName,
 setSloDescription,
  errorQuery,
  totalQuery,
  alertName,
  alertAnnotations,
  setObjective,
  setCategory,
  removeThisSlo,
  setEditing,
  setDefaultSlo
 
) => {
  //if you have an slo that's already in editing mode then you cannot select any other slo to edit unless you saved the last slo
  if (editing) {
    alert("Pleas save the current SLO first");
    return;
  }



  //if we are editing an slo then also we need to remove it from the current slo state array for a time being
  //getting the index of slo that's to be remove from slo state array
  let toBeDeletedSloIndex = slos.findIndex(
    (slo) => slo.sloName === toBeEditedSloName
  );

  if(slos[toBeDeletedSloIndex].sloName.includes("Operations_Excellence_Availability")){
    setDefaultSlo(true)
  }

  //setting the current input box values with the slo values selected by the user for editing
  console.log("Alert Value Selected",alertName.current)
  if(alertName.current!=undefined){

    setSloName(() => slos[toBeDeletedSloIndex].sloName);
    setSloDescription(() => slos[toBeDeletedSloIndex].sloDescription);
 
    errorQuery.current.value = slos[toBeDeletedSloIndex].errorQuery;
    totalQuery.current.value = slos[toBeDeletedSloIndex].totalQuery;
    alertName.current.value = slos[toBeDeletedSloIndex].alertName;
    alertAnnotations.current.value = slos[toBeDeletedSloIndex].alertAnnotations;
  }else{
    setSloName(() => slos[toBeDeletedSloIndex].sloName);
    setSloDescription(() => slos[toBeDeletedSloIndex].sloDescription);
    errorQuery.current.value = slos[toBeDeletedSloIndex].errorQuery;
    totalQuery.current.value = slos[toBeDeletedSloIndex].totalQuery;
  }
 
  setObjective(() => slos[toBeDeletedSloIndex].objective);
  setCategory(() => slos[toBeDeletedSloIndex].category);

  //now remove this slo from the state arrays, both context and local
  removeThisSlo(toBeDeletedSloIndex, toBeEditedSloName);

  //This variable we ensure that you should not select another slo for editing before you save the current slo
  setEditing(true);
};
