import React, { useState } from "react";
import "./style.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TeamForm from "../../../../OnBoarder/Forms/TeamForm";
import PlatformForm from "../../../../OnBoarder/Forms/PlatformForm";
import CiForm from "../../../../OnBoarder/Forms/CiForm";
import UrlOnBoardInfoForm from "../../../../OnBoarder/Forms/UrlOnBoardInfoForm";

const OnboardUrl = () => {
    const [selectedOption, setSelectedOption] = useState("team");

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
    };

    return (
        <>

            <div className="optionSection">

                <Box sx={{ width: '100%' , display:"flex" , justifyContent:"flex-start"}}>
                    <Tabs
                        value={selectedOption}
                        onChange={handleChange}
                        aria-label="wrapped label tabs example"
                    >
                        <Tab
                            value="team"
                            label="Team"
                        // wrapped
                        />
                        <Tab value="platform" label="Platform" />
                        <Tab value="configuration_item" label="Configuration Item" />
                        <Tab value="urls_for_certificate_check" label="URLs for Certificate Check" />
                    </Tabs>
                </Box>

            </div>

            <div className="TabsBox">

                <div className="Contents">
                    <div className={selectedOption === "team" ? "content active-content" : "content"}>
                        {selectedOption === "team" && <TeamForm />}
                    </div>
                    <div className={selectedOption === "platform" ? "content active-content" : "content"}>
                        {selectedOption === "platform" && < PlatformForm />}
                    </div>
                    <div className={selectedOption === "configuration_item" ? "content active-content" : "content"}>
                        {selectedOption === "configuration_item" && < CiForm />}
                    </div>
                    <div className={selectedOption === "urls_for_certificate_check" ? "content active-content" : "content"}>
                        {selectedOption === "urls_for_certificate_check" && < UrlOnBoardInfoForm />}
                    </div>
                </div>
            </div>

        </>
    );
};

export default OnboardUrl;