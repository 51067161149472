import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import NavBar from "../../Components/NavBar";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import {
  ciIdAlreadyExist,
  ciNameAlreadyExist,
} from "../util/checkIfAlreadyExist";

function CiForm() {
  const [name, setName] = useState("");
  const [oldCiName, setOldCiName] = useState("");
  const [description, setDescription] = useState("");
  const [ciId, setCiId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamNames, setTeamNames] = useState([]);
  const [platformName, setPlatformName] = useState("");
  const [platformNames, setPlatformNames] = useState([]);
  const [supportTeamName, setSupportTeamName] = useState("");
  const [ciName, setCiName] = useState("");
  const [ciNames, setCiNames] = useState([]);
  const [opp, setOpp] = useState("Insert");
  const [loading, setLoading] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [gettingCiNames, setGettingCiNames] = useState(false);
  const [gettingCiData, setGettingCiData] = useState(false);

  useEffect(() => {
    fetchTeamNames();
  }, []);

  const fetchTeamNames = async () => {
    try {
      let tnames = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamNames`,
        {
          mothod: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      tnames = tnames.data.data;

      if (tnames.error) {
        throw new Error(tnames.message);
      } else {
        setTeamNames(tnames.team_names);
      }
    } catch (err) {
      alert(err.message);
    }
    try {
      let pnames = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getPlatforms`,
        {
          mothod: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      pnames = pnames.data.data;

      if (pnames.error) {
        throw new Error(pnames.message);
      } else {
        let platformData = pnames.platforms.map((platform) => {
          return { name: platform.name, team_id: platform.team_id };
        });

        setPlatforms([...platformData]);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    if (teamName) {
      setPlatformNames([]);
      fetchTeamPlatforms();
    }
  }, [teamName]);

  const fetchTeamPlatforms = async () => {
    let teamidData = "";
    try {
      let encodeteamName = encodeURIComponent(teamName);
      teamidData = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${encodeteamName}`,
        {
          method: "GET",
          mode: "cors",

          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      teamidData = teamidData.data.data;

      if (teamidData.error) {
        if (teamidData.status === 401) {
          throw new Error(teamidData.message);
        }
        throw new Error(
          "An internal error has occurred, unable to get the team id\nPress ok to continue"
        );
      }
    } catch (err) {
      alert(err.message);
    }

    try {
      let pforms = platforms.filter(
        (platform) => platform.team_id === teamidData.data.id
      );

      if (!pforms || pforms.length === 0) {
        throw new Error(
          "No Platforms are associated with the selected team, Please onboard platforms first\nPress ok to continue"
        );
      } else {
        setPlatformNames([...pforms]);
      }
    } catch (err) {
      alert(err.message);
      return;
    }
  };

  useEffect(() => {
    setTeamName("");
    setPlatformName("");
    setSupportTeamName("");
    setName("");
    setDescription("");
    setTeamName("");
    setCiId("");
    setCiName("");
    // setCiNames([])
    if (opp === "Update" || opp === "Delete") {
      if (opp !== "") {
        fetchCiNames();
      }
    }
  }, [opp]);

  const fetchCiNames = async () => {
    try {
      setGettingCiNames(true);
      let cinames = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getCiNames`,
        {
          method: "GET",
          mode: "cors",

          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      cinames = cinames.data.data;
      if (cinames.error) {
        throw new Error(cinames.message);
      } else {
        setCiNames(cinames.ci_names);
        setGettingCiNames(false);
      }
    } catch (err) {
      setGettingCiNames(false);
      alert(err.message);
    }
  };

  useEffect(() => {
    if (!ciName) {
      return;
    }
    setTeamName("");
    setPlatformName("");
    setSupportTeamName("");
    setDescription("");
    setTeamName("");
    setCiId("");
    fetchCiData();
  }, [ciName]);

  const fetchCiData = async () => {
    // (teamName)
    let encodeCiName = encodeURIComponent(ciName);
    try {
      setGettingCiData(true);
      let ciData = await axios.get(
        `${process.env.REACT_APP_ONBOARDER_API}/getCiData?name=${encodeCiName}`,
        {
          method: "GET",
          mode: "cors",

          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      ciData = ciData.data.data;
      if (ciData.error) {
        throw new Error(ciData.message);
      } else {
        // (ciData.data[0]);
        setDescription(ciData.data[0].description);
        setCiId(ciData.data[0].id);
        setName(ciData.data[0].name);
        setOldCiName(ciData.data[0].name);
        let teamData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamData?id=${ciData.data[0].team_id}`,
          {
            method: "GET",
            mode: "cors",

            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamData = teamData.data.data;
        // (teamData.data)
        setTeamName(teamData.data[0].name);

        let supportTeamData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getSupportTeamData?id=${ciData.data[0].support_team_id}`,
          {
            method: "GET",
            mode: "cors",

            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        supportTeamData = supportTeamData.data.data;

        setSupportTeamName(supportTeamData.data[0].name);

        let platformData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getPlatformData?id=${ciData.data[0].platform_id}`,
          {
            metho: "GET",
            mode: "cors",

            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        platformData = platformData.data.data;

        setPlatformName(platformData.data[0].name);
        setGettingCiData(false);
      }
    } catch (err) {
      setGettingCiData(false);
      alert(err.message);
    }
  };
  const handleCiNameUptDel = (e) => {
    setCiName(e.target.value);
  };

  const formInputFieldData = [
    {
      name: "CI ID",
      type: "text",
      required: "true",
      isTextArea: false,
      onChange: "setCiId",
      value: ciId,
    },
    {
      name: "Name",
      type: "text",
      required: "true",
      isTextArea: false,
      onChange: "setName",
      value: name,
    },

    {
      name: "Team Name",
      type: "select",
      required: true,
      isTextArea: false,
      onChange: "setTeamName",
      value: teamName,
      disabled: false,
      names: teamNames,
      text: "Select Team",
    },

    {
      name: "Platform Name",
      type: "select",
      required: true,
      isTextArea: false,
      onChange: "setPlatformName",
      value: platformName,
      names: platformNames,
      disabled: false,
      text: "Select Platform",
    },

    {
      name: "Support Team Name",
      type: "select",
      required: true,
      isTextArea: false,
      onChange: "setSupportTeamName",
      value: supportTeamName,
      names: teamNames,
      disabled: false,
      text: "Select Support Team",
    },

    {
      name: "Description",
      type: "text",
      required: "true",
      isTextArea: true,
      onChange: "setDescription",
      value: description,
    },
  ];

  const changeValue = (value, e) => {
    switch (value) {
      case "setName":
        if (opp === "") {
          setName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setName(e.target.value);
        break;

      case "setCiId":
        if (opp === "") {
          setCiId("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setCiId(e.target.value);
        break;

      case "setDescription":
        if (opp === "") {
          setDescription("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setDescription(e.target.value);
        break;

      case "setTeamName":
        if (opp === "") {
          setTeamName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setTeamName(e.target.value);

        break;

      case "setPlatformName":
        if (opp === "") {
          setPlatformName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setPlatformName(e.target.value);
        break;

      case "setSupportTeamName":
        if (opp === "") {
          setSupportTeamName("");
          alert("Please select an operation first\nPress ok to continue");
          return;
        }
        setSupportTeamName(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleOppChange = (e) => {
    setOpp(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (opp === "Delete") {
      if (
        !window.confirm(
          "Do you really want to delete your CI?\nAll  URLs associated with this CI will be  deleted"
        )
      ) {
        return;
      }
    }

    let teamidData = "";
    let platformIdData = "";
    let supportTeamIdData = "";

    //================================================================================================================================
    //INSERTION

    if (opp === "Insert") {
      setLoading(true);
      try {
        teamidData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${teamName}`,
          {
            method: "GET",
            mode: "cors",

            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamidData = teamidData.data.data;
        if (teamidData.error) {
          if (teamidData.status === 401) {
            throw new Error(teamidData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the team id\nPress ok to continue"
          );
        }
        platformIdData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getPlatformId?name=${platformName}`,

          {
            method: "GET",

            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        platformIdData = platformIdData.data.data;
        if (platformIdData.error) {
          if (platformIdData.status === 401) {
            throw new Error(platformIdData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the platform id"
          );
        }
        supportTeamIdData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getSupportTeamId?name=${supportTeamName}`,
          {
            method: "GET",
            mode: "cors",

            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        supportTeamIdData = supportTeamIdData.data.data;
        if (supportTeamIdData.error) {
          if (supportTeamIdData.status === 401) {
            throw new Error(supportTeamIdData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the support team id\nPress ok to continue"
          );
        }
        let teamID = teamidData.data.id;
        let platformID = platformIdData.data.id;
        let supportTeamId = supportTeamIdData.data.id;

        if (await ciIdAlreadyExist(ciId)) {
          setLoading(false);
          alert("CI ID Already exists!");
          setCiId("");
          return;
        }

        if (await ciNameAlreadyExist(name)) {
          setLoading(false);
          alert("CI Name Already exists!");
          setName("");
          return;
        }
        let desc = description.trim();
        let na = name.trim();
        let ci_Id = ciId.trim();
        let body = {
          teamID,
          desc,
          na,
          platformID,
          ci_Id,
          supportTeamId,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ONBOARDER_API}/addinci`,
          {
            mode: "cors",
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            ...body,
          }
        );
        let responseData = response.data.data;

        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable to onboard CI\nPossible Reasons:\n1: Server is down\n2: Network error\n3:Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          alert(
            "Your CI has been successfully onboarded! Press ok to continue"
          );
          setCiId("");
          setName("");
          setPlatformName("");
          setDescription("");
          setName("");
          setTeamName("");
          setSupportTeamName("");
          setOpp("");
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
    //================================================================================================================================
    //UPDATION
    else if (opp === "Update") {
      setLoading(true);
      try {
        teamidData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getTeamId?name=${teamName}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        teamidData = teamidData.data.data;

        if (teamidData.error) {
          if (teamidData.error) {
            if (teamidData.status === 401) {
              throw new Error(teamidData.message);
            }
            throw new Error(
              "An internal error has occurred, unable to get the team id\nPress ok to continue"
            );
          }
        }
        platformIdData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getPlatformId?name=${platformName}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        platformIdData = platformIdData.data.data;

        if (platformIdData.error) {
          if (platformIdData.status === 401) {
            throw new Error(platformIdData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the platform id"
          );
        }
        supportTeamIdData = await axios.get(
          `${process.env.REACT_APP_ONBOARDER_API}/getSupportTeamId?name=${supportTeamName}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        supportTeamIdData = supportTeamIdData.data.data;
        if (supportTeamIdData.error) {
          if (supportTeamIdData.status === 401) {
            throw new Error(supportTeamIdData.message);
          }
          throw new Error(
            "An internal error has occurred, unable to get the support team id\nPress ok to continue"
          );
        }
        let teamID = teamidData.data.id;
        let platformID = platformIdData.data.id;
        let supportTeamId = supportTeamIdData.data.id;
        if (oldCiName !== name) {
          if (await ciNameAlreadyExist(name)) {
            setLoading(false);
            alert("CI Name Already exists!");
            setName("");
            return;
          }
        }
        let desc = description.trim();
        let na = name.trim();
        let ci_Id = ciId.trim();
        let body = {
          teamID,
          desc,
          na,
          platformID,
          ci_Id,
          supportTeamId,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_ONBOARDER_API}/updateCI`,
          {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            ...body,
          }
        );
        let responseData = response.data.data;
        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable to Update CI\nPossible Reasons:\n1: Server is down.\n2: Network error.\n3:Database is down.\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          alert("Your CI has been successfully Updated! Press ok to continue");
          setCiId("");
          setName("");
          setPlatformName("");
          setDescription("");
          setName("");
          setTeamName("");
          setSupportTeamName("");
          setOpp("");
          setCiName("");
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
    //================================================================================================================================
    //DELETION
    else {
      setLoading(true);
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_ONBOARDER_API}/deleteCI`,
          {
            mode: "cors",
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              ciId: ciId,
            },
          }
        );

        let responseData = response.data.data;

        if (responseData.error) {
          if (responseData.status === 401) {
            throw new Error(responseData.message);
          }
          throw new Error(
            "Unable to Delete your CI\nPossible Reasons:\n1: Server is down\n2: Network error \n3: Database is down\nContact SRE\nPress ok to continue"
          );
        } else {
          setLoading(false);
          setCiId("");
          setName("");
          setPlatformName("");
          setDescription("");
          setName("");
          setTeamName("");
          setSupportTeamName("");
          setOpp("");
          setCiName("");
          alert("Your CI has been successfully deleted\nPress ok to continue");
          // window.location.reload();
        }
      } catch (err) {
        setLoading(false);
        alert(err.message);
      }
    }
  };

  return (
    <>
      <NavBar />
      <div className="topContainer">
        {loading ? (
          <h2 style={{ fontWeight: "100" }}>
            {opp === "Insert"
              ? "Onboarding "
              : opp === "Update"
              ? "Updating "
              : "Deleting "}
            your CI, Please wait!!
          </h2>
        ) : (
          <>
            {" "}
            {/* <h1 style={{ fontWeight: "100" }}>CI Info Form</h1> */}
            <div className="formContainer">
              <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3 formGroup"
                  controlId="formBasicEmail"
                >
                  <Form.Label style={{}} className="formLabel">
                    Operation
                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                  </Form.Label>
                  <Box
                    sx={{
                      minWidth: 120,
                      marginBottom: "20px",
                      marginTop: "20px",
                      border: "none",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Operation
                      </InputLabel>
                      <Select
                        style={{ borderTop: "0px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={opp}
                        label="Select Opeartion"
                        onChange={(e) => handleOppChange(e)}
                      >
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Insert"
                        >
                          Onboard CI
                        </MenuItem>
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Update"
                        >
                          Update CI
                        </MenuItem>
                        <MenuItem
                          style={{ fontFamily: "Maersk Text" }}
                          value="Delete"
                        >
                          Delete CI
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {opp === "Update" || opp === "Delete" ? (
                    <Box
                      sx={{
                        minWidth: 120,
                        marginBottom: "20px",
                        marginTop: "20px",
                        border: "none",
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {gettingCiNames ? "Loading CIs" : "Select CI"}
                        </InputLabel>
                        <Select
                          style={{ borderTop: "0px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ciName}
                          label="Select Platform"
                          onChange={(e) => handleCiNameUptDel(e)}
                          disabled={gettingCiNames}
                        >
                          {ciNames.length > 0 &&
                            ciNames.map((ciName) => {
                              return (
                                <MenuItem
                                  // key={jobName.job_id}
                                  // onClick={() => setJobId(jobName.job_id)}
                                  style={{ fontFamily: "Maersk Text" }}
                                  value={ciName.name}
                                >
                                  {ciName.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}
                  {formInputFieldData.map((data) => {
                    return (
                      <>
                        <Form.Label className="formLabel">
                          {data.name}{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            *
                          </span>
                        </Form.Label>
                        {!data.isTextArea ? (
                          data.type === "select" ? (
                            <Box
                              sx={{
                                minWidth: 120,
                                marginBottom: "20px",
                                marginTop: "10px",
                                border: "none",
                              }}
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {gettingCiData
                                    ? "Loading CI Details"
                                    : data.name === "Platform Name" &&
                                      teamName === ""
                                    ? "Please select a team first"
                                    : data.text}
                                </InputLabel>
                                <Select
                                  style={{ borderTop: "0px" }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={data.value}
                                  label={data.text}
                                  onChange={(e) =>
                                    changeValue(data.onChange, e)
                                  }
                                  disabled={
                                    opp === "Delete" ||
                                    gettingCiData ||
                                    data.name === "Platform Name"
                                      ? teamName === ""
                                      : false
                                  }
                                >
                                  {data.names.length != 0 &&
                                    data.names.map((name) => {
                                      return (
                                        <MenuItem
                                          style={{ fontFamily: "Maersk Text" }}
                                          value={name.name}
                                        >
                                          {name.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>
                          ) : (
                            <Form.Control
                              className="inputBox"
                              type={data.type}
                              placeholder={
                                gettingCiData ? "Loading CI Details" : ""
                              }
                              onChange={(e) => changeValue(data.onChange, e)}
                              value={data.value}
                              required={data.required ? true : false}
                              disabled={
                                gettingCiData || data.name === "CI ID"
                                  ? opp === "Update" || opp === "Delete"
                                  : opp === "Delete"
                              }
                            />
                          )
                        ) : (
                          <Form.Control
                            as="textarea"
                            className="multiLineInput"
                            type={data.type}
                            placeholder={
                              gettingCiData
                                ? "Loading CI Details"
                                : "Describe your CI"
                            }
                            rows="5"
                            onChange={(e) => changeValue(data.onChange, e)}
                            value={data.value}
                            required={data.required ? true : false}
                            disabled={opp === "Delete" || gettingCiData}
                          />
                        )}
                      </>
                    );
                  })}

                  <div className="buttonContainer">
                    <Button type="submit" className="submitButton">
                      {opp === "Insert" || opp === ""
                        ? "Submit"
                        : opp === "Update"
                        ? "Update"
                        : "Delete"}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CiForm;
