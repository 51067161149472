export const clear = (
  setSloName,
  setSloDescription,
  alertName,
  errorQuery,
  totalQuery,

  alertAnnotations,
  setCategory,
  setObjective,
  setOnlyViewSloInfo
) => {
  setOnlyViewSloInfo(false);
  setSloName(() => "" ) 
  setSloDescription(() => "")
  errorQuery.current.value = "";
  totalQuery.current.value = "";
  alertName.current.value = "";
  alertAnnotations.current.value = "";
  setObjective(() => "0.0");
  setCategory(() => null);
  window.scrollTo(0, 0);
};
