import { IconButton, Tooltip } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../Components/NavBar";
import "../../../../StyleSheets/DBForm.css";
import { PostgresReport } from "./PostgresReport";


const useStyles = makeStyles(theme => ({
  customFontSize: {
    fontSize: '15px'
  }
}))

function PostgresForensicForm() {
  const navigate = useNavigate();
  const classes = useStyles()

  const [showReport, setShowReport] = useState(false);
  const [host, setHost] = useState();
  const [port, setPort] = useState(5432);
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [DB, setdb] = useState("");
  const [sslmode, setsslmode] = useState("true");
  const handleHostChange = (e) => {
    setHost(e.target.value);
  };
  const handlePortChange = (e) => {
    setPort(e.target.value);
  };
  const handleUserNameChange = (e) => {
    setuserName(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handledbchange = (e) => {
    setdb(e.target.value);
  };
  const handleSSLmode = (e) => {
    setsslmode(e);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate("/postgresreport",{state: {host,port,userName,password,DB,sslmode}});
    setShowReport(true);
  };
  const handleClick = (e) => {
    handleSSLmode(e);
  };

  const [isReportLoaded, setIsReportLoaded] = useState(false);

  useEffect(() => {
    if (isReportLoaded) {
      const element = document.getElementById("report");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    console.log("isReportLoaded", isReportLoaded);
    //unmounting 
    return ()=>{
      setIsReportLoaded(false);
    }
  }, [isReportLoaded])

  return (
    <>
      {/* <NavBar /> */}
      <div className="forenform__topContainer">
        {/* <h2>Postgres Forensic Form</h2> */}
        <div className="foren__formContainer foren_formContainer_md">
          <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="inputBox_Container">

                <div style={{ width: "50%" }}>
                  <Form.Label className="formLabel">Host <span style={{ color: "red", fontWeight: "bold" }}>*</span></Form.Label>
                  <Form.Control
                    className="foren__inputbox foren_inputBox_md"
                    type="text"
                    placeholder=""
                    onChange={handleHostChange}
                    value={host}
                    required
                  />
                </div>
                <div style={{ width: "50%", marginLeft: "20px" }}>
                  <div style={{ display: "flex" }}>
                    <div>

                      <Form.Label className="formLabel">Port
                        {/* <Tooltip placement = "top" title="Provide 5432 or other port" classes={{tooltip: classes.customFontSize}}>
                <IconButton>
                  <InfoIcon  style={{fontSize:'15px', color:"orange"}}/>
                </IconButton>
              </Tooltip> */}
                      </Form.Label>
                    </div>
                    {/* <div style={{marginLeft:"20px"}}>
              <p style={{fontSize:"10px", marginBottom:"5px"}}>Provide your server port. If onboarded with SRE, port is optional. If not, use your server port number</p>
              </div> */}
                  </div>
                  <Form.Control
                    className="foren__inputbox  foren_inputBox_md"
                    type="number"
                    min="0"
                    max="100000"
                    placeholder=""
                    onChange={handlePortChange}
                    value={port}


                  />
                </div>
              </div>
              <div className="inputBox_Container">
                <div style={{ width: "50%" }}>
                  <Form.Label className="formLabel">DBName <span style={{ color: "red", fontWeight: "bold" }}>*</span></Form.Label>
                  <Form.Control
                    className="foren__inputbox foren_inputBox_md"
                    type="text"
                    placeholder=""
                    onChange={handledbchange}
                    value={DB}
                    required
                  />
                </div>
                <div style={{ width: "50%", marginLeft: "20px" }}>
                  <Form.Label className="formLabel">SSLMode</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      aria-label="Text input with dropdown button"
                      className="foren__inputbox foren_inputBox_md"
                      value={sslmode}

                    />
                    <div

                    >
                      <DropdownButton
                        variant="default"
                        title="select"
                        id="input-group-dropdown-2"
                        align="end"
                        onSelect={handleClick}
                        className="selectButton"
                      >
                        <Dropdown.Item eventKey={"false"}>false</Dropdown.Item>
                        <Dropdown.Item eventKey={"true"}>true</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </InputGroup>
                </div>
              </div>
              <Form.Label className="formLabel">UserName
              </Form.Label>
              <p style={{ fontSize: "12px", marginBottom: "5px", }}>Provide your database user with admin or monitor privileges. If onboarded with SRE (with sremon user), username is optional. If not, use your admin/monitor user</p>

              {/* <Tooltip placement = "top" title={`Provide your database user with admin or monitor privileges. If onboarded with SRE, you can use ${process.env.REACT_APP_PG_USERNAME_DEFAULT} user as well. If not, use your admin/monitor user`} classes={{tooltip: classes.customFontSize}}>
                <IconButton>
                  <InfoIcon  style={{fontSize:'15px', color:"orange"}}/>
                </IconButton>
              </Tooltip> */}

              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="text"
                placeholder=""
                value={userName}
                onChange={handleUserNameChange}

              />

              <Form.Label className="formLabel">Password
                {/* <span style={{color: "red", fontWeight: "bold"}}>*</span> */}
                {/* <Tooltip placement = "top" title={`Required if user name is other than ${process.env.REACT_APP_PG_USERNAME_DEFAULT}`} classes={{tooltip: classes.customFontSize}}>
                <IconButton>
                  <InfoIcon  style={{fontSize:'15px', color:"orange"}}/>
                </IconButton>
              </Tooltip> */}
              </Form.Label>
              <p style={{ fontSize: "12px", marginBottom: "5px" }}>Optional if you are not providing username else provide the password corresponding to the username</p>
              <Form.Control
                className="foren__inputbox foren_inputBox_md"
                type="password"
                // placeholder={`For default username ${process.env.REACT_APP_PG_USERNAME_DEFAULT}, no password is needed`}
                value={password}
                onChange={handlePasswordChange}
                required={userName !== "" ? true : false}

              />



              <div className="buttonContainer">
                <Button type="submit" className="forenform__submitButton">
                  Submit
                </Button>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div id="report">
          {showReport && <PostgresReport
            host={host}
            port={port}
            userName={userName}
            password={password}
            DB={DB}
            sslmode={sslmode}
            setIsReportLoaded={setIsReportLoaded}
          />}
        </div>

      </div>
    </>
  );
}

export default PostgresForensicForm;
