import React from "react";
import NavBar from "../Components/NavBar";
import "../StyleSheets/about.css";
import {Link} from 'react-router-dom';
import { Card } from "react-bootstrap";
import brand from "../Images/Customer_Insights_Illustration.png";
const About = () => {

  const team = [
    {
      name: "Anish Koikkara Poulose",
      position: "Principal Engineer",
      email: "anish.poulose@maersk.com",
      mail: "mailto:anish.poulose@maersk.com",
    },
    {
      name: "Gowrish Subbaramaiah Mallipattana",
      position: "Lead Platform Architect ",
      email: "gowrish.mallipattana@maersk.com",
      mail: "mailto:gowrish.mallipattana@maersk.com",
    },
    {
      name: "Sudhir Sathyamurthy",
      position: "Lead Platform Architect",
      email: "sudhir.hosakote@maersk.com",
      mail: "mailto:sudhir.hosakote@maersk.com",
    },
    {
      name: "Bipin Balachandran Nair",
      position: "Senior Engineering Manager-SRE",
      email: "bipin.balachandran.nair@maersk.com",
      mail: "mailto:bipin.balachandran.nair@maersk.com",
    },
    // {
    //   name: "Vishesh Bansal",
    //   position: "Project Manager",
    //   email: "vishesh.bansal@maersk.com",
    //   mail: "mailto:vishesh.bansal@maersk.com",
    // },
    {
      name: "Jibin Rajan",
      position: "Cloud Engineer",
      email: "jibin.rajan@maersk.com",
      mail: "mailto:jibin.rajan@maersk.com",
    },
    {
      name: "Kunal Pankaj",
      position: "Senior Software Engineer",
      email: "kunal.pankaj@maersk.com",
      mail: "mailto:kunal.pankaj@maersk.com",
    },
    {
      name: "Muthukumar Rajendran",
      position: "Senior Software Engineer",
      email: "muthukumar.rajendran@maersk.com",
      mail: "mailto:muthukumar.rajendran@maersk.com",
    },
    {
      name: "Amal Saxena",
      position: "P3 Scrum Master",
      email: "amal.saxena@maersk.com",
      mail: "mailto:amal.saxena@maersk.com",
    },
    {
      name: "Chandra Bhushan Tiwari",
      position: "Senior Software Engineer",
      email: "chandra.tiwari@maersk.com",
      mail: "mailto:chandra.tiwari@maersk.com",
    },
    {
      name: "Leo Gladston Victor Gnanadhas",
      position: "Senior Software Engineer",
      email: "leo.v@maersk.com",
      mail: "mailto:leo.v@maersk.com",
    },
    {
      name: "Pooja Rastogi",
      position: "Infrastructure Engineer",
      email: "pooja.rastogi@maersk.com",
      mail: "mailto:pooja.rastogi@maersk.com",
    },
    {
      name: "Nilesh Chakkar",
      position: "Senior Software Engineer",
      email: "nilesh.chakkar@maersk.com",
      mail: "mailto:nilesh.chakkar@maersk.com",
    },
    {
      name: "Ram Gupta",
      position: "Senior Engineering Manager",
      email: "ram.gupta@maersk.com",
      mail: "mailto:ram.gupta@maersk.com",
    },
    {
      name: "Sandeep Shah",
      position: "P4 Senior Scrum Master",
      email: "sandeep.shah1@maersk.com",
      mail: "mailto:sandeep.shah1@maersk.com",
    },
    {
      name: "Shishir Shetty",
      position: "Senior Software Engineer",
      email: "shishir.shetty@maersk.com",
      mail: "mailto:shishir.shetty@maersk.com",
    },
    {
      name: "Sowmya R",
      position: "Senior Software Engineer",
      email: "sowmya.r.1@maersk.com",
      mail: "mailto:sowmya.r.1@maersk.com",
    },
    {
      name: "Sraddhanand Chikine",
      position: "Senior Software Engineer",
      email: "sraddhanand.c@maersk.com",
      mail: "mailto:sraddhanand.c@maersk.com",
    },
    {
      name: "Thalupuru Kamasai Naresh",
      position: "Senior Software Engineer",
      email: "thalupuru.kamasainaresh@maersk.com",
      mail: "mailto:thalupuru.kamasainaresh@maersk.com",
    },
    {
      name: "Thara K R",
      position: "Senior Scrum Master",
      email: "thara.k.r@maersk.com",
      mail: "mailto:thara.k.r@maersk.com",
    },
    {
      name: "Yeshwanth Athmakuri",
      position: "Senior Software Engineer",
      email: "yeshwanth.athmakuri@maersk.com",
      mail: "mailto:yeshwanth.athmakuri@maersk.com",
    },
    {
      name: "Arpita Sood",
      position: "Software Engineer",
      email: "arpita.sood@maersk.com",
      mail: "mailto:arpita.sood@maersk.com",
    },
    {
      name: "Shilpitha Swarna",
      position: "Associate Software Engineer",
      email: "shilpitha.swarna@maersk.com",
      mail: "mailto:shilpitha.swarna@maersk.com",
    },
    {
      name: "Abdullah Farhan Safwi",
      position: "Associate Software Engineer",
      email: "abdullah.safwi@maersk.com",
      mail: "mailto:abdullah.safwi@maersk.com",
    },
    {
      name: "Nikki Gupta",
      position: "Associate Software Engineer",
      email: "nikki.gupta@maersk.com",
      mail: "mailto:nikki.gupta@maersk.com",
    },
    {
      name: "Sagar Awasthi",
      position: "Associate Software Engineer",
      email: "sagar.awasthi@maersk.com",
      mail: "mailto:sagar.awasthi@maersk.com",
    },
    {
      name: "Sibasis Mishra",
      position: "Associate Software Engineer",
      email: "sibasis.mishra@maersk.com",
      mail: "mailto:ssibasis.mishra@maersk.com",
    },
    {
      name: "Ishita Gupta",
      position: "Associate Software Engineer",
      email: "ishita.gupta@maersk.com",
      mail: "mailto:ishita.gupta@maersk.com",
    },
    {
      name: "Aditya Mishra",
      position: "Intern SRE - Service Reliability Engineering ",
      email: "aditya.mishra@maersk.com",
      mail: "mailto:aditya.mishra@maersk.com",
    },
    {
      name: "Yeshavanth Nanjundappa",
      position: "Engineering Director",
      email: "yeshavanth.nanjundappa@maersk.com",
      mail: "mailto:yeshavanth.nanjundappa@maersk.com",
    },
  ];
  return (
    <>
      {/* <NavBar /> */}

    <div>
        <div className="mainContainer">
          <div className="contentDiv">
            <h3 className="headings"> SRE and Performance Engineering Team</h3>
          
            <p>
              We are a team of problem solvers focusing on Reliability and
              Performance of Maersk systems. Our focus is providing solutions to
              performance and reliability issues as well as developing platforms
              and tools to embrace "reliability and performance" in development
              cycle. SRE principles are at the core of our thought process as
              described in our <Link to="/manifesto"> manifesto</Link> .
            </p>
          </div>
        </div>

        <div className="">
          <section className="containerTeam container-Team-md-screen">
      
            <div className="team px-5 py-2">
              {team.map((data) => (
                <Card
                  className="shadow py-1 px-1 teamCard"
                  style={{ width: "15rem", backgroundColor: "#B5E0F5" }}
                >
                  <Card.Body>
                    <Card.Text>
                      <div className="team-member">
                        {/* <img
                          className=" card-img-top team-member-image py-2"
                          src={brand}
                          alt="Card image cap"
                        /> */}
                        <div className="details">
                          <div className="team-member-name">{data.name}</div>
                          <div className="team-member-position">
                            {data.position}
                          </div>
                          <div className="team-member-email">
                            <a
                              href={data.mail}
                              target="_blank"
                              className="team-link"
                            >
                              {data.email}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default About;
