export const setContext = (sloData, setServiceName, setProductId, setOrgId, setEnv, setHeadwigScope, setSelectedFormDetails, setCachedFormDetails) => {
     //only set the current input fields with context data if there's something in the context state
    //this condition will handle the case when the component mounted for first time and context state is empty
    if (sloData) {
        // setServiceName(() => sloData?.serviceName);
        // setProductId(() => sloData?.productId);
        // setOrgId(() => sloData?.orgId);
        // setEnv(() => sloData?.environment);
        // setHeadwigScope(() => sloData?.headwigScope);
        setSelectedFormDetails(() => ({
            AppID: sloData?.AppID,
            Portfolio: sloData?.Portfolio,
            Product: sloData?.Product,
            Platform: sloData?.Platform,
            Application: sloData?.Application,
            Environment: sloData?.Environment,
            hedwigScope: sloData?.hedwigScope
        }));
        setCachedFormDetails(sloData?.macDropDownValues)
      }
}